<template>
  <div
    class="has-background-white rounded px-4 pt-3 pb-1 mb-5"
    style="border: 1px solid #dcdcdc"
  >
    <div class="is-flex mb-3">
      <b-skeleton size="is-large" height="10" width="50" />
    </div>

    <div>
      <article class="is-flex product-list-item">
        <div
          class="media-content is-flex cart-list-item-content rounded-2 py-3"
          style="overflow: hidden"
        >
          <figure class="mr-3">
            <b-tag style="width: 32px"> </b-tag>
          </figure>
          <div class="content">
            <p class="is-size-6 is-size-7-mobile mb-0">
              <b-skeleton size="is-large" height="10" width="120" />
            </p>
            <p class="is-size-7 has-text-grey mb-0">
              <b-skeleton size="is-large" height="10" width="60" />
            </p>
          </div>
        </div>

        <div class="media-right is-flex">
          <div class="py-3 pl-3">
            <p class="is-size-6 is-size-7-mobile">
              <b-skeleton size="is-large" height="10" width="35" />
            </p>
          </div>
        </div>
      </article>
      <article class="is-flex product-list-item">
        <div
          class="media-content is-flex cart-list-item-content rounded-2 py-3"
          style="overflow: hidden"
        >
          <figure class="mr-3">
            <b-tag style="width: 32px"> </b-tag>
          </figure>
          <div class="content">
            <p class="is-size-6 is-size-7-mobile mb-0">
              <b-skeleton size="is-large" height="10" width="120" />
            </p>
            <p class="is-size-7 has-text-grey mb-0">
              <b-skeleton size="is-large" height="10" width="60" />
            </p>
          </div>
        </div>

        <div class="media-right is-flex">
          <div class="py-3 pl-3">
            <p class="is-size-6 is-size-7-mobile">
              <b-skeleton size="is-large" height="10" width="35" />
            </p>
          </div>
        </div>
      </article>
    </div>

    <!-- <hr class="my-3" />
    <div class="is-flex">
      <div class="is-flex-grow-1 is-size-7 has-text-grey">
        <b-skeleton size="is-large" height="10" width="50" />
      </div>
      <div class="is-size-7 has-text-grey">
        <b-skeleton size="is-large" height="10" width="30" />
      </div>
    </div>
    <div class="is-flex">
      <div class="is-flex-grow-1 has-text-grey is-size-7">
        <b-skeleton size="is-large" height="10" width="60" />
      </div>
      <div class="has-text-grey is-size-7">
        <b-skeleton size="is-large" height="10" width="30" />
      </div>
    </div>
    <div class="is-flex">
      <div class="is-flex-grow-1 has-text-grey is-size-7">
        <b-skeleton size="is-large" height="10" width="50" />
      </div>
      <div class="has-text-grey is-size-7">
        <b-skeleton size="is-large" height="10" width="30" />
      </div>
    </div>

    <hr class="my-2" />
    <div class="is-flex mb-3">
      <span class="is-flex-grow-1 has-text-grey"
        ><b-skeleton size="is-large" height="10" width="50"
      /></span>
      <span class="has-text-weight-semibold"
        ><b-skeleton size="is-large" height="10" width="60"
      /></span>
    </div>

    <hr class="my-0" />

    <div class="is-flex mt-3">
      <div class="has-text-grey is-flex-grow-1">
        <p class="is-size-6">
          <b-skeleton size="is-large" height="10" width="60" />
        </p>
      </div>
      <div class="has-text-right">
        <b-skeleton size="is-large" height="10" width="120" />
      </div>
    </div> -->

    <!-- <hr class="mt-3 mb-0" />
    <div
      class="is-size-7 has-text-centered my-3 is-flex is-justify-content-center"
    >
      <b-icon icon="leaf-circle" type="is-success" class="mr-1 pt-1" />

      <div class="pt-1 has-text-grey">
        <b-skeleton size="is-large" height="10" width="240" />
      </div>
    </div>
    <b-button icon-left="arrow-down" expanded disabled
      >Download Invoice</b-button
    > -->
  </div>
</template>

<script>
export default {
  name: "OrderSkeleton",
  data() {
    return {};
  },
};
</script>
