<template>
  <section class="responsive-padding" style="padding-right: 1rem !important">
    <div class="mb-3">
      <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">
          Kitchen Display
        </div>
        <!-- TODO: Yesterday and today orders? handle midnight orders-->
        <div class="ml-auto pr-4">
          <!-- <b-button
            class="is-secondary-btn is-hidden-tablet"
            icon-left="plus"
            @click="action"
          >
          </b-button>
          <b-button
            class="is-secondary-btn is-hidden-mobile"
            icon-left="plus"
            label="Action"
            @click="action"
          >
          </b-button> -->
        </div>
      </div>
      <div
        class="py-3"
        style="
          height: calc(100vh - 170px);
          overflow-y: auto;
          padding-right: 1rem;
          border-top: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
        "
      >
        <ToPrepareList v-if="!isLoading" :activeOrders="activeOrders" />

        <div v-else>
          <div class="pb-3" v-for="i in 3" :key="i">
            <b-skeleton size="is-large" height="120" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ToPrepareList from "@/components/Merchant/KitchenDisplay/ToPrepareList.vue";

export default {
  name: "KitchenDisplay",
  components: { ToPrepareList },
  data() {
    return { isLoading: false };
  },
  mounted() {
    this.isLoading = true;

    setTimeout(async () => {
      this.isLoading = false;
    }, 300);
  },
  computed: {
    activeOrders() {
      let temp = this.$store.getters["merchantOrdersModule/getActiveOrders"];

      return temp.sort((a, b) => {
        return (
          (a.createdAt === null) - (b.createdAt === null) ||
          +(a.createdAt > b.createdAt) ||
          -(a.createdAt < b.createdAt)
        );
      });
    },
  },

  methods: {
    // action() {
    //   console.log("action");
    // },
  },
};
</script>

<style>
.order-item-row:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 0.5rem;
  /* margin-bottom: 0.5rem !important; */
}
</style>
