import { db } from "@/firebase";
import {
  getDoc,
  getDocs,
  query,
  collection,
  where,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
  increment,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

import { ToastProgrammatic as Toast } from "buefy";

const state = {
  redeemableItems: [],
  userMerchantMemberPoints: 0,
  userMerchantRewards: [],
};

const getters = {
  getRedeemableItems: (state) => state.redeemableItems,
  getUserMerchantMemberPoints: (state) => state.userMerchantMemberPoints,
  getUserMerchantRewards: (state) => state.userMerchantRewards,
};

const mutations = {
  retrieveRedeemableItems(state, items) {
    state.redeemableItems = items;
  },

  addItem(state, item) {
    state.redeemableItems.push(item);
  },
  updateItem(state, item) {
    var targetIndex = state.redeemableItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      Object.assign(state.redeemableItems[targetIndex], item);
    }
    //state.currentItems.splice(targetIndex, 1, item);
  },
  removeItem(state, itemId) {
    // TODO: Debug
    var targetIndex = state.redeemableItems.findIndex((x) => x.id == itemId);
    if (targetIndex >= 0) {
      state.redeemableItems.splice(targetIndex, 1);
    }
  },
  updateItemStock(state, item) {
    var targetIndex = state.redeemableItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      Object.assign(state.redeemableItems[targetIndex], item);
    }
  },

  updateUserMerchantMemberPoints(state, userMerchantMemberPoints) {
    state.userMerchantMemberPoints = userMerchantMemberPoints;
  },

  retrieveUserMerchantMemberPoints(state, userMerchantMemberPoints) {
    state.userMerchantMemberPoints = userMerchantMemberPoints;
  },

  // TODO check increment / derement
  updateUserMerchantMemberPointsOnRedeem(state, pointChange) {
    state.userMerchantMemberPoints =
      state.userMerchantMemberPoints + pointChange;
  },

  addReward(state, reward) {
    state.userMerchantRewards.push(reward);
  },
  retrieveUserMerchantRewards(state, rewards) {
    state.userMerchantRewards = rewards;
  },

  redeemReward(state, item) {
    state.userMerchantRewards.push(item);
  },

  setRewardAsUsedOnAddToCart(state, rewardId) {
    var targetIndex = state.userMerchantRewards.findIndex(
      (x) => x.rewardId === rewardId
    );
    if (targetIndex >= 0) {
      state.userMerchantRewards[targetIndex].isUsed = true;
    }
  },

  setRewardAsNotUsedOnRemoveFromCart(state, rewardId) {
    var targetIndex = state.userMerchantRewards.findIndex(
      (x) => x.rewardId === rewardId
    );
    if (targetIndex >= 0) {
      state.userMerchantRewards[targetIndex].isUsed = false;
    }
  },
};

const actions = {
  async initRedeemableItemsRealtimeListeners({ commit }, merchantId) {
    const querySnapshot = await query(
      collection(db, "Merchants", merchantId, "Items"),
      where("isPointRedeemable", "==", true)
    ); // fix this
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("addItem", change.doc.data());
          }
        }
        if (change.type === "modified") {
          commit("updateItem", change.doc.data());
          commit("updateItemStock", change.doc.data());
        }
        if (change.type === "removed") {
          commit("removeItem", change.doc.id);
        }
      });
    });
  },

  async retrieveRedeemableItems({ commit }, merchantId) {
    const querySnapshot = await getDocs(
      query(
        collection(db, "Merchants", merchantId, "Items"),
        where("isPointRedeemable", "==", true)
      ) // fix this
    );
    let tempItems = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      tempItems.push(data);
    });

    commit("retrieveRedeemableItems", tempItems);
  },

  async initUserMerchantMemberPointsRealtimeListeners({ commit }, merchantId) {
    let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    const docRef = doc(db, "Users", userId, "Memberships", merchantId);

    // const unsub =
    onSnapshot(docRef, (doc) => {
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";

      if (source === "Server") {
        if (doc.data()) {
          commit("updateUserMerchantMemberPoints", doc.data().memberPoints);
        }
      }
    });
  },

  async retrieveUserMerchantMemberPoints({ commit }, merchantId) {
    let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    try {
      const docRef = doc(db, "Users", userId, "Memberships", merchantId);
      const docSnap = await getDoc(docRef);

      let userMerchantMemberPoints;

      if (docSnap.exists()) {
        userMerchantMemberPoints = docSnap.data().memberPoints;
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        userMerchantMemberPoints = 0;
      }

      commit("retrieveUserMerchantMemberPoints", userMerchantMemberPoints);
    } catch (e) {
      console.error("Error getting document: ", e);
      Toast.open("Something went wrong. Please try again.");
    }
  },

  // TODO: Get My Rewards (userMerchantRewards)
  async initUserMerchantRewardsRealtimeListeners({ commit }, merchantId) {
    let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    const querySnapshot = await query(
      collection(db, "Users", userId, "Rewards"),
      where("merchantId", "==", merchantId)
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("addReward", change.doc.data());
          }
        }
        // if (change.type === "modified") {
        // }
        // if (change.type === "removed") {
        //   commit("removeReward", change.doc.id);
        // }
      });
    });
  },

  async retrieveUserMerchantRewards({ commit }, merchantId) {
    let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    const querySnapshot = await getDocs(
      query(
        collection(db, "Users", userId, "Rewards"),
        where("merchantId", "==", merchantId)
      )
    );

    let tempItems = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data();
      tempItems.push(data);
    });

    commit("retrieveUserMerchantRewards", tempItems);
  },

  async redeemReward({ commit }, item) {
    try {
      let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      const todayDate = new Date();
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let randomCode = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        randomCode += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      const rewardId =
        "R" +
        todayDate.getFullYear().toString().slice(-2) +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        todayDate.getDate().toString().padStart(2, "0") +
        randomCode;

      const docRef = doc(db, "Users", userId, "Rewards", rewardId);
      item.rewardId = rewardId;

      let formattedData = {
        ...item,
        rewardRedeemedAt: new Date(),
        isUsed: false,
      };

      await setDoc(docRef, formattedData);

      commit("redeemReward", formattedData);

      Toast.open("Item has been redeemed successfully 🎉");
      return { success: true };
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong. Please try again.");
      return { success: false, error: e.message };
    }
  },

  async setRewardAsUsedOnAddToCart({ commit }, rewardId) {
    try {
      let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      const docRef = doc(db, "Users", userId, "Rewards", rewardId);

      await updateDoc(docRef, {
        isUsed: true,
      });
      // no need commit because realtime initialized?
      commit("setRewardAsUsedOnAddToCart", rewardId);
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong. Please try again.");
    }
  },
  async setRewardAsNotUsedOnRemoveFromCart({ commit }, rewardId) {
    try {
      let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      const docRef = doc(db, "Users", userId, "Rewards", rewardId);

      await updateDoc(docRef, {
        isUsed: false,
      });
      // no need commit because realtime initialized?
      commit("setRewardAsNotUsedOnRemoveFromCart", rewardId);
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong. Please try again.");
    }
  },

  // Decrease member points on redeem
  async updateUserMerchantMemberPointsOnRedeem({ commit }, data) {
    try {
      let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      const docRef = doc(db, "Users", userId, "Memberships", data.merchantId);

      let pointChangeInt = parseInt(data.pointChange);

      await updateDoc(docRef, {
        memberPoints: increment(pointChangeInt),
      });
      // no need commit because realtime initialized?
      commit("updateUserMerchantMemberPointsOnRedeem", pointChangeInt);
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong. Please try again.");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
