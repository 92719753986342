<template>
  <section class="responsive-padding" style="padding-right: 1rem !important">
    <div class="mb-3">
      <div class="is-block-mobile is-flex-tablet">
        <div class="mb-4">
          <div class="has-text-weight-bold is-size-5">Invoices</div>
          <p class="is-size-7 has-text-grey">
            <!-- Download report to view more details -->
          </p>
        </div>
        <!-- TODO: Yesterday and today orders? handle midnight orders-->
        <div class="ml-auto pr-4 is-flex mb-4">
          <b-datepicker
            class="mr-4"
            v-model="date"
            placeholder="Select a date"
            icon="calendar-today"
            trap-focus
            :mobile-native="false"
            position="is-bottom-left"
            @input="retrieveInvoicesByDate"
          >
          </b-datepicker>

          <!-- <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
            :disabled="filteredInvoicesByDate.length === 0"
          >
            <template #trigger="{}">
              <b-button
                class="is-secondary-btn is-hidden-tablet"
                icon-left="arrow-down"
                :disabled="filteredInvoicesByDate.length === 0"
              >
              </b-button>
              <b-button
                class="is-secondary-btn is-hidden-mobile"
                label="Download"
                icon-left="arrow-down"
                :disabled="filteredInvoicesByDate.length === 0"
              >
              </b-button>
            </template>

            <b-dropdown-item aria-role="listitem" @click="downloadOrdersReport"
              ><div class="media">
                <b-icon class="media-left" icon="clipboard-outline"></b-icon>
                <div class="media-content">
                  <h3>Orders Report</h3>
                </div>
              </div></b-dropdown-item
            >
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadSoldItemsReport"
              ><div class="media">
                <b-icon class="media-left" icon="cube-outline"></b-icon>
                <div class="media-content">
                  <h3>Sold Items Report</h3>
                </div>
              </div></b-dropdown-item
            >
          </b-dropdown> -->
        </div>
      </div>
      <div
        style="
          height: calc(100vh - 188px);
          overflow-y: auto;
          border-top: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
        "
      >
        <div v-if="!isLoading">
          <div v-if="filteredInvoicesByDate.length !== 0">
            <div
              v-for="invoice in filteredInvoicesByDate"
              :key="invoice.id"
              class="py-3 px-1 is-size-6 is-flex"
              style="border-bottom: 1px solid #f5f5f5"
            >
              <div class="py-2 mr-3 has-text-grey" style="width: 75px">
                {{ formatTimestampToTime(invoice.createdAt) }}
              </div>
              <div
                class="py-2 mr-3 has-text-grey is-hidden-mobile"
                style="width: 145px"
              >
                {{ invoice.id }}
              </div>
              <div class="py-2 mr-3 has-text-grey" style="width: 100px">
                {{ invoice.tableName }}
              </div>
              <div class="py-2">
                {{ `RM ${invoice.totalAmount}` }}
              </div>
              <div class="pl-4">
                <b-button
                  type="is-text"
                  icon-right="open-in-new"
                  @click="openInvoiceModal(invoice)"
                />
              </div>
            </div>

            <InvoiceModal
              v-if="isInvoiceModalActive"
              :isInvoiceModalActiveProp="isInvoiceModalActive"
              :closeInvoiceModal="closeInvoiceModal"
              :invoice="selectedInvoice"
            />
          </div>
          <div v-else>
            <div class="has-text-centered p-5 rounded">
              <b-image
                :src="require('@/assets/illustration/no_data.svg')"
                alt=""
                class="is-128x128 mx-auto mb-5"
              ></b-image>
              <div class="has-text-grey mx-auto">No invoice on this date</div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            v-for="i in 10"
            :key="i"
            class="py-3 px-1"
            style="height: 46.59px"
          >
            <b-skeleton size="is-large" height="10" width="320" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import { ToastProgrammatic as Toast } from "buefy";
import InvoiceModal from "@/components/Merchant/InvoicesPage/InvoiceModal.vue";

export default {
  name: "InvoicesPage",
  components: { InvoiceModal },
  props: {
    merchantInfo: Object, // Define merchantInfo as a prop
  },
  data() {
    return {
      isLoading: false,
      date: null,

      selectedInvoice: {},
      isInvoiceModalActive: false,
    };
  },
  computed: {
    filteredInvoicesByDate() {
      return this.$store.getters[
        "merchantInvoiceModule/getFilteredInvoicesByDate"
      ];
    },
  },
  async created() {
    this.date = new Date();

    // await this.$store.dispatch(
    //   "merchantInvoiceModule/retrieveInvoicesByDate",
    //   this.date
    // );
  },
  async mounted() {
    this.isLoading = true;

    setTimeout(async () => {
      await this.$store.dispatch(
        "merchantInvoiceModule/retrieveInvoicesByDate",
        this.date
      );
      this.isLoading = false;
    }, 300);
  },
  methods: {
    async retrieveInvoicesByDate() {
      this.isLoading = true;
      await this.$store.dispatch(
        "merchantInvoiceModule/retrieveInvoicesByDate",
        this.date
      );
      setTimeout(async () => {
        this.isLoading = false;
      }, 300);
    },

    openInvoiceModal(invoice) {
      this.selectedInvoice = invoice;
      this.isInvoiceModalActive = true;
    },
    closeInvoiceModal() {
      this.selectedInvoice = {};
      this.isInvoiceModalActive = false;
    },

    // downloadOrdersReport() {
    //   let data = this.filteredOrdersByDate;

    //   let reportDate = new Date(this.date);
    //   let dateString = `${reportDate.getDate()}-${
    //     reportDate.getMonth() + 1
    //   }-${reportDate.getFullYear()}`;

    //   const csvData = data.map((order) => {
    //     return {
    //       Date: this.formatTimestampToDate(order.createdAt),
    //       Time: this.formatTimestampToTime(order.createdAt),
    //       Order_ID: order.id,
    //       Items: order.items
    //         .map((item) => `${item.quantity}x ${item.name}`)
    //         .join(" + "),
    //       Subtotal: order.subtotal,
    //       Service_Tax: order.serviceTax,
    //       Service_Charge: order.serviceCharge,
    //       Total: order.totalAmount,
    //       Payment_Method: order.paymentMethod,
    //       Payment_Status: order.paymentStatus,
    //     };
    //   });

    //   const keys = Object.keys(csvData[0]);
    //   const csvContent =
    //     "data:text/csv;charset=utf-8," +
    //     keys.join(",") +
    //     "\n" +
    //     csvData
    //       .map((row) => {
    //         return keys
    //           .map((key) => {
    //             return '"' + (row[key] || "") + '"';
    //           })
    //           .join(",");
    //       })
    //       .join("\n");
    //   const encodedUri = encodeURI(csvContent);
    //   const link = document.createElement("a");
    //   link.setAttribute("href", encodedUri);
    //   link.setAttribute("download", `orders-report-${dateString}.csv`);
    //   document.body.appendChild(link); // Required for FF
    //   link.click();
    //   Toast.open("Report has been downloaded successfully ✅");
    // },

    // // TODO
    // getUniqueIdsWithTotalQuantity(nestedArray) {
    //   const idQuantityMap = {};

    //   for (const obj of nestedArray) {
    //     const items = obj.items;

    //     for (const item of items) {
    //       const {
    //         productId,
    //         name,
    //         basePrice,
    //         quantity,
    //         hasBasePriceDiscount,
    //         basePriceDiscountPercentage,
    //         sumPriceIncrease,
    //       } = item;

    //       const discountedQuantity = hasBasePriceDiscount ? quantity : 0;
    //       const discountedAmount = hasBasePriceDiscount
    //         ? (basePriceDiscountPercentage / 100) * Number(basePrice) * quantity
    //         : 0;

    //       if (productId in idQuantityMap) {
    //         idQuantityMap[productId].quantity += quantity;
    //         idQuantityMap[productId].discountedQuantity += discountedQuantity;
    //         idQuantityMap[productId].discountedAmount += discountedAmount;
    //         idQuantityMap[productId].sumPriceIncrease += sumPriceIncrease;
    //       } else {
    //         idQuantityMap[productId] = {
    //           productId,
    //           name,
    //           basePrice,
    //           quantity,

    //           discountedQuantity,
    //           discountedAmount,
    //           sumPriceIncrease,
    //         };
    //       }
    //     }
    //   }

    //   const uniqueIdsWithTotalQuantity = Object.values(idQuantityMap);
    //   return uniqueIdsWithTotalQuantity;
    // },

    // async downloadSoldItemsReport() {
    //   let data = await this.getUniqueIdsWithTotalQuantity(
    //     this.filteredOrdersByDate
    //   );

    //   let reportDate = new Date(this.date);
    //   let dateString = `${reportDate.getDate()}-${
    //     reportDate.getMonth() + 1
    //   }-${reportDate.getFullYear()}`;

    //   const csvData = data.map((item) => {
    //     const totalAmountAfterDiscount = (
    //       Number(item.basePrice) * item.quantity -
    //       item.discountedAmount
    //     ).toFixed(2);
    //     return {
    //       Product_ID: item.productId,
    //       Name: item.name,
    //       Base_Price: Number(item.basePrice).toFixed(2),
    //       Quantity: item.quantity,
    //       Total_Base_Price_Amount: Number(item.basePrice) * item.quantity,
    //       Discounted_Quantity: item.discountedQuantity,
    //       Total_Discounted_Amount: item.discountedAmount.toFixed(2),
    //       Total_Amount_After_Discount: totalAmountAfterDiscount,
    //       Total_Price_Increase: item.sumPriceIncrease.toFixed(2),
    //       Total_Amount_Received: (
    //         Number(totalAmountAfterDiscount) + item.sumPriceIncrease
    //       ).toFixed(2),
    //     };
    //   });

    //   const keys = Object.keys(csvData[0]);
    //   const csvContent =
    //     "data:text/csv;charset=utf-8," +
    //     keys.join(",") +
    //     "\n" +
    //     csvData
    //       .map((row) => {
    //         return keys
    //           .map((key) => {
    //             return '"' + (row[key] || "") + '"';
    //           })
    //           .join(",");
    //       })
    //       .join("\n");
    //   const encodedUri = encodeURI(csvContent);
    //   const link = document.createElement("a");
    //   link.setAttribute("href", encodedUri);
    //   link.setAttribute("download", `sold-items-report-${dateString}.csv`);
    //   document.body.appendChild(link); // Required for FF
    //   link.click();
    //   Toast.open("Report has been downloaded successfully ✅");
    // },

    formatTimestampToTime(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string

      const formattedTime = `${hours}.${minutes} ${amPm}`;

      return formattedTime;
    },

    formatTimestampToDate(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      const year = date.getFullYear();

      // Combine the date components into a formatted string
      const formattedDate = `${day} ${month} ${year}`;

      return formattedDate;
    },
  },
};
</script>
