<template>
  <section>
    <div
      v-if="TablesOrdersData?.length === 0"
      class="has-text-centered p-5 rounded has-fade-in-anim has-border-grey mt-4"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/no_table.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <div class="has-text-grey mx-auto">No table yet</div>
      </div>
    </div>

    <div
      v-else
      class="columns is-variable is-2-mobile is-2-tablet is-3-desktop is-mobile is-multiline"
    >
      <div
        v-for="item in TablesOrdersData"
        :key="item.tableName"
        class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd is-clickable"
      >
        <div class="has-border-grey rounded fullheight table-card">
          <div class="is-block is-align-content-space-between fullheight">
            <div class="is-flex pl-4 pr-3 pt-3">
              <div
                class="has-text-left is-flex-grow-1"
                @click="goToTableDetailsPage(item.tableName)"
              >
                <p class="is-size-7 has-text-grey">Table</p>
                <p
                  class="is-size-5 has-text-weight-semibold has-text-grey-dark"
                >
                  {{ item.tableName }}
                </p>
              </div>
              <div>
                <b-dropdown
                  aria-role="list"
                  position="is-bottom-left"
                  :mobile-modal="false"
                >
                  <template #trigger="{}">
                    <b-button type="is-text" icon-right="dots-vertical" />
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="downloadQR(item.tableName)"
                    ><div class="media">
                      <b-icon class="media-left" icon="download"></b-icon>
                      <div class="media-content">
                        <h3>Download QR</h3>
                      </div>
                    </div></b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="item.totalOrders > 0"
                    aria-role="listitem"
                    @click="confirmRemoveTable(item.tableName)"
                    ><div class="media has-text-danger">
                      <b-icon class="media-left" icon="delete-outline"></b-icon>
                      <div class="media-content">
                        <h3>Remove Table</h3>
                      </div>
                    </div></b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>

            <div
              class="px-3 pb-3 pt-2 rounded p-1"
              @click="goToTableDetailsPage(item.tableName)"
            >
              <!-- if seated -->
              <div
                v-if="item.totalOrders > 0"
                class="has-background-light pl-2 pr-3 py-3 rounded-2"
                style="height: 92px"
              >
                <div class="is-flex mb-2">
                  <b-icon icon="food" type="is-grey-light" class="mr-1">
                  </b-icon>
                  <p class="py-1 pl-2">
                    <span
                      class="has-text-weight-semibold mr-2"
                      :class="
                        item.totalQuantityOfCompletedItems ===
                        item.totalQuantityOfItems
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                      >{{ item.totalQuantityOfCompletedItems }} /
                      {{ item.totalQuantityOfItems }}</span
                    >
                    <span>Prepared</span>
                  </p>
                </div>
                <div class="is-flex">
                  <b-icon icon="currency-usd" type="is-grey-light" class="mr-1">
                  </b-icon>
                  <p class="py-1 pl-2">
                    <span
                      class="has-text-weight-semibold mr-2"
                      :class="
                        item.totalPaidOrders === item.totalOrders
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                      >{{ item.totalPaidOrders }} / {{ item.totalOrders }}</span
                    >
                    <span>Paid</span>
                  </p>
                </div>
              </div>

              <!-- if seat available -->
              <div
                v-else
                class="has-background-success-light p-3 rounded-2"
                style="height: 92px"
              >
                <b-icon
                  icon="circle"
                  size="is-small"
                  type="is-success"
                  class="pt-3"
                >
                </b-icon>
                <span class="py-1 pl-2 has-text-weight-medium">Available</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import QRCode from "qrcode";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "TableList",
  props: {
    TablesOrdersData: Array,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    merchantId() {
      return this.$store.getters["merchantModule/getMerchantId"];
    },
  },
  methods: {
    goToTableDetailsPage(tableName) {
      this.$router.push(`/merchant/dashboard/${tableName}`);
    },

    async downloadQR(tableName) {
      const url = `https://${window.location.host}/user/menu/${this.merchantId}?tableName=${tableName}`;

      const mainCanvas = document.createElement("canvas");

      const width = 600;
      const height = 600;

      mainCanvas.width = width;
      mainCanvas.height = height;

      const mainCtx = mainCanvas.getContext("2d");

      await QRCode.toCanvas(mainCanvas, url, {
        width: mainCanvas.width,
        height: mainCanvas.height,
      });

      const labelCanvas = document.createElement("canvas");
      labelCanvas.width = mainCanvas.width;
      labelCanvas.height = mainCanvas.height;

      const labelCtx = labelCanvas.getContext("2d");

      labelCtx.font = "20px Poppins";
      labelCtx.fillStyle = "#000";
      labelCtx.textAlign = "center";

      const label = `Table ${tableName}`;
      const x = labelCanvas.width / 2;
      let y = 40;

      const qrHeight = labelCanvas.height - 20;
      const labelHeight = parseInt(labelCtx.font) * 1.2;
      if (y + labelHeight > qrHeight) {
        y = qrHeight - labelHeight;
      }

      labelCtx.fillText(label, x, y);

      const logo = new Image();
      logo.src = require("@/assets/square-logo.jpeg");

      await new Promise((resolve) => {
        logo.onload = resolve;
      });

      const logoSize = labelCanvas.width * 0.2;
      const logoX = (labelCanvas.width - logoSize) / 2;
      const logoY = (labelCanvas.height - logoSize) / 2;

      labelCtx.drawImage(logo, logoX, logoY, logoSize, logoSize);

      mainCtx.drawImage(mainCanvas, 0, 0);

      mainCtx.drawImage(labelCanvas, 0, 0);

      const dataUrl = mainCanvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.download = `QR Code - Table ${tableName}.png`;
      link.href = dataUrl;
      link.click();
      Toast.open("QR has been downloaded successfully ✅");
    },

    confirmRemoveTable(tableName) {
      this.$buefy.dialog.confirm({
        title: "Remove Table",
        message: `Are you sure you want to Table ${tableName}?`,
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () => this.removeTable(tableName),
      });
    },
    async removeTable(tableName) {
      await this.$store.dispatch("merchantModule/removeTable", tableName);
    },
  },
};
</script>

<style>
.table-card:hover {
  border: 1px solid #b5b5b5 !important;
}
.table-card:active {
  transform: scale(0.99) !important;
}
</style>
