import { db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";

const state = {
  merchantInfo: {},
  order: {},
  invoice: {},
};

const getters = {
  getMerchantInfo: (state) => state.merchantInfo,
  getOrder(state) {
    return state.order;
  },

  getInvoice(state) {
    return state.invoice;
  },
};

const mutations = {
  retrieveMerchantInfo(state, merchant) {
    state.merchantInfo = merchant;
  },
  retrieveOrder(state, order) {
    state.order = order;
  },

  retrieveInvoice(state, invoice) {
    state.invoice = invoice;
  },
};

const actions = {
  async retrieveMerchantInfo({ commit }, merchantId) {
    const docRef = doc(db, "Merchants", merchantId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      commit("retrieveMerchantInfo", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },

  //TODO: Update
  async retrieveOrder({ commit }, orderId) {
    console.log("debug!", orderId);
    const docRef = doc(db, "Orders", orderId);
    const docSnap = await getDoc(docRef);

    console.log("debug!", docSnap);

    if (docSnap.exists()) {
      console.log("debug!", docSnap.data());
      commit("retrieveOrder", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },

  async retrieveInvoice({ commit }, invoiceId) {
    console.log("debug!", invoiceId);
    const docRef = doc(db, "Invoices", invoiceId);
    const docSnap = await getDoc(docRef);

    console.log("debug!", docSnap);

    if (docSnap.exists()) {
      console.log("debug!", docSnap.data());
      commit("retrieveInvoice", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
