<template>
  <b-modal
    v-model="isEditMerchantCartItemModalActive"
    scroll="keep"
    :on-cancel="closeEditMerchantCartItemModal"
    :can-cancel="['escape', 'outside']"
  >
    <div
      class="has-background-white"
      style="height: calc(100vh - 151px) !important"
    >
      <div style="height: calc(100vh - 151px) !important; overflow-y: auto">
        <div class="content p-5" style="max-width: 500px">
          <div class="has-text-weight-bold is-size-5 mb-5">Edit cart item</div>
          <div>
            <b-button
              class="mb-4"
              type="is-danger is-light"
              icon-left="trash-can-outline"
              @click="removeItem"
              expanded
              >Remove Item</b-button
            >

            <div class="is-flex mt-3 mb-3">
              <div class="is-flex-grow-1 has-text-weight-semibold is-size-6">
                {{ cartItem.name }}
              </div>
              <div class="has-text-right pl-3" style="width: 120px">
                <p v-if="!cartItem.hasBasePriceDiscount" class="is-size-6">
                  RM {{ cartItem.basePrice }}
                </p>
                <div v-else class="is-block">
                  <div class="is-size-6 ml-2">
                    RM
                    {{
                      (
                        cartItem.basePrice *
                        (1 - cartItem.basePriceDiscountPercentage / 100)
                      ).toFixed(2)
                    }}
                  </div>
                  <span
                    class="is-size-7 has-text-grey mb-0"
                    style="text-decoration: line-through"
                  >
                    RM {{ cartItem.basePrice }}
                  </span>
                  <b-tag
                    class="ml-2"
                    type="is-success is-light"
                    style="font-size: 0.6rem"
                    >{{ `-${cartItem.basePriceDiscountPercentage}%` }}</b-tag
                  >
                </div>
              </div>
            </div>

            <!-- Variations -->
            <div v-if="cartItem.hasVariations" class="has-fade-in-anim mb-4">
              <div
                v-for="(variation, variationIndex) in cartItem.variations"
                :key="variation.id"
                class="variation-container rounded has-border-grey p-4 mt-3"
              >
                <p class="mb-2">
                  <span class="has-text-weight-semibold is-size-6">{{
                    variation.title
                  }}</span>

                  <span
                    v-if="!selectedVariations[variationIndex]"
                    class="ml-2 has-text-grey is-size-7"
                    >Choose 1</span
                  >
                  <span v-else class="ml-2 has-text-grey is-size-7">
                    <b-icon
                      icon="check-circle"
                      size="is-small"
                      type="is-success"
                      class="mr-1"
                    >
                    </b-icon>
                  </span>
                </p>

                <div class="is-block">
                  <span v-for="option in variation.options" :key="option.id">
                    <!-- TODO: key and option id -->

                    <div class="is-flex mt-4 is-size-6">
                      <b-radio
                        v-model="selectedVariations[variationIndex]"
                        type="is-info"
                        :name="option.name"
                        :native-value="option"
                        required
                        :disabled="!option.isAvailable"
                        class="is-flex-grow-1"
                      >
                        {{ option.name }}
                      </b-radio>

                      <div v-if="option.isAvailable" class="has-text-grey">
                        {{
                          option.priceIncrease === "0.00"
                            ? ""
                            : `+RM ${option.priceIncrease}`
                        }}
                      </div>
                      <div v-else class="has-text-grey is-size-7">
                        <b-tag>Out of stock</b-tag>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <!-- Collection -->
            <div v-if="cartItem.hasCollections" class="has-fade-in-anim mb-4">
              <div
                v-for="(collection, collectionIndex) in cartItem.collections"
                :key="collection.id"
                class="rounded has-border-grey p-4 mt-3"
              >
                <div class="mb-2 is-flex is-size-6">
                  <span class="has-text-weight-semibold">{{
                    collection.title
                  }}</span>

                  <div class="mt-1 ml-2 has-text-grey is-size-7">
                    <span
                      v-if="
                        selectedCollections[collectionIndex].selections
                          ?.length === 0
                      "
                      >{{
                        `Select ${collection.requiredSelectionQuantity}`
                      }}</span
                    >
                    <div v-else>
                      <span
                        v-if="
                          collection.requiredSelectionQuantity -
                            selectedCollections[collectionIndex].selections
                              ?.length !==
                          0
                        "
                      >
                        {{
                          `Select ${
                            collection.requiredSelectionQuantity -
                            selectedCollections[collectionIndex].selections
                              ?.length
                          } more`
                        }}</span
                      >
                      <span v-else>
                        <b-icon
                          icon="check-circle"
                          size="is-small"
                          type="is-success"
                        >
                        </b-icon>
                        {{
                          `${selectedCollections[collectionIndex].selections?.length} selected`
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="is-block">
                  <span v-for="option in collection.options" :key="option.id">
                    <!-- TODO: key and option id -->

                    <div class="is-flex mt-4">
                      <b-checkbox
                        v-model="
                          selectedCollections[collectionIndex].selections
                        "
                        class="is-flex-grow-1"
                        type="is-info"
                        :name="option.name"
                        :native-value="option"
                        :disabled="
                          !option.isAvailable ||
                          (!checkIfSelectedById(
                            selectedCollections[collectionIndex].selections,
                            option.id
                          ) &&
                            selectedCollections[collectionIndex].selections
                              ?.length === collection.requiredSelectionQuantity)
                        "
                      >
                        <!-- TODO: update disabled rules by checking id-->
                        {{ option.name }}
                      </b-checkbox>

                      <div v-if="option.isAvailable" class="has-text-grey">
                        {{
                          option.priceIncrease === "0.00"
                            ? ""
                            : `+RM ${option.priceIncrease}`
                        }}
                      </div>
                      <div v-else class="has-text-grey is-size-7">
                        <b-tag>Out of stock</b-tag>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <!-- TODO Add-on -->

            <div
              class="mb-4 variation-container rounded has-border-grey p-4 mt-3 is-block"
            >
              <p class="mb-2">
                <span class="has-text-weight-semibold">Service</span>
              </p>

              <div class="is-block">
                <span>
                  <div class="is-flex mt-4">
                    <b-radio
                      class="mb-3 is-flex-grow-1"
                      v-model="serviceMode"
                      name="service"
                      native-value="Dine-in"
                    >
                      Dine-in
                    </b-radio>
                  </div>
                </span>
                <span>
                  <div class="is-flex mt-2">
                    <b-radio
                      class="is-flex-grow-1"
                      v-model="serviceMode"
                      name="service"
                      native-value="Takeaway"
                    >
                      Takeaway
                    </b-radio>

                    <div v-if="takeawayPriceIncrease" class="has-text-grey">
                      {{
                        takeawayPriceIncrease === "0.00"
                          ? ""
                          : `+ ${takeawayPriceIncrease}`
                      }}
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <div class="is-flex mt-5">
              <div
                class="is-flex-grow-1 has-text-weight-semibold py-2 is-size-6"
              >
                Quantity
              </div>

              <b-field>
                <b-numberinput
                  v-model="quantity"
                  :disabled="cartItem.isRewardItem"
                  type="is-light"
                  :editable="false"
                  :min="1"
                  :max="50"
                ></b-numberinput>
              </b-field>
            </div>
            <hr class="my-4" />
            <div class="mt-3">
              <b-collapse
                animation="slide"
                aria-id="contentIdForA11y3"
                :open="false"
              >
                <template #trigger="props">
                  <div
                    class="is-flex"
                    role="button"
                    aria-controls="contentIdForA11y3"
                    :aria-expanded="props.open"
                  >
                    <p
                      class="has-text-weight-semibold is-flex-grow-1 mb-0 is-size-6"
                    >
                      Special Remark
                    </p>
                    <a class="">
                      <b-icon
                        :icon="props.open ? 'chevron-up' : 'chevron-down'"
                      >
                      </b-icon>
                    </a>
                  </div>
                </template>

                <b-field class="mt-1">
                  <b-input v-model="specialRemark" maxlength="80"></b-input>
                </b-field>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <footer
        class="modal-card-foot is-justify-content-left customized-modal-actions"
      >
        <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
        <b-button
          type="is-primary action-btn"
          @click="updateCart"
          :disabled="!isAllRequiredFieldCompleted"
          >Save Changes</b-button
        >
      </footer>
    </div>
  </b-modal>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';

export default {
  name: "EditMerchantCartItem",
  components: {},
  props: {
    cartItem: { type: Object, required: true },
    isEditMerchantCartItemModalActiveProp: Boolean,
    closeEditMerchantCartItemModal: { type: Function },
  },
  data() {
    return {
      selectedVariations: this.cartItem.selectedVariations,
      selectedCollections: this.cartItem.selectedCollections,

      serviceMode: this.cartItem.serviceMode,
      specialRemark: this.cartItem.specialRemark,
      quantity: this.cartItem.quantity,
    };
  },
  created() {
    // const temp = [];
    // for (let i = 0; i < this.cartItem.collections?.length; i++) {
    //   temp[i] = { selections: [] };
    // }
    // this.selectedCollections = temp;
  },
  computed: {
    isEditMerchantCartItemModalActive: {
      get() {
        return this.isEditMerchantCartItemModalActiveProp;
      },
      set() {},
    },
    isVariationsCompleted() {
      if (!this.cartItem.hasVariations) {
        return true;
      } else {
        for (let i = 0; i < this.cartItem.variations?.length; i++) {
          if (this.selectedVariations[i] === undefined) {
            return false;
          }
        }
        return true;
      }
    },
    isCollectionsCompleted() {
      if (!this.cartItem.hasCollections) {
        return true;
      } else {
        for (let i = 0; i < this.cartItem.collections?.length; i++) {
          if (
            this.selectedCollections[i].selections?.length !==
            this.cartItem.collections[i].requiredSelectionQuantity
          ) {
            return false;
          }
        }
        return true;
      }
    },
    isAllRequiredFieldCompleted() {
      // TODO: + add-on length if feature added

      if (this.isVariationsCompleted && this.isCollectionsCompleted) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.closeEditMerchantCartItemModal();
    },

    sumPriceIncrease() {
      let sumPriceIncrease = 0;

      let sumSelectedVariationsPriceIncrease = this.selectedVariations.reduce(
        (accumulator, item) => {
          return accumulator + Number(item.priceIncrease);
        },
        0
      );

      sumPriceIncrease = sumPriceIncrease + sumSelectedVariationsPriceIncrease;

      for (let j = 0; j < this.selectedCollections.length; j++) {
        let sumSelectedCollectionsPriceIncrease = this.selectedCollections[
          j
        ].selections.reduce((accumulator, item) => {
          return accumulator + Number(item.priceIncrease);
        }, 0);

        sumPriceIncrease =
          sumPriceIncrease + sumSelectedCollectionsPriceIncrease;
      }

      return sumPriceIncrease;
    },

    async updateCart() {
      let newCartItem = this.cartItem;

      newCartItem.selectedVariations = this.selectedVariations;
      newCartItem.selectedCollections = this.selectedCollections;

      newCartItem.serviceMode = this.serviceMode;

      newCartItem.quantity = this.quantity;
      newCartItem.specialRemark = this.specialRemark;

      newCartItem.sumPriceIncrease = await this.sumPriceIncrease();
      // TODO
      newCartItem.totalPrice = newCartItem.hasBasePriceDiscount
        ? Number(
            newCartItem.basePrice *
              (1 - newCartItem.basePriceDiscountPercentage / 100)
          ) + Number(await this.sumPriceIncrease())
        : Number(newCartItem.basePrice) + Number(await this.sumPriceIncrease());

      await this.$store.dispatch(
        "merchantCartModule/updateItemInMerchantCart",
        newCartItem
      );

      this.onCancel();
    },

    checkIfSelectedById(arr, id) {
      const targetIndex = arr.findIndex((obj) => obj.id === id);

      if (targetIndex > -1) {
        return true;
      } else {
        return false;
      }
    },

    async removeItem() {
      //const result =
      await this.$store.dispatch(
        "merchantCartModule/removeItemFromMerchantCart",
        this.cartItem
      );

      //   if (result.success) {
      //     if (this.cartItem.isRewardItem) {
      //       await this.$store.dispatch(
      //         "rewardsModule/setRewardAsNotUsedOnRemoveFromCart",
      //         this.cartItem.rewardId
      //       );
      //     }
      //   }
      this.onCancel();
    },
  },
};
</script>

<style>
.bar-design {
  position: fixed;
  width: 100%;
  max-width: 500px !important;
  z-index: 1;
}
</style>
