<template>
  <div>
    <b-field>
      <div class="is-flex is-block-mobile">
        <b-input
          v-model="collectionTitle"
          placeholder="Name a collection"
          class="mb-4 mr-3 is-flex-grow-1"
        ></b-input>

        <b-button
          icon-left="plus"
          class="is-secondary-btn"
          :disabled="collectionTitle === ''"
          @click="createCollection"
        >
          Create
        </b-button>
      </div>
    </b-field>
    <div
      v-if="collections.length === 0"
      class="is-size-6 has-text-grey has-text-centered rounded has-background-light mt-4 p-4"
    >
      No collection yet
    </div>
    <div
      v-else
      v-for="(collectionItem, collectionIndex) in collections"
      :key="collectionIndex"
    >
      <div class="rounded pt-3 pb-4 px-4 has-border-grey mt-4">
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <span class="is-size-7 has-text-weight-light"
              >Collection {{ collectionIndex + 1 }}</span
            >
            <div class="has-text-weight-semibold mb-2">
              {{ collectionItem.title }}
            </div>
          </div>
          <b-button
            type="is-danger is-light"
            icon-right="delete"
            class="is-flex-grow-0 mt-1"
            @click="
              confirmRemoveCollection(collectionItem.title, collectionIndex)
            "
          />
        </div>

        <div
          v-if="collectionItem.options.length === 0"
          class="is-size-7 has-text-grey mb-3"
        >
          No option yet
        </div>

        <b-field v-else grouped group-multiline>
          <div
            v-for="option in collectionItem.options"
            :key="option.id"
            class="control"
          >
            <b-tag
              type="is-light"
              attached
              close-type="is-light"
              closable
              aria-close-label="Close tag"
              @close="removeOptionFromCollection(collectionIndex, option.id)"
            >
              {{ option.name + " +RM " + option.priceIncrease }}
            </b-tag>
          </div>
        </b-field>

        <b-collapse
          class="mb-4"
          :open="false"
          aria-id="contentIdForA11y1"
          position="is-bottom"
          animation="slide"
        >
          <template #trigger="props">
            <b-button
              v-if="!props.open"
              icon-left="plus"
              class="is-secondary-btn mt-3"
              size="is-small"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              >Add Option</b-button
            >
            <div v-else>
              <b-button :aria-expanded="props.open" type="is-small" class="mr-3"
                >Cancel</b-button
              >

              <b-button
                :aria-expanded="props.open"
                type="is-primary"
                size="is-small"
                :disabled="
                  optionNameInput[collectionIndex] === '' ||
                  optionPriceIncreaseInput[collectionIndex] === undefined
                "
                @click="
                  addOptionToCollection(
                    collectionIndex,
                    optionNameInput[collectionIndex],
                    optionPriceIncreaseInput[collectionIndex]
                  )
                "
                >Add</b-button
              >
            </div>
          </template>
          <div class="has-fade-in-anim">
            <hr />

            <b-input
              class="mb-4"
              v-model="optionNameInput[collectionIndex]"
              placeholder="Name an Option"
              size="is-small"
            ></b-input>
            <b-numberinput
              v-model="optionPriceIncreaseInput[collectionIndex]"
              type="is-light"
              controls-rounded
              class="mb-4"
              min="0"
              placeholder="Price Increase"
              size="is-small"
            ></b-numberinput>
          </div>
        </b-collapse>
        <hr class="mt-0 mb-3" />
        <div class="rounded is-flex-tablet is-block-mobile">
          <div class="mr-2 is-flex-grow-1">
            <div class="is-size-7-mobile is-size-6">
              Required quantity of selection
            </div>
            <div>
              <p
                v-if="collectionItem.options.length < 2"
                class="is-size-7 has-text-danger mb-0"
              >
                Please add at least 2 options
              </p>
              <p v-else class="is-size-7 has-text-grey mb-0">
                Minimum: 1, Maximum: {{ collectionItem.options.length - 1 }}
              </p>
            </div>
          </div>
          <b-field class="mt-1">
            <b-numberinput
              :editable="false"
              :disabled="collectionItem.options.length < 3"
              v-model="collections[collectionIndex].requiredSelectionQuantity"
              :value="1"
              :placeholder="1"
              type="is-light"
              size="is-small"
              min="1"
              :max="
                collectionItem.options.length > 2
                  ? collectionItem.options.length - 1
                  : 1
              "
            >
            </b-numberinput>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemCollections",
  components: {},
  props: {
    collectionsProp: Array,
  },
  computed: {},
  data() {
    return {
      collections: this.collectionsProp,

      collectionTitle: "",

      collectionsRequiredSelectionQuantity: [],

      optionNameInput: [],
      optionPriceIncreaseInput: [],
    };
  },

  methods: {
    createCollection() {
      const todayDate = new Date();
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let randomCode = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        randomCode += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      // TODO: id format?
      const collectionId =
        "CL" +
        todayDate.getFullYear().toString().slice(-2) +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        todayDate.getDate().toString().padStart(2, "0") +
        randomCode;

      this.collections.push({
        id: collectionId,
        title: this.collectionTitle,
        options: [],
        requiredSelectionQuantity: 1,
      });
      this.collectionTitle = "";
    },
    removeCollection(collectionIndex) {
      this.collections.splice(collectionIndex, 1);
      this.optionNameInput[collectionIndex] = "";
      this.optionPriceIncreaseInput[collectionIndex] = null;
    },
    confirmRemoveCollection(title, collectionIndex) {
      this.$buefy.dialog.confirm({
        title: "Remove Collection",
        message: `Are you sure you want to remove collection '${title}'?`,
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () => this.removeCollection(collectionIndex),
      });
    },
    addOptionToCollection(collectionIndex, optionName, optionPriceIncrease) {
      const todayDate = new Date();
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let randomCode = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        randomCode += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      const collectionsOptionId =
        "CLO" +
        todayDate.getFullYear().toString().slice(-2) +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        todayDate.getDate().toString().padStart(2, "0") +
        randomCode;

      this.collections[collectionIndex].options.push({
        id: collectionsOptionId,
        name: optionName,
        priceIncrease: Number(optionPriceIncrease).toFixed(2),
        isAvailable: true,
      });
      this.optionNameInput[collectionIndex] = "";
      this.optionPriceIncreaseInput[collectionIndex] = null;
    },
    removeOptionFromCollection(collectionIndex, optionId) {
      const targetIndex = this.collections[collectionIndex].options.findIndex(
        (obj) => obj.id === optionId
      );

      if (targetIndex > -1) {
        this.collections[collectionIndex].options.splice(targetIndex, 1);
      }

      if (this.collections[collectionIndex].options.length <= 2) {
        this.collections[collectionIndex].requiredSelectionQuantity = 1;
      }
    },
  },
};
</script>
