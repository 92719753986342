<template>
  <!-- TODO : clear 'added but not saved' variation when onCancel -->
  <!-- TODO: remove uploaded image but unsaved item image when navigate away.. -->
  <b-modal
    v-model="isEditItemModalActive"
    scroll="keep"
    :on-cancel="closeEditItemModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white">
      <div class="p-5 customized-modal-content">
        <div class="has-text-weight-bold is-size-5 mb-5">Edit Item</div>

        <b-field label="Name" size="is-small">
          <b-input v-model="itemName" required></b-input>
        </b-field>

        <b-field label="Description" size="is-small">
          <b-input v-model="itemDescription"></b-input>
        </b-field>

        <div v-if="isEditingImage" class="mb-5">
          <b-field label="Image" class="is-block">
            <b-upload
              v-if="itemImage.length === 0"
              v-model="itemImage"
              drag-drop
              accept=".png, .jpg, .jpeg"
              @input="onFileChange"
            >
              <section class="section" style="width: 100%">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
            <div v-else>
              <b-image
                :src="imagePreviewSrc"
                alt="product-image"
                class="m-0 product-image-preview"
                :responsive="false"
                :class="isUploadingImage ? 'img-loading' : ''"
              ></b-image>
            </div>
          </b-field>

          <div v-if="imageURL === '' && isUploadingImage" class="mb-5">
            <b-progress
              :value="uploadProgress"
              format="percent"
              show-value
              type="is-success"
              >Uploading {{ uploadProgress }}%</b-progress
            >
          </div>
          <div v-else class="tags">
            <span class="tag is-primary is-light" v-if="itemImage.name">
              {{ itemImage.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile()"
              ></button>
            </span>
          </div>

          <b-button
            v-if="storedImageSrc !== ''"
            type="is-light"
            size="is-small"
            icon-left="undo"
            @click="undoRemoveImage"
            >Undo Removal</b-button
          >
        </div>
        <div v-else class="mb-5">
          <b-field label="Image">
            <b-skeleton
              v-if="isLoadingStoredImageSrc"
              width="195"
              height="195"
            ></b-skeleton>
            <b-image
              v-else
              :src="storedImageSrc"
              alt="product-image"
              class="m-0 product-image-preview"
              :responsive="false"
            ></b-image>
          </b-field>
          <b-button
            type="is-light is-danger"
            size="is-small"
            icon-left="trash-can-outline"
            @click="removeImage"
            >Remove Image</b-button
          >
        </div>

        <b-field label="Base Price (RM)">
          <b-input
            v-model="itemBasePrice"
            placeholder=""
            type="number"
            step=".01"
          >
          </b-input>
        </b-field>

        <b-field label="Base Price Discount (%)" class="mb-3">
          <b-switch type="is-success" v-model="hasBasePriceDiscount"
            >{{ hasBasePriceDiscount ? "Enabled" : "Disabled" }}
          </b-switch>
        </b-field>
        <b-field>
          <b-numberinput
            v-if="hasBasePriceDiscount"
            v-model="basePriceDiscountPercentage"
            min="0"
            max="100"
            type="is-light"
          >
          </b-numberinput
        ></b-field>

        <b-field label="Item code" size="is-small">
          <b-input
            v-model="itemCode"
            placeholder="Example: A001"
            @input="toUpperCase"
          ></b-input>
        </b-field>

        <b-field label="Display on menu">
          <b-switch type="is-success" v-model="isDisplayOnMenu">{{
            isDisplayOnMenu ? "Enabled" : "Disabled"
          }}</b-switch></b-field
        >
      </div>
      <hr class="my-0" />
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-semibold has-text-dark">Variations</div>
        <p class="has-text-grey is-size-7 mb-4">
          It is usually for standard attributes. In each variation, customers
          are required to select only one option.
        </p>
        <b-switch
          class="mb-4 has-text-grey"
          type="is-success"
          v-model="hasVariations"
          @input="clearVariations"
          >{{ hasVariations ? "Enabled" : "Disabled" }}</b-switch
        >
        <ItemVariations v-if="hasVariations" :variationsProp="variations" />
      </div>

      <hr class="my-0" />
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-semibold has-text-dark">Collections</div>
        <p class="has-text-grey is-size-7 mb-4">
          It is usually for free combination. In each collection, customers are
          required to select a specified number of options.
        </p>
        <b-switch
          class="mb-4 has-text-grey"
          type="is-success"
          v-model="hasCollections"
          @input="clearCollections"
          >{{ hasCollections ? "Enabled" : "Disabled" }}</b-switch
        >

        <ItemCollections v-if="hasCollections" :collectionsProp="collections" />
      </div>

      <hr class="my-0" />
      <ItemPointRedemption
        :isPointRedeemable.sync="isPointRedeemable"
        :pointValue.sync="pointValue"
      />
    </div>

    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
      <b-button
        type="is-primary action-btn"
        @click="updateProduct"
        :disabled="!isAllRequiredFieldCompleted || isUploadingImage"
        >Save Changes</b-button
      >
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </b-modal>
</template>

<script>
import ItemVariations from "@/components/Merchant/ProductManagement/ItemVariations.vue";
import ItemCollections from "@/components/Merchant/ProductManagement/ItemCollections.vue";
import ItemPointRedemption from "@/components/Merchant/ProductManagement/ItemPointRedemption.vue";

export default {
  name: "EditItem",
  components: { ItemVariations, ItemCollections, ItemPointRedemption },
  props: {
    isEditItemModalActiveProp: Boolean,
    closeEditItemModal: { type: Function },
    category: Object,
    item: Object,
  },
  data() {
    return {
      itemId: this.item.id,
      itemName: this.item.name,
      itemDescription: this.item.description,
      itemBasePrice: this.item.basePrice,
      hasBasePriceDiscount: this.item.hasBasePriceDiscount,
      basePriceDiscountPercentage: this.item.basePriceDiscountPercentage,

      itemImage: [], //this.item.image TODO: file?
      itemCode: this.item.code,
      isDisplayOnMenu: this.item.isDisplayOnMenu,
      hasVariations: this.item.hasVariations,
      variations: [],
      variationsJSONString: "",

      hasCollections: this.item.hasCollections,
      collections: [],
      collectionsJSONString: "",

      storedImageSrc: this.item.imageURL,
      isLoadingStoredImageSrc: false,
      storedImageFullPath: this.item.imageFullPath,

      isEditingImage: this.item.imageURL === "" ? true : false,
      imagePreviewSrc: "",

      isLoading: false,

      isPointRedeemable: this.item.isPointRedeemable,
      pointValue: this.item.pointValue,
    };
  },

  created() {
    this.variationsJSONString = JSON.stringify([...this.item.variations]);
    this.variations = JSON.parse(this.variationsJSONString);

    this.collectionsJSONString = JSON.stringify([...this.item.collections]);
    this.collections = JSON.parse(this.collectionsJSONString);
  },

  mounted() {
    this.isLoadingStoredImageSrc = true;

    setTimeout(async () => {
      this.isLoadingStoredImageSrc = false;
    }, 300);
  },

  computed: {
    isEditItemModalActive: {
      get() {
        return this.isEditItemModalActiveProp;
      },
      set() {},
    },

    isUploadingImage() {
      return this.$store.getters["productImagesModule/getIsUploadingImage"];
    },
    uploadProgress() {
      return Math.round(
        this.$store.getters["productImagesModule/getUploadProgress"]
      );
    },
    // TODO
    imageURL() {
      return this.$store.getters["productImagesModule/getImageURL"];
    },
    imageFullPath() {
      return this.$store.getters["productImagesModule/getImageFullPath"];
    },

    itemCopy() {
      return { ...this.item };
    },

    isVariationsValid() {
      if (!this.hasVariations) {
        return true;
      } else {
        if (this.variations.length !== 0) {
          for (let i = 0; i < this.variations.length; i++) {
            if (this.variations[i].options.length < 2) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }
    },
    isCollectionsValid() {
      if (!this.hasCollections) {
        return true;
      } else {
        if (this.collections.length !== 0) {
          for (let i = 0; i < this.collections.length; i++) {
            if (
              this.collections[i].options.length < 2 ||
              this.collections[i].requiredSelectionQuantity === undefined ||
              this.collections[i].requiredSelectionQuantity === null ||
              this.collections[i].requiredSelectionQuantity === "" ||
              this.collections[i].requiredSelectionQuantity < 1 ||
              this.collections[i].requiredSelectionQuantity >=
                this.collections[i].options.length
            ) {
              return false;
            }
          }

          return true;
        } else {
          return false;
        }
      }
    },
    isAllRequiredFieldCompleted() {
      let generalInputCompleted =
        this.itemName !== "" && this.itemBasePrice !== undefined;

      //&&
      //this.itemImage instanceof File

      if (generalInputCompleted) {
        if (!this.isVariationsValid || !this.isCollectionsValid) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // Remove uploaded image, reset ui and store data
    deleteDropFile() {
      this.itemImage = [];
      this.imagePreviewSrc = "";

      if (this.imageFullPath !== "") {
        this.$store.dispatch(
          "productImagesModule/deleteImage",
          this.imageFullPath
        );
      }
      this.$store.dispatch("productImagesModule/clearAll");
    },
    // Reset ui and store data only
    resetDropFile() {
      this.itemImage = [];
      this.imagePreviewSrc = "";
      this.$store.dispatch("productImagesModule/clearAll");
    },

    clearVariations() {
      // TODO
      this.variations = [];
    },
    clearCollections() {
      // TODO
      this.collections = [];
    },

    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.deleteDropFile();
      this.closeEditItemModal();
    },

    toUpperCase() {
      this.itemCode = this.itemCode.toUpperCase();
    },

    removeImage() {
      this.isEditingImage = true;
    },
    undoRemoveImage() {
      this.isEditingImage = false;
    },

    onFileChange() {
      if (!(this.itemImage instanceof Blob)) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const width = img.width;
          const height = img.height;
          const dimension = Math.min(width, height);
          const offsetX = (width - dimension) / 2;
          const offsetY = (height - dimension) / 2;

          canvas.width = 1080;
          canvas.height = 1080;

          const scale = 1080 / dimension;
          const destWidth = dimension * scale;
          const destHeight = dimension * scale;
          const destX = (1080 - destWidth) / 2;
          const destY = (1080 - destHeight) / 2;

          ctx.drawImage(
            img,
            offsetX,
            offsetY,
            dimension,
            dimension,
            destX,
            destY,
            destWidth,
            destHeight
          );

          const dataURL = canvas.toDataURL();

          this.imagePreviewSrc = dataURL;
          await this.uploadProductImage(canvas, this.itemImage.name);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(this.itemImage);
    },
    async uploadProductImage(canvas, fileName) {
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg", 0.8)
      );
      const file = new File([blob], fileName.replace(/\.[^.]+$/, ""), {
        type: "image/jpeg",
      });
      await this.$store.dispatch("productImagesModule/uploadImage", file);
    },

    async updateProduct() {
      this.isLoading = true;

      // TODO: if new image uploaded, remove old image
      if (this.isEditingImage && this.storedImageFullPath !== "") {
        // Image edited (removed or use new), remove old image from storage
        await this.$store.dispatch(
          "productImagesModule/deleteImage",
          this.storedImageFullPath
        );
      }

      await this.$store.dispatch("productsModule/updateItem", {
        // TODO
        id: this.itemId,
        name: this.itemName,
        description: this.itemDescription,
        basePrice: Number(this.itemBasePrice).toFixed(2),
        hasBasePriceDiscount: this.hasBasePriceDiscount,
        basePriceDiscountPercentage: this.basePriceDiscountPercentage,
        //   image: this.itemImage,
        //TODO:
        imageURL: this.isEditingImage ? this.imageURL : this.storedImageSrc,
        imageFullPath: this.isEditingImage
          ? this.imageFullPath
          : this.storedImageFullPath,

        code: this.itemCode,
        isDisplayOnMenu: this.isDisplayOnMenu,
        hasVariations: this.hasVariations,
        variations: this.variations,

        hasCollections: this.hasCollections,
        collections: this.collections,

        isPointRedeemable: this.isPointRedeemable,
        pointValue: this.pointValue,

        categoryId: this.category.id,
      });

      this.isLoading = false;

      this.reset();
      this.resetDropFile();
      this.closeEditItemModal();
    },
  },
};
</script>

<style>
.product-image-preview img {
  height: 195px !important;
}

.img-loading img {
  filter: opacity(0.5);
}

button.delete.is-small {
  background: hsl(243.08deg 100% 69.41%);
}
</style>
