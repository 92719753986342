import { db } from "@/firebase";
import { collection, getDocs, setDoc } from "firebase/firestore";
import { query, where } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { doc } from "firebase/firestore";

import { ToastProgrammatic as Toast } from "buefy";

const state = {
  filteredInvoicesByDate: [], // todayInvoices
  latestInvoice: {},
};

const getters = {
  getFilteredInvoicesByDate(state) {
    return state.filteredInvoicesByDate;
  },

  getLatestInvoice(state) {
    return state.latestInvoice;
  },
};

const mutations = {
  retrieveInvoicesByDate(state, invoices) {
    state.filteredInvoicesByDate = invoices;
  },

  createInvoice(state, invoice) {
    state.filteredInvoicesByDate.push(invoice);
    state.latestInvoice = invoice;
  },
};

const actions = {
  async retrieveInvoicesByDate({ commit, rootState }, date) {
    if (!date) {
      console.error("Date parameter is undefined or null");
      return; // Exit the function if date is undefined or null
    }

    const dateStart = new Date(date);
    dateStart.setHours(0, 0, 0, 0);
    const dateStartTimestamp = Timestamp.fromDate(dateStart);

    const dateEnd = new Date(date);
    dateEnd.setHours(24, 0, 0, 0);
    const dateEndTimestamp = Timestamp.fromDate(dateEnd);

    const querySnapshot = await getDocs(
      query(
        collection(db, "Invoices"),
        where("merchantId", "==", rootState.merchantModule.merchantInfo.id),
        where("createdAt", ">=", dateStartTimestamp),
        where("createdAt", "<", dateEndTimestamp)
      )
    );
    let temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      temp.push(data);
    });

    temp.sort((a, b) => {
      // Assuming your item objects have a timestamp property
      // Replace 'timestamp' with the actual property name if different
      return b.createdAt - a.createdAt; // Sort in descending order
    });

    commit("retrieveInvoicesByDate", temp);
  },

  async createInvoice({ commit, rootState }, data) {
    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const invoiceId =
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    try {
      const docRef = doc(db, "Invoices", invoiceId);

      const date = new Date();
      await setDoc(docRef, {
        id: invoiceId,
        ...data,
        merchantId: rootState.merchantModule.merchantInfo.id,
        createdAt: date,
        updatedAt: date,
      });

      // Toast.open("Invoice has been created successfully ✅");

      commit("createInvoice", {
        id: invoiceId,
        ...data,
        merchantId: rootState.merchantModule.merchantInfo.id,
        createdAt: date,
        updatedAt: date,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
