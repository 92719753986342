<template>
  <section class="responsive-padding" style="padding-right: 1rem !important">
    <div>
      <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">Edit Profile</div>
      </div>
      <div class="py-4 pr-5 edit-profile-container">
        <div class="mb-3" style="max-width: 500px">
          <div v-if="isEditingLogoImage" class="mb-5">
            <b-field label="Logo Image" class="is-block">
              <b-upload
                v-if="logoUploader.length === 0"
                v-model="logoUploader"
                drag-drop
                accept=".png, .jpg, .jpeg"
                @input="onFileChange"
              >
                <section class="section" style="width: 100%">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Drop your files here or click to upload</p>
                  </div>
                </section>
              </b-upload>
              <div v-else>
                <b-image
                  :src="logoImagePreviewSrc"
                  alt="product-image"
                  class="m-0 product-image-preview"
                  :responsive="false"
                  :class="isUploadingImage ? 'img-loading' : ''"
                ></b-image>
              </div>
            </b-field>

            <div v-if="logoImageURL === '' && isUploadingImage" class="mb-5">
              <b-progress
                :value="uploadProgress"
                format="percent"
                show-value
                type="is-success"
                >Uploading {{ uploadProgress }}%</b-progress
              >
            </div>
            <div v-else class="tags">
              <span class="tag is-primary is-light" v-if="logoUploader.name">
                {{ logoUploader.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile()"
                ></button>
              </span>
            </div>

            <b-button
              v-if="storedLogoImageSrc !== ''"
              type="is-light"
              size="is-small"
              icon-left="undo"
              @click="undoRemoveImage"
              >Undo Removal</b-button
            >
          </div>
          <div v-else class="mb-5">
            <b-field label="Image">
              <b-skeleton
                v-if="isLoadingStoredLogoImageSrc"
                width="195"
                height="195"
              ></b-skeleton>
              <b-image
                v-else
                :src="storedLogoImageSrc"
                alt="product-image"
                class="m-0 product-image-preview"
                :responsive="false"
              ></b-image>
            </b-field>
            <b-button
              type="is-light is-danger"
              size="is-small"
              icon-left="trash-can-outline"
              @click="removeImage"
              >Remove Image</b-button
            >
          </div>

          <b-field label="Merchant Name">
            <b-input v-model="name"></b-input>
          </b-field>

          <b-field label="Contact">
            <div class="is-flex">
              <span
                class="p-2 mr-2 has-border-grey"
                style="border-radius: 0.5rem; height: 40px"
                >+60</span
              >
              <b-input
                maxlength="10"
                class="is-flex-grow-1"
                v-model="contact"
                :has-counter="false"
                validation-message="Please enter a valid Malaysian mobile number"
                :pattern="contactPattern"
                required
              >
              </b-input>
            </div>
          </b-field>
          <div class="mb-5">
            <div class="has-text-weight-semibold mb-2">Address</div>
            <b-input
              v-model="detailedAddress"
              placeholder="Detailed Address"
              class="mb-3"
            ></b-input>

            <div class="is-flex">
              <b-input
                v-model="city"
                placeholder="City"
                class="mb-3 is-flex-grow-1"
              ></b-input>
              <b-input
                v-model="postcode"
                placeholder="Postcode"
                class="mb-3 ml-3 is-flex-grow-1"
              ></b-input>
            </div>
            <b-input
              v-model="state"
              placeholder="State"
              class="mb-3 is-flex-grow-1"
            ></b-input>
          </div>

          <b-field label="Company Name">
            <b-input v-model="companyName"></b-input>
          </b-field>

          <b-field label="Company Code">
            <b-input v-model="companyCode"></b-input>
          </b-field>

          <b-field label="SST No.">
            <b-input v-model="SSTNo"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div>
      <b-button
        class="my-4"
        type="is-primary"
        :disabled="!isEdited"
        @click="saveProfile"
        >Save Changes</b-button
      >
    </div>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </section>
</template>

<script>
export default {
  name: "EditProfile",
  components: {},
  data() {
    return {
      name: "",
      contact: "",
      detailedAddress: "",
      city: "",
      postcode: "",
      state: "",
      companyName: "",
      companyCode: "",
      SSTNo: "",

      contactPattern: "^(1)[0-46-9][0-9]{7}|^(1)(1)[0-9]{8}$",
      //
      logoUploader: [],
      storedLogoImageSrc: "",
      isLoadingStoredLogoImageSrc: false,
      storedLogoImageFullPath: "",

      isEditingLogoImage: undefined,
      logoImagePreviewSrc: "",

      isLoading: false,
    };
  },
  computed: {
    merchantInfo() {
      return this.$store.getters["merchantModule/getMerchantInfo"];
    },

    isUploadingImage() {
      return this.$store.getters["logoImageModule/getIsUploadingImage"];
    },
    uploadProgress() {
      return Math.round(
        this.$store.getters["logoImageModule/getUploadProgress"]
      );
    },
    logoImageURL() {
      return this.$store.getters["logoImageModule/getImageURL"];
    },
    logoImageFullPath() {
      return this.$store.getters["logoImageModule/getImageFullPath"];
    },

    isEdited() {
      const regexMobileNo = new RegExp(this.contactPattern);

      if (
        (this.name == this.merchantInfo.name &&
          this.contact == this.merchantInfo.contact.substring(2) &&
          this.detailedAddress == this.merchantInfo.detailedAddress &&
          this.city == this.merchantInfo.city &&
          this.postcode == this.merchantInfo.postcode &&
          this.state == this.merchantInfo.state &&
          this.companyName == this.merchantInfo.companyName &&
          this.companyCode == this.merchantInfo.companyCode &&
          this.logoImageURL ==
            (this.merchantInfo.logoImageURL === undefined
              ? ""
              : this.merchantInfo.logoImageURL) &&
          this.SSTNo == this.merchantInfo.SSTNo) ||
        !regexMobileNo.test(this.contact)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    merchantInfo(newValue) {
      this.name = newValue.name;
      this.contact = newValue.contact.substring(2);
      this.detailedAddress = newValue.detailedAddress || "";
      this.city = newValue.city || "";
      this.postcode = newValue.postcode || "";
      this.state = newValue.state || "";
      this.companyName = newValue.companyName || "";
      this.companyCode = newValue.companyCode || "";
      this.SSTNo = newValue.SSTNo || "";

      this.storedLogoImageSrc = newValue.logoImageURL
        ? newValue.logoImageURL
        : "";
      this.storedLogoImageFullPath = newValue.logoImageFullPath
        ? newValue.logoImageFullPath
        : "";
      this.isEditingLogoImage =
        newValue.logoImageURL === "" || newValue.logoImageURL === undefined
          ? true
          : false;

      console.log("debug", newValue.logoImageURL);
    },
  },
  created() {
    this.name = this.merchantInfo.name || "";
    this.contact = this.merchantInfo.contact.substring(2) || "";
    this.detailedAddress = this.merchantInfo.detailedAddress || "";
    this.city = this.merchantInfo.city || "";
    this.postcode = this.merchantInfo.postcode || "";
    this.state = this.merchantInfo.state || "";
    this.companyName = this.merchantInfo.companyName || "";
    this.companyCode = this.merchantInfo.companyCode || "";
    this.SSTNo = this.merchantInfo.SSTNo || "";

    this.storedLogoImageSrc = this.merchantInfo.logoImageURL
      ? this.merchantInfo.logoImageURL
      : "";
    this.storedLogoImageFullPath = this.merchantInfo.logoImageFullPath
      ? this.merchantInfo.logoImageFullPath
      : "";
    this.isEditingLogoImage =
      this.merchantInfo.logoImageURL === "" ||
      this.merchantInfo.logoImageURL === undefined
        ? true
        : false;
  },
  mounted() {
    this.isLoadingStoredLogoImageSrc = true;

    setTimeout(async () => {
      this.isLoadingStoredLogoImageSrc = false;
    }, 300);
  },
  methods: {
    async saveProfile() {
      this.isLoading = true;
      // TODO: if new image uploaded, remove old image
      if (this.isEditingLogoImage && this.storedLogoImageFullPath !== "") {
        // Image edited (removed or use new), remove old image from storage
        await this.$store.dispatch(
          "logoImageModule/deleteImage",
          this.storedLogoImageFullPath
        );
      }

      await this.$store.dispatch("merchantModule/updateProfile", {
        name: this.name,
        contact: "60" + this.contact,
        detailedAddress: this.detailedAddress,
        city: this.city,
        postcode: this.postcode,
        state: this.state,
        companyName: this.companyName,
        companyCode: this.companyCode,
        SSTNo: this.SSTNo,

        logoImageURL: this.isEditingLogoImage
          ? this.logoImageURL
          : this.storedLogoImageSrc,
        logoImageFullPath: this.isEditingLogoImage
          ? this.logoImageFullPath
          : this.storedLogoImageFullPath,
      });

      this.isLoading = false;

      this.resetDropFile();
    },

    resetDropFile() {
      this.logoUploader = [];
      this.logoImagePreviewSrc = "";
      this.$store.dispatch("logoImageModule/clearAll");
    },

    onFileChange() {
      if (!(this.logoUploader instanceof Blob)) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const width = img.width;
          const height = img.height;
          const dimension = Math.min(width, height);
          const offsetX = (width - dimension) / 2;
          const offsetY = (height - dimension) / 2;

          canvas.width = 1080;
          canvas.height = 1080;

          const scale = 1080 / dimension;
          const destWidth = dimension * scale;
          const destHeight = dimension * scale;
          const destX = (1080 - destWidth) / 2;
          const destY = (1080 - destHeight) / 2;

          ctx.drawImage(
            img,
            offsetX,
            offsetY,
            dimension,
            dimension,
            destX,
            destY,
            destWidth,
            destHeight
          );

          const dataURL = canvas.toDataURL();

          this.logoImagePreviewSrc = dataURL;
          await this.uploadLogoImage(canvas, this.logoUploader.name);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(this.logoUploader);
    },

    deleteDropFile() {
      this.logoUploader = [];
      this.logoImagePreviewSrc = "";

      if (this.logoImageFullPath !== "") {
        this.$store.dispatch(
          "logoImageModule/deleteImage",
          this.logoImageFullPath
        );
      }
      this.$store.dispatch("logoImageModule/clearAll");
    },

    removeImage() {
      this.isEditingLogoImage = true;
    },
    undoRemoveImage() {
      this.isEditingLogoImage = false;
    },

    async uploadLogoImage(canvas, fileName) {
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg", 0.8)
      );
      const file = new File([blob], fileName.replace(/\.[^.]+$/, ""), {
        type: "image/jpeg",
      });
      await this.$store.dispatch("logoImageModule/uploadImage", file);
    },
  },
};
</script>

<style>
.edit-profile-container {
  height: calc(100vh - 210px);
  overflow-y: auto;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

.product-image-preview img {
  height: 195px !important;
  border: 1px solid #dbdbdb;
  border-radius: 0.5rem;
}

.img-loading img {
  filter: opacity(0.5);
}

button.delete.is-small {
  background: hsl(243.08deg 100% 69.41%);
}
</style>
