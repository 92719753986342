<template>
  <section>
    <div>
      <!-- <b-field>
        <div class="is-flex is-block-mobile">
          <b-input
            v-model="addOnItemName"
            placeholder="Name an add-on item"
            class="mb-4 mr-3 is-flex-grow-1"
          ></b-input>

          <b-button
            icon-left="plus"
            class="is-secondary-btn"
            :disabled="addOnItemName === ''"
            @click="createAddOn"
          >
            Create
          </b-button>
        </div>
      </b-field> -->
      <div
        v-if="addOns.length === 0"
        class="is-size-6 has-text-grey has-text-centered rounded has-background-light mt-4 p-4"
      >
        No Add-on yet
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ItemAddOns",
  components: {},
  props: { addOnsProp: Array },
  data() {
    return {
      addOns: this.addOnsProp,
      addOnItemName: "",
    };
  },
  computed: {},
  methods: { createAddOn() {} },
};
</script>
