<template>
  <section class="has-background-light mx-auto" style="height: 100vh">
    <div class="mx-auto has-border-grey" style="width: 100%; max-width: 500px">
      <div
        class="has-background-light mb-2"
        style="
          width: 100%;
          max-width: 500px;
          position: fixed !important;
          z-index: 1;
        "
      >
        <div class="p-2 is-flex has-background-white mb-3">
          <div>
            <b-button
              type="is-text"
              icon-right="arrow-left"
              @click="backToMenu"
            ></b-button>
          </div>
          <div class="is-flex-grow-1 p-2">
            <div class="has-text-weight-semibold has-text-centered">
              My Cart
            </div>
          </div>
          <div>
            <b-button
              :disabled="cartItems.length === 0"
              type="is-danger is-light"
              icon-right="trash-can-outline"
              @click="confirmClearAllItems"
            ></b-button>
          </div>
        </div>
      </div>

      <div
        style="height: calc(100vh - 178px); overflow-y: auto; margin-top: 70px"
      >
        <CartItemList
          v-if="cartItems"
          :cartItems="cartItems"
          :updatePaymentDetails="updatePaymentDetails"
          :editCartItem="editCartItem"
        />
        <!-- TODO -->
        <PaymentMethod v-if="cartItems.length > 0" />
      </div>

      <div
        class="has-background-white px-4 py-3 has-border-grey"
        style="bottom: 0px; position: fixed; width: 100%; max-width: 500px"
      >
        <div class="is-flex mb-2 mx-1">
          <div class="is-flex-grow-1">Total</div>
          <div class="has-text-weight-semibold">
            {{ `RM ${Number(totalAmount).toFixed(2)}` }}
          </div>
        </div>

        <b-button
          type="is-primary"
          expanded
          class="mt-auto py-5"
          :disabled="cartItems?.length === 0"
          @click="placeOrder"
          >Place Order
        </b-button>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isPlacingOrder"
      :can-cancel="false"
    ></b-loading>

    <EditCartItem
      v-if="isEditingCartItem"
      :cartItem="selectedCartItem"
      :isEditCartItemModalActiveProp="isEditingCartItem"
      :closeEditCartItemModal="closeEditCartItemModal"
    />
  </section>
</template>

<script>
import CartItemList from "@/components/User/CartView/CartItemList.vue";
import EditCartItem from "@/components/User/CartView/EditCartItem.vue";

import PaymentMethod from "@/components/User/CartView/PaymentMethod.vue";

export default {
  name: "CartView",
  components: { CartItemList, EditCartItem, PaymentMethod },
  data() {
    return {
      subtotal: 0,
      serviceCharge: 0,
      serviceTax: 0,
      totalAmount: 0,

      paymentMethod: "off-platform",

      selectedCartItem: {},
      isEditingCartItem: false,

      isPlacingOrder: false,
    };
  },
  computed: {
    cartItems() {
      return this.$store.getters["cartModule/getCartItems"];
    },
  },
  async created() {
    // TODO: check again for phone login in future
    // await this.$store.dispatch(
    //   "cartModule/retrieveCartItemsByMerchant",
    //   this.$route.params.merchantId
    // );
  },

  methods: {
    backToMenu() {
      this.$router.push(
        `/user/menu/${this.$route.params.merchantId}?tableName=${this.$route.query.tableName}`
      );
    },

    editCartItem(cartItem) {
      this.selectedCartItem = cartItem;
      this.isEditingCartItem = true;
    },
    closeEditCartItemModal() {
      this.selectedCartItem = {};
      this.isEditingCartItem = false;
    },

    // TODO: create payment

    async placeOrder() {
      this.isPlacingOrder = true;

      const result = await this.$store.dispatch("cartModule/placeOrder", {
        items: [...this.cartItems],
        subtotal: this.subtotal,

        tableName: this.$route.query.tableName,
        merchantId: this.$route.params.merchantId,

        paymentMethod: this.paymentMethod,
        paymentStatus: "pending",

        status: "active",
      });

      if (result.success) {
        // Update UI with loaded data
        // Show order placed success view
        await this.$store.dispatch(
          "cartModule/clearCartItemsOnOrdersPlaced",
          this.$route.params.merchantId
        );

        this.$router.push(
          `/user/menu/${this.$route.params.merchantId}/order-confirmed?tableName=${this.$route.query.tableName}`
        );
      } else {
        // Display error message
      }

      this.isPlacingOrder = false;
      // TODO: if order placed successfully, clear cart data and redirect to status view
    },

    updatePaymentDetails(data) {
      // TODO: charge and tax percentage by merchants
      this.subtotal = data.subtotal;
      this.serviceCharge = data.serviceCharge;
      this.serviceTax = data.serviceTax;
      this.totalAmount = data.totalAmount;
    },
    confirmClearAllItems() {
      this.$buefy.dialog.confirm({
        title: "Empty the cart",
        message: "Are you sure you want to remove all items from your cart?",
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () =>
          this.clearAllItems() &&
          this.$buefy.toast.open("Removing items from cart ..."),
      });
    },
    clearAllItems() {
      console.log("clear all items");
      this.$store.dispatch(
        "cartModule/clearCartItems",
        this.$route.params.merchantId
      );
    },
  },
};
</script>

<style>
.modal-card.animation-content {
  padding: 1rem;
}
/* header.modal-card-head {
  border-radius: 1rem 1rem 0 0;
}
footer.modal-card-foot {
  border-radius: 0 0 1rem 1rem;
} */
</style>
