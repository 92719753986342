import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";
import { query, where, onSnapshot } from "firebase/firestore";
//import { getAuth } from "firebase/auth";

const state = {
  userOrders: [],
  filter: "active",
};

const getters = {
  //TODO: Realtime listener
  getFilter: (state) => state.filter,
  getUserOrders(state) {
    // if (state.filter == "active") {
    //   return state.userOrders.filter(
    //     (order) =>
    //       order.status === "active" || order.paymentStatus === "pending"
    //   );
    // } else if (state.filter == "completed") {
    //   return state.userOrders.filter(
    //     (order) =>
    //       order.status === "completed" && order.paymentStatus === "paid"
    //   );
    // }
    return state.userOrders;
  },
  //   todosFiltered(state) {
  //     if (state.filter == "all") {
  //       return state.todos;
  //     } else if (state.filter == "active") {
  //       return state.todos.filter((todo) => !todo.completed);
  //     } else if (state.filter == "completed") {
  //       return state.todos.filter((todo) => todo.completed);
  //     }
  //     return state.todos;
  //   },
};

const mutations = {
  updateOrdersFilter(state, filter) {
    state.filter = filter;
  },
  retrieveTableOrdersByMerchant(state, orders) {
    state.userOrders = orders;
  },

  addOrder(state, item) {
    state.userOrders.push(item);
  },
};

const actions = {
  async initTableOrdersRealtimeListeners({ commit }, data) {
    const querySnapshot = await query(
      collection(db, "Orders"),
      where("merchantId", "==", data.merchantId),
      where("tableName", "==", data.tableName),
      where("status", "==", "active")
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("addOrder", change.doc.data());
          }
        }
        if (change.type === "modified") {
          // commit("updateItem", change.doc.data());
          // commit("updateItemStock", change.doc.data());
        }
        if (change.type === "removed") {
          // commit("removeItem", change.doc.id);
        }
      });
    });
  },

  async retrieveTableOrdersByMerchant({ commit }, data) {
    // let isLoginAsGuest = getAuth().currentUser.isAnonymous;
    // let userId;
    // if (!isLoginAsGuest) {
    //   userId = getAuth().currentUser.phoneNumber.replace("+", "");
    // } else {
    //   userId = getAuth().currentUser.uid;
    // }

    const querySnapshot = await getDocs(
      query(
        collection(db, "Orders"),
        where("merchantId", "==", data.merchantId),
        where("tableName", "==", data.tableName),
        where("status", "==", "active")
      )
    );
    let temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      temp.push(data);
    });

    commit("retrieveTableOrdersByMerchant", temp);
  },

  updateOrdersFilter({ commit }, filter) {
    commit("updateOrdersFilter", filter);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
