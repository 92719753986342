<template>
  <section
    class="has-background-light mx-auto"
    style="height: 90vh; max-width: 500px"
  >
    <div
      class="mx-auto"
      style="
        width: 100%;
        max-width: 500px;

        z-index: 1;
      "
    >
      <div class="has-background-light mb-2" style="max-width: 500px">
        <div class="px-4 py-3 is-flex has-background-white mb-2">
          <div class="is-flex-grow-1 mr-2">
            <p class="is-size-7">
              {{ `Table ${this.$route.query.tableName}` }}
            </p>
            <div
              v-if="merchantInfo.name"
              class="has-text-weight-semibold is-size-6 ellipsis-merchant-name"
            >
              {{ merchantInfo.name }}
            </div>
            <b-skeleton
              v-else
              size="is-large"
              height="18"
              width="calc(100vw - 140px)"
            />
          </div>
          <!-- <b-button
            type="is-danger is-light"
            class="rd-br-sm"
            @click="confirmLogout()"
            >Logout</b-button
          > -->
          <div class="is-flex-grow-1">
            <b-button
              class="rd-br-sm"
              icon-left="receipt-text-outline"
              @click="goToMyOrders()"
              ><span class="is-size-6">Orders</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO -->
    <b-tabs class="p-0 mobile-nav" animated expanded>
      <b-tab-item label="General Menu">
        <div class="rounded">
          <SelectCategory
            v-if="categories.length !== 0"
            :categories="categories"
            :selectedCategory="selectedCategory"
            :getItems="getItems"
          />
          <div v-else class="px-4 py-3">
            <div>
              <div class="has-background-white rounded p-3">
                <b-skeleton size="is-large" height="16" class="mb-2" />
                <b-skeleton size="is-large" height="34" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="pl-3 pr-2"
          style="
            overflow-y: scroll;
            max-height: calc(100vh - 310px);
            scroll-behavior: smooth;
            padding-bottom: 86px;
          "
        >
          <div class="has-background-white rounded">
            <MenuItemList
              v-if="!isItemsLoading"
              :items="itemsByCategory"
              :addToCart="addToCart"
            />

            <div v-else>
              <div class="p-3 is-flex" v-for="i in 5" :key="i">
                <div class="is-narrow">
                  <b-skeleton height="64" width="64" />
                </div>
                <div class="pl-3 is-flex-grow-1">
                  <b-skeleton
                    class="mb-3"
                    size="is-large"
                    height="10"
                    width="150"
                  />
                  <b-skeleton size="is-large" height="10" width="50" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

      <b-tab-item label="Order with Code">
        <div class="rounded">
          <CodeInput :getItemByCode="getItemByCode" />
        </div>

        <div
          class="pl-3 pr-2 pb-3"
          style="
            overflow-y: scroll !important;
            max-height: calc(100vh - 310px);
            scroll-behavior: smooth;
            padding-bottom: 86px;
          "
        >
          <div class="has-background-white rounded">
            <ItemByCode
              v-if="!isItemByCodeLoading"
              :itemByCode="itemByCode"
              :addToCart="addToCart"
            />

            <div v-else class="p-3 is-flex">
              <div class="is-narrow">
                <b-skeleton height="64" width="64" />
              </div>
              <div class="pl-3 is-flex-grow-1">
                <b-skeleton
                  class="mb-3"
                  size="is-large"
                  height="10"
                  width="150"
                />
                <b-skeleton size="is-large" height="10" width="50" />
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

      <!-- TODO -->
      <!-- <b-tab-item
        icon="wallet-giftcard"
        v-if="merchantInfo.isLoyaltyProgramEnabled"
      >
        <MyRewards :redeemableItems="redeemableItems" :addToCart="addToCart" />
      </b-tab-item> -->
    </b-tabs>

    <div
      class="has-background-white px-4 py-3 has-border-grey"
      style="bottom: 0px; position: fixed; width: 100%; max-width: 500px"
    >
      <b-button
        type="is-primary"
        expanded
        class="mt-auto py-5"
        icon-left="cart"
        @click="goToCart"
        >View Cart
        <b-tag type="is-primary" class="ml-2 has-border-grey">{{
          cartItemsLength
        }}</b-tag>
      </b-button>
    </div>
    <AddToCart
      v-if="isAddingToCart"
      :item="selectedItem"
      :isAddToCartModalActiveProp="isAddingToCart"
      :closeAddToCartModal="closeAddToCartModal"
    />
  </section>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/firebase";

import SelectCategory from "@/components/User/MerchantMenu/SelectCategory.vue";
import MenuItemList from "@/components/User/MerchantMenu/MenuItemList.vue";

import CodeInput from "@/components/User/MerchantMenu/CodeInput.vue";
import ItemByCode from "@/components/User/MerchantMenu/ItemByCode.vue";

// TODO
// import MyRewards from "@/components/User/MerchantMenu/MyRewards.vue";

import AddToCart from "@/components/User/MerchantMenu/AddToCart.vue";

export default {
  name: "MerchantMenu",
  components: {
    SelectCategory,
    MenuItemList,
    CodeInput,
    ItemByCode,
    AddToCart,
    // MyRewards,
  },
  data() {
    return {
      selectedCategory: {},
      isItemsLoading: true,
      isAddingToCart: false,
      selectedItem: {},

      isItemByCodeLoading: false,

      activeTab: "generalMenu",

      currentUserUID: auth.currentUser.uid,
    };
  },
  async created() {},
  computed: {
    merchantInfo() {
      return this.$store.getters["userModule/getMerchantInfo"];
    },
    categories() {
      let temp = this.$store.getters["menuModule/getCategories"];

      return temp
        .filter((item) => {
          return item.isDisplayOnMenu === true;
        })
        .sort((a, b) => {
          return (
            (a.positionNo === null) - (b.positionNo === null) ||
            +(a.positionNo > b.positionNo) ||
            -(a.positionNo < b.positionNo)
          );
        });
    },
    itemsByCategory() {
      return this.$store.getters["menuModule/getItemsByCategory"].filter(
        (item) => item.isDisplayOnMenu === true
      );
    },
    itemByCode() {
      return this.$store.getters["menuModule/getItemByCode"].filter(
        (item) => item.isDisplayOnMenu === true
      );
    },

    cartItemsLength() {
      return this.$store.getters["cartModule/getCartItemsLength"];
    },

    redeemableItems() {
      return this.$store.getters["rewardsModule/getRedeemableItems"];
    },
  },
  methods: {
    async getItems(category) {
      // Do something with the value
      this.selectedCategory = category;
      // TODO

      await this.$store.dispatch("menuModule/initItemsRealtimeListeners", {
        categoryId: this.selectedCategory.id,
        merchantId: this.$route.params.merchantId,
      });

      this.isItemsLoading = true;

      setTimeout(async () => {
        // TODO
        await this.$store.dispatch("menuModule/retrieveItemsByCategory", {
          categoryId: this.selectedCategory.id,
          merchantId: this.$route.params.merchantId,
        });
        this.isItemsLoading = false;
      }, 500);
    },

    async getItemByCode(itemCode) {
      this.isItemByCodeLoading = true;
      setTimeout(async () => {
        await this.$store.dispatch("menuModule/retrieveItemByCode", {
          itemCode: itemCode,
          merchantId: this.$route.params.merchantId,
        });
        this.isItemByCodeLoading = false;
      }, 500);
    },

    addToCart(item) {
      // TODO
      // if has variation, open modal
      // else add to cart directly
      this.selectedItem = item;
      this.isAddingToCart = true;
    },
    closeAddToCartModal() {
      this.selectedItem = {};
      this.isAddingToCart = false;
    },

    goToMyOrders() {
      this.$router.push(
        `/user/menu/${this.$route.params.merchantId}/orders?tableName=${this.$route.query.tableName}`
      );
    },

    goToCart() {
      this.$router.push(
        `/user/menu/${this.$route.params.merchantId}/cart?tableName=${this.$route.query.tableName}`
      );
    },

    confirmLogout() {
      this.$buefy.dialog.confirm({
        title: "Logout",
        message: "Are you sure you want to logout?",
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () =>
          this.logout() && this.$buefy.toast.open("Logging Out. . ."),
      });
    },
    logout() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.$buefy.toast.open("Logout successfully!");

          //TODO: Fix this
          this.$router.push(
            `/login?merchantId=${this.$route.params.merchantId}&tableName=${this.$route.query.tableName}`
          );
        })
        .catch((error) => {
          // An error happened.
          if (error.code !== undefined) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            this.$buefy.toast.open(`Error: ${errorCode}`);
          }
        });
      location.reload();
    },
  },
};
</script>

<style>
.select-category:active {
  transform: scale(0.99) !important;
}

.mobile-nav nav.tabs {
  max-width: 500px;
}
</style>
