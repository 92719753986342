<template>
  <div class="mx-auto p-5" style="max-width: 400px">
    <div class="card p-5 rounded">
      <div class="has-text-centered">
        <img
          alt="Vue logo"
          src="@/assets/text-logo.png"
          class="rd-logo"
          style="
            height: 45px !important;
            width: auto !important;
            padding-top: 0.5rem;
            image-rendering: -webkit-optimize-contrast;
          "
        />
      </div>
      <!-- <h1 class="is-size-5 has-text-weight-semibold">Sign Up</h1> -->
      <div class="flex mb-5 has-text-centered">
        <span class="is-size-7 has-text-grey mr-1"
          >Already has an account?</span
        >
        <a class="is-size-7 is-clickable" @click="goToLogin">Login</a>
      </div>
      <b-field label="Merchant Name">
        <b-input
          type="text"
          v-model="merchantName"
          :value="merchantName"
          maxlength="50"
          :has-counter="false"
          required
        >
        </b-input>
      </b-field>
      <b-field label="Contact">
        <b-input
          v-model="merchantContact.value"
          :value="merchantContact.value"
          :has-counter="false"
          validation-message="Please enter a valid contact start with 60.."
          :pattern="merchantContact.pattern"
          required
          placeholder="example: 60123334444"
        >
        </b-input>
      </b-field>
      <div class="mb-5">
        <div class="has-text-weight-semibold mb-2">Address</div>
        <b-input
          v-model="detailedAddress"
          placeholder="Detailed Address"
          class="mb-3"
        ></b-input>

        <b-input
          v-model="city"
          placeholder="City"
          class="mb-3 is-flex-grow-1"
        ></b-input>
        <b-field class="mb-3">
          <b-input
            v-model="postcode"
            :value="postcode"
            placeholder="Postcode"
            class="is-flex-grow-1"
            maxlength="5"
            :has-counter="false"
            :pattern="postcodePattern"
            validation-message="Please enter a valid Malaysia postcode"
            required
          ></b-input>
        </b-field>

        <b-input
          v-model="state"
          placeholder="State"
          class="mb-3 is-flex-grow-1"
        ></b-input>
      </div>
      <hr />

      <b-field label="Email">
        <b-input
          type="email"
          v-model="merchantEmail.value"
          :value="merchantEmail.value"
          maxlength="50"
          :has-counter="false"
          validation-message="Please enter a valid email"
          :pattern="merchantEmail.pattern"
          required
        >
        </b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          type="password"
          v-model="password.value"
          :value="password.value"
          password-reveal
          validation-message="8-16 characters, at least one lowercase, one uppercase, one number, one special character"
          :pattern="password.pattern"
          required
        >
        </b-input>
      </b-field>
      <!-- <b-field label="Confirm Password">
        <b-input
          type="password"
          v-model="confirmPassword.value"
          :value="confirmPassword.value"
          password-reveal
          validation-message="Password does not match"
          :pattern="password.value"
          required
          @input="validateInputs"
        >
        </b-input>
      </b-field> -->
      <b-button
        :disabled="!validateInputs || isSigningUp"
        type="is-primary"
        expanded
        class="my-5"
        @click="merchantSignUp"
      >
        {{ isSigningUp ? "Signing up" : "Sign up"
        }}<b-icon
          v-if="isSigningUp"
          icon="loading"
          size="is-small"
          custom-class="fa-spin"
          class="ml-1"
        ></b-icon
      ></b-button>
      <p class="is-size-7 has-text-grey has-text-centered">
        By signing up, you agree to WokyTalk's <br />
        <a>Terms of Service</a> &
        <a>Privacy Policy</a>
      </p>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { ERROR_MESSAGES } from "@/errorCodes.js";

export default {
  name: "MerchantSignUp",
  data() {
    return {
      merchantName: "",
      merchantContact: { value: "", pattern: "^[6]+[0]+[0-9]{9,10}$" },
      merchantEmail: {
        value: "",
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
      },
      password: {
        value: "",
        pattern:
          "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$",
      },
      detailedAddress: "",
      city: "",
      postcode: "",
      postcodePattern: "^[0-9]{5}$",
      state: "",
      // confirmPassword: {
      //   value: "",
      // },
      isSigningUp: false,
    };
  },
  computed: {
    validateInputs() {
      const regexContact = new RegExp(this.merchantContact.pattern);
      const regexEmail = new RegExp(this.merchantEmail.pattern);
      const regexPassword = new RegExp(this.password.pattern);
      const regexPostcode = new RegExp(this.postcodePattern);

      if (
        regexEmail.test(this.merchantEmail.value) &&
        regexPassword.test(this.password.value) &&
        this.merchantName !== "" &&
        regexContact.test(this.merchantContact.value) &&
        this.detailedAddress !== "" &&
        this.city !== "" &&
        regexPostcode.test(this.postcode) &&
        this.state !== ""
        // && this.password.value === this.confirmPassword.value
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    merchantSignUp() {
      this.isSigningUp = true;
      const auth = getAuth();
      createUserWithEmailAndPassword(
        auth,
        this.merchantEmail.value,
        this.password.value
      )
        .then(async (userCredential) => {
          await this.addMerchantInfo();
          // TODO: if failed to insert to database, how? redirect to contact support page

          const user = userCredential.user;
          console.log("res", userCredential);
          this.isSigningUp = false;
          this.$buefy.toast.open("Account has been registered successfully!");

          // Inform user to check their email for verification
          const actionCodeSettings = {
            url: `https://${window.location.host}/merchant/login`,
          };

          await sendEmailVerification(user, actionCodeSettings);

          this.$buefy.toast.open(
            `Verification email sent to ${this.merchantEmail.value}`
          );
          // Display a message to the user
          this.$buefy.toast.open(
            "Please check your email to verify your account before login"
          );

          this.$router.push("/merchant/login");
        })
        // TODO: check why keep go to catch although success when internet connection weak
        .catch((error) => {
          console.log(error);
          if (error.code !== undefined) {
            const errorCode = error.code;
            // const errorMessage = error.message;

            this.$buefy.toast.open(
              `Error: ${ERROR_MESSAGES[errorCode] || errorCode}`
            );
          }
          this.isSigningUp = false;
        });
    },

    addMerchantInfo() {
      this.$store.dispatch("merchantModule/addMerchantInfo", {
        name: this.merchantName,
        contact: this.merchantContact.value,
        email: this.merchantEmail.value,
        detailedAddress: this.detailedAddress,
        city: this.city,
        postcode: this.postcode,
        state: this.state,
      });
    },
    goToLogin() {
      this.$router.push("/merchant/dashboard");
    },
  },
};
</script>
