<template>
  <section>
    <div class="responsive-padding" style="padding-right: 1rem !important">
      <div class="mb-3 is-flex">
        <div>
          <b-button
            class="mr-3"
            type="is-text"
            icon-right="arrow-left"
            @click="backToDashboard"
          ></b-button>
        </div>
        <div class="has-text-weight-bold my-auto is-size-5">
          Table {{ this.$route.params.tableName }}
        </div>
        <b-field grouped group-multiline class="ml-3 my-auto">
          <div class="control">
            <b-tag type="is-danger">{{ ordersByTable.length }}</b-tag>
          </div>
        </b-field>

        <!-- <div class="ml-auto">right button</div> -->
      </div>
      <div class="pt-3 table-orders-container">
        <TableOrderList v-if="!isLoading" :ordersByTable="ordersByTable" />

        <div v-else>
          <div class="pb-3" v-for="i in 3" :key="i">
            <b-skeleton size="is-large" height="120" />
          </div>
        </div>
      </div>

      <div class="has-background-white table-orders-bottom-bar">
        <!-- <div class="is-hidden-tablet is-flex">
          <div class="is-flex-grow-1 pr-4 has-text-center pb-1">
            <p>
              <b-tag class="mr-1" type="is-success is-light">Paid</b-tag>
              <span class="has-text-weight-semibold"
                >RM {{ paidOrders.totalAmount.toFixed(2) }}</span
              >
            </p>
          </div>
          <div class="is-flex-grow-1 pl-4 has-text-center pb-1">
            <p>
              <b-tag class="mr-1" type="is-warning is-light">Pending</b-tag>
              <span class="has-text-weight-semibold mr-1">
                RM {{ pendingOrders.totalAmount.toFixed(2) }}</span
              >
              <b-icon
                v-if="pendingOrders.totalAmount != 0"
                icon="alert-circle-outline"
                size="is-small"
                type="is-danger"
              >
              </b-icon>
            </p>
          </div>
        </div> -->
        <!-- <hr class="is-hidden-tablet mt-2 mb-3" /> -->
        <!-- <div class="is-hidden-tablet is-flex mb-3">
        <div class="is-flex-grow-1">
          <p class="is-size-6 has-text-grey">Paid</p>
          <p class="is-size-6">Pending</p>
        </div>
        <div class="is-flex-grow-1 has-text-right">
          <p class="is-size-6 has-text-weight-medium">RM 12.00</p>
          <p class="is-size-6 has-text-weight-semibold">RM 53.00</p>
        </div>
      </div> -->

        <div class="">
          <div class="is-flex p-3">
            <div class="has-text-grey pr-3">
              <p class="">Paid Orders</p>
            </div>

            <div
              class="has-text-right is-flex-grow-1"
              :class="
                paidOrders.count === allOrders.count ? '' : 'has-text-danger'
              "
            >
              <b-icon
                v-if="
                  paidOrders.count === allOrders.count && allOrders.count > 0
                "
                class="mr-1"
                icon="check-circle"
                type="is-success"
                size="is-small"
              ></b-icon>
              {{ paidOrders.count }}
              <b-icon icon="slash-forward" type="is-grey" size="is-small">
              </b-icon>
              {{ allOrders.count }}
              <span class="ml-1">paid</span>
            </div>
          </div>

          <hr class="my-0" />

          <div class="is-flex p-3">
            <div class="has-text-grey pr-3">
              <p class="is-size-5">Total</p>
            </div>
            <div class="has-text-right is-flex-grow-1">
              <p class="has-text-weight-semibold is-size-5">
                <b-tooltip
                  position="is-top"
                  multilined
                  type="is-dark"
                  size="is-small"
                >
                  <b-button
                    class="mr-1"
                    type="is-text"
                    icon-right="information-outline"
                    size="is-small"
                  />
                  <template v-slot:content>
                    <div class="is-flex">
                      <div class="pr-3 is-flex-grow-1 has-text-left">
                        Subtotal
                      </div>
                      <div>{{ allOrders.subtotal.toFixed(2) }}</div>
                    </div>
                    <div class="is-flex">
                      <div class="pr-3 is-flex-grow-1 has-text-left">
                        Service Charge
                      </div>
                      <div>{{ serviceCharge.toFixed(2) }}</div>
                    </div>
                    <div class="is-flex">
                      <div class="pr-3 is-flex-grow-1 has-text-left">
                        Service Tax
                      </div>
                      <div>{{ serviceTax.toFixed(2) }}</div>
                    </div>
                  </template>
                </b-tooltip>
                {{ `RM ${totalAmount}` }}
              </p>
            </div>
          </div>
        </div>

        <div class="is-flex">
          <!-- <div class="has-text-left py-2 has-text-grey">
            {{ selectedOrders.length }} / {{ ordersByTable.length }} selected
          </div> -->
          <!-- <div class="has-text-left py-2 has-text-grey">
          {{ paidOrders.count }}/{{ ordersByTable.length }} paid
        </div> -->
          <div
            class="has-text-right is-flex-grow-1 is-flex is-justify-content-right"
          >
            <div class="is-flex">
              <!-- <div
                class="px-4 has-text-left py-2"
                style="border-right: 1px solid #dbdbdd"
              >
                <p>
                  <b-tag class="mr-1" type="is-success is-light">Paid</b-tag>
                  <span class="has-text-weight-semibold"
                    >RM {{ paidOrders.totalAmount.toFixed(2) }}</span
                  >
                </p>
              </div>
              <div class="px-4 has-text-left py-2">
                <p>
                  <b-tag class="mr-1" type="is-warning is-light">Pending</b-tag>
                  <span class="has-text-weight-semibold mr-1">
                    RM {{ pendingOrders.totalAmount.toFixed(2) }}</span
                  >
                  <b-icon
                    v-if="pendingOrders.totalAmount != 0"
                    icon="alert-circle-outline"
                    size="is-small"
                    type="is-danger"
                  >
                  </b-icon>
                </p>
              </div> -->
            </div>

            <b-button
              class="mr-3 is-secondary-btn"
              icon-left="pencil"
              :disabled="
                paidOrders.count === allOrders.count || allOrders.count <= 0
              "
              @click="confirmSetAllOrderAsPaid()"
              >Set All as Paid</b-button
            >

            <b-button
              type="is-primary"
              :disabled="
                paidOrders.count !== allOrders.count || allOrders.count <= 0
              "
              @click="confirmCompleteOrders"
              >Complete Orders</b-button
            >
            <!--  -->
            <!--  -->
          </div>
        </div>
      </div>
    </div>

    <InvoiceModal
      v-if="isInvoiceModalActive"
      :isInvoiceModalActiveProp="isInvoiceModalActive"
      :closeInvoiceModal="closeInvoiceModal"
      :invoice="selectedInvoice"
    />
  </section>
</template>

<script>
import TableOrderList from "@/components/Merchant/TableDetailsPage/TableOrderList.vue";
import InvoiceModal from "@/components/Merchant/InvoicesPage/InvoiceModal.vue";

export default {
  name: "TableDetailsPage",
  components: {
    TableOrderList,
    InvoiceModal,
  },
  data() {
    return {
      isLoading: false,
      selectedOrders: [],

      selectedInvoice: {},
      isInvoiceModalActive: false,
    };
  },
  mounted() {
    this.isLoading = true;

    setTimeout(async () => {
      this.isLoading = false;
    }, 300);
  },
  async created() {
    await this.$store.dispatch(
      "merchantOrdersModule/setSelectedTableName",
      this.$route.params.tableName
    );
  },
  computed: {
    ordersByTable() {
      return this.$store.getters["merchantOrdersModule/getOrdersByTableName"];
    },
    allOrders() {
      if (this.ordersByTable.length !== 0) {
        return this.ordersByTable.reduce(
          (acc, obj) => {
            acc.subtotal += Number(obj.subtotal);

            acc.count++;

            return acc;
          },
          { count: 0, subtotal: 0 }
        );
      } else {
        return { count: 0, subtotal: 0 };
      }
    },
    getHasServiceCharge() {
      return this.$store.getters["merchantModule/getMerchantInfo"]
        .hasServiceCharge;
    },
    serviceCharge() {
      let serviceChargePercentage = this.getHasServiceCharge ? 0.1 : 0;
      return this.allOrders.subtotal * serviceChargePercentage;
    },

    getHasServiceTax() {
      return this.$store.getters["merchantModule/getMerchantInfo"]
        .hasServiceTax;
    },
    serviceTax() {
      let serviceTaxPercentage = this.getHasServiceTax ? 0.06 : 0;
      return this.allOrders.subtotal * serviceTaxPercentage;
    },

    totalAmount() {
      return (
        this.allOrders.subtotal +
        this.serviceTax +
        this.serviceCharge
      ).toFixed(2);
    },
    //
    paidOrders() {
      if (this.ordersByTable.length !== 0) {
        return this.ordersByTable.reduce(
          (acc, obj) => {
            if (obj.paymentStatus === "paid") {
              acc.totalAmount += Number(obj.totalAmount);
              acc.count++;
            }
            return acc;
          },
          { count: 0, totalAmount: 0 }
        );
      } else {
        return { count: 0, totalAmount: 0 };
      }
    },
    pendingOrders() {
      if (this.ordersByTable.length !== 0) {
        return this.ordersByTable.reduce(
          (acc, obj) => {
            if (obj.paymentStatus === "pending") {
              acc.totalAmount += Number(obj.totalAmount);
              acc.count++;
            }
            return acc;
          },
          { count: 0, totalAmount: 0 }
        );
      } else {
        return { count: 0, totalAmount: 0 };
      }
    },
    orderIds() {
      let orderIds = [];
      for (let i = 0; i < this.ordersByTable.length; i++) {
        orderIds.push(this.ordersByTable[i].id);
      }
      return orderIds;
    },

    ordersItems() {
      const filteredArray = this.ordersByTable.map((obj) => {
        return { id: obj.id, items: obj.items };
      });
      return filteredArray;
    },
  },
  methods: {
    backToDashboard() {
      this.$router.push(`/merchant/dashboard`);
    },
    confirmCompleteOrders() {
      this.$buefy.dialog.confirm({
        title: "Complete Orders",
        message:
          "Are you sure you want to complete orders and clear this table?",
        confirmText: "Yes",
        type: "is-primary",

        onConfirm: () => this.onConfirmCompleteOrders(),
      });
    },

    async onConfirmCompleteOrders() {
      try {
        // Call createInvoice and completeOrders
        await this.createInvoice();
        await this.completeOrders();
        this.$buefy.toast.open("Completing orders ...");

        // Watch for changes in the latestInvoice
        const unwatch = this.$store.watch(
          (state) => state.merchantInvoiceModule.latestInvoice,
          (latestInvoice) => {
            // Once the latestInvoice is updated, open the invoice modal
            this.openInvoiceModal(latestInvoice);
            console.log("debugggg", latestInvoice);
            // Stop watching for changes
            unwatch();
          }
        );
      } catch (error) {
        // Handle errors
        console.error("Error completing orders:", error);
        // Show error message
        this.$buefy.toast.open({
          message: "Error completing orders",
          type: "is-danger",
        });
      }
    },

    async createInvoice() {
      this.$store.dispatch("merchantInvoiceModule/createInvoice", {
        tableName: this.$route.params.tableName,
        orderIds: this.orderIds,
        ordersItems: this.ordersItems,
        orderQuantity: this.allOrders.count,
        subtotal: this.allOrders.subtotal.toFixed(2),
        serviceCharge: this.serviceCharge.toFixed(2),
        serviceTax: this.serviceTax.toFixed(2),
        totalAmount: this.totalAmount,
      });
    },

    completeOrders() {
      this.$store.dispatch(
        "merchantOrdersModule/completeOrders",
        this.orderIds
      );
      this.selectedOrders = [];
    },

    confirmSetAllOrderAsPaid() {
      this.$buefy.dialog.confirm({
        title: "Update orders payment status",
        message:
          "Have you received the payment from the customer for all these orders?",
        confirmText: "Yes, payment received",
        type: "is-danger",
        hasIcon: true,
        icon: "alert-circle-outline",
        onConfirm: () => this.setOrdersAsPaid(),
      });
    },
    async setOrdersAsPaid() {
      await this.$store.dispatch(
        "merchantOrdersModule/setOrdersAsPaid",
        this.orderIds
      );
    },

    openInvoiceModal(invoice) {
      this.selectedInvoice = invoice;
      this.isInvoiceModalActive = true;
    },
    closeInvoiceModal() {
      this.selectedInvoice = {};
      this.isInvoiceModalActive = false;
    },
  },
};
</script>

<style>
.table-orders-container {
  height: calc(100vh - 315px);
  overflow-y: auto;
  padding-right: 1rem;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 3.2rem;
}

@media screen and (max-width: 768px) {
  .table-orders-container {
    height: calc(100vh - 315px);
  }
}

.table-orders-bottom-bar {
  width: 100%;
  border-top: 1px solid #dbdbdb;
}
</style>
