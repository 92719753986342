import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";
import { query, onSnapshot, where } from "firebase/firestore";

import { ToastProgrammatic as Toast } from "buefy";

const state = {
  categories: [],
  currentItems: [],
  itemByCode: [],
};

const getters = {
  getCategories: (state) => state.categories,
  getItemsByCategory: (state) => state.currentItems,
  getItemByCode: (state) => state.itemByCode,
};

const mutations = {
  retrieveCategories(state, categories) {
    state.categories = categories;
  },
  retrieveItemsByCategory(state, items) {
    state.currentItems = items;
  },
  //
  addItem(state, item) {
    state.currentItems.push(item);
  },
  updateItem(state, item) {
    var targetIndex = state.currentItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      Object.assign(state.currentItems[targetIndex], item);
    }
    //state.currentItems.splice(targetIndex, 1, item);
  },
  removeItem(state, itemId) {
    // TODO: Debug
    var targetIndex = state.currentItems.findIndex((x) => x.id == itemId);
    if (targetIndex >= 0) {
      state.currentItems.splice(targetIndex, 1);
    }
  },
  updateItemStock(state, item) {
    var targetIndex = state.currentItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      Object.assign(state.currentItems[targetIndex], item);
    }
  },
  retrieveItemByCode(state, itemByCode) {
    state.itemByCode = itemByCode;
  },
};

const actions = {
  async initCategoriesRealtimeListeners(context, merchantId) {
    const querySnapshot = await query(
      collection(db, "Merchants", merchantId, "Categories")
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            // commit("createCategory", {
            //   id: change.doc.id,
            //   name: change.doc.data().name,
            //   positionNo: change.doc.data().positionNo,
            //   timestamp: change.doc.data().timestamp,
            // });
          }
        }
        if (change.type === "modified") {
          //   commit("updateCategory", {
          //     id: change.doc.id,
          //     name: change.doc.data().name,
          //   });
        }
        if (change.type === "removed") {
          //commit("removeCategory", change.doc.id);
        }
      });
    });
  },

  async initItemsRealtimeListeners({ commit }, data) {
    const querySnapshot = await query(
      collection(db, "Merchants", data.merchantId, "Items"),
      where("categoryId", "==", data.categoryId)
    ); // fix this
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("addItem", change.doc.data());
          }
        }
        if (change.type === "modified") {
          commit("updateItem", change.doc.data());
          commit("updateItemStock", change.doc.data());
        }
        if (change.type === "removed") {
          commit("removeItem", change.doc.id);
        }
      });
    });
  },

  async retrieveCategories({ commit }, merchantId) {
    //commit("setLoading", "categories", true);

    const querySnapshot = await getDocs(
      collection(db, "Merchants", merchantId, "Categories") // fix this
    );

    let tempCategories = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      tempCategories.push(data);
    });

    // TODO: sort by position no
    commit("retrieveCategories", tempCategories);
  },

  async retrieveItemsByCategory({ commit }, data) {
    const querySnapshot = await getDocs(
      query(
        collection(db, "Merchants", data.merchantId, "Items"),
        where("categoryId", "==", data.categoryId)
      ) // fix this
    );
    let tempItems = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();
      // {
      //   id: doc.id,
      //   name: doc.data().name,
      //   basePrice: doc.data().basePrice,
      //   hasVariations: doc.data().hasVariations,
      //   variations: doc.data().variations,

      //   categoryId: doc.data().categoryId,
      //   merchantId: doc.data().merchantId,
      //   timestamp: doc.data().timestamp,
      // };
      tempItems.push(data);
    });

    // TODO: sort by position no
    commit("retrieveItemsByCategory", tempItems);
  },

  async retrieveItemByCode({ commit }, data) {
    const q = query(
      collection(db, "Merchants", data.merchantId, "Items"),
      where("code", "==", data.itemCode)
    );

    let tempItem = []; // use array in case merchant set duplicated code (mistake), user still can search it
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();
      tempItem.push(data);
    });

    if (tempItem.length === 0) {
      Toast.open("No result found");
    }

    commit("retrieveItemByCode", tempItem);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
