<template>
  <section>
    <div
      class="mx-auto has-text-centered p-5 rounded has-fade-in-anim my-auto response-container"
      style="max-width: 500px"
    >
      <div>
        <b-image
          :src="require('@/assets/illustration/order_placed.svg')"
          alt=""
          class="mx-auto mb-5"
          style="width: 55%"
        ></b-image>
        <!-- TODO ：remove after display feature added -->
        <div class="mx-auto">Order Confirmed</div>
        <p class="has-text-grey-light is-size-7 mb-5">
          Order has been placed successfully
        </p>
        <b-button type="is-outlined is-primary" @click="goToMyOrders"
          >View Orders</b-button
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlaceOrderSuccessView",
  components: {},
  data() {
    return {};
  },
  async created() {
    await this.$store.dispatch(
      "userOrdersModule/retrieveUserOrdersByMerchant",
      this.$route.params.merchantId
    );
  },
  methods: {
    goToMyOrders() {
      this.$router.push(
        `/user/menu/${this.$route.params.merchantId}/orders?tableName=${this.$route.query.tableName}`
      );
    },
  },
};
</script>

<style>
.response-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to the full viewport height */
}
</style>
