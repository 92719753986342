//import { db } from "@/firebase";
//import { collection, addDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const state = {
  imageURL: "",
  imageFullPath: "",

  uploadProgress: 0,
  isUploadingImage: false,
};

const getters = {
  getImageURL: (state) => state.imageURL,
  getImageFullPath: (state) => state.imageFullPath,
  getUploadProgress: (state) => state.uploadProgress,
  getIsUploadingImage: (state) => state.isUploadingImage,
};

const mutations = {
  setImageURL(state, url) {
    state.imageURL = url;
  },
  setImageFullPath(state, imageFullPath) {
    state.imageFullPath = imageFullPath;
  },
  setUploadProgress(state, progress) {
    state.uploadProgress = progress;
  },
  setIsUploadingImage(state, value) {
    state.isUploadingImage = value;
  },

  clearAll(state) {
    state.imageURL = "";
    state.imageFullPath = "";
    //state.uploadProgress = 0;
  },

  resetUploadProgress(state) {
    state.uploadProgress = 0;
  },
};

const actions = {
  async uploadImage({ rootState, commit }, file) {
    commit("setIsUploadingImage", true);

    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const uniqueId =
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    const storage = getStorage();
    const path = `merchants/${rootState.merchantModule.merchantInfo.id}/product-images/${uniqueId}-${file.name}`;
    const imagesRef = ref(storage, path);

    const metadata = {
      contentType: file.type,
    };

    //TODO: reduce image resolution

    // 'file' comes from the Blob or File API
    const uploadTask = uploadBytesResumable(imagesRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // commit progressPercentage
        console.log("Upload is " + progress + "% done");
        commit("setUploadProgress", progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("something went wrong", error);
        commit("setIsUploadingImage", false);
        commit("resetUploadProgress");
      },
      async () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...

        const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log("File available at", imageURL);
        commit("setImageURL", imageURL);
        commit("setImageFullPath", uploadTask.snapshot.ref.fullPath);

        commit("setIsUploadingImage", false);
        commit("resetUploadProgress");
      }
    );
  },

  deleteImage(context, imageFullPath) {
    const storage = getStorage();

    // Create a reference to the file to delete
    const imageRef = ref(storage, imageFullPath);

    // Delete the file
    deleteObject(imageRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        console.log("error", error);
        // Uh-oh, an error occurred!
      });
  },

  clearAll({ commit }) {
    commit("clearAll");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
