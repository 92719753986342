import { db } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";
// import { getDoc  } from "firebase/firestore";
// import { updateDoc, arrayRemove } from "firebase/firestore";

// import { getAuth } from "firebase/auth";

import { ToastProgrammatic as Toast } from "buefy";

const state = {
  merchantCartItems: [],
};

const getters = {
  getMerchantCartItems: (state) => state.merchantCartItems,
  // getMerchantCartItemsLength: (state) =>
  //   state.cartItems.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue.quantity,
  //     0
  //   ),
};

const mutations = {
  addItemToMerchantCart(state, combinedItems) {
    state.merchantCartItems = combinedItems;
  },

  updateItemInMerchantCart(state, combinedItems) {
    state.merchantCartItems = combinedItems;
  },

  // retrieveCartItemsByMerchant(state, items) {
  //   state.merchantCartItems = items;
  // },

  // placeOrder(state, data) {
  //   state.orders.push(data);
  // },

  removeItemFromMerchantCart(state, item) {
    var targetIndex = state.merchantCartItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      state.merchantCartItems.splice(targetIndex, 1);
    }
  },

  clearMerchantCartItems(state) {
    state.merchantCartItems = [];
  },
};

const actions = {
  async addItemToMerchantCart({ commit, state, rootState }, item) {
    //let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const itemId =
      "F" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    try {
      //const docRef = doc(db, "Merchants",  rootState.merchantModule.merchantInfo.id, "Carts", item.merchantId);
      item.merchantId = rootState.merchantModule.merchantInfo.id;
      console.log("debug", item);
      const date = new Date();
      // TODO: Change all to Carts - MerchantId - Array

      let newCartItems = state.merchantCartItems;

      newCartItems.push({
        id: itemId,
        ...item,
        // userId: userId,
        isKitchenChecked: false,
        createdAt: date,
        updatedAt: date,
      });

      // lodash here
      const _ = require("lodash");

      const combinedItems = [];

      for (let i = 0; i < newCartItems.length; i++) {
        const currentItem = newCartItems[i];
        let found = false;

        for (let j = 0; j < combinedItems.length; j++) {
          const combinedItem = combinedItems[j];
          if (
            currentItem.productId === combinedItem.productId &&
            currentItem.rewardId === combinedItem.rewardId &&
            _.isEqual(
              currentItem.selectedVariations,
              combinedItem.selectedVariations
            ) &&
            _.isEqual(
              currentItem.selectedCollections,
              combinedItem.selectedCollections
            ) &&
            _.isEqual(currentItem.specialRemark, combinedItem.specialRemark)
          ) {
            // Combine quantities ..
            combinedItem.quantity += currentItem.quantity;

            found = true;
            break;
          }
        }

        if (!found) {
          // Add new item to combinedItems array
          combinedItems.push(currentItem);
        }
      }
      //

      // await setDoc(docRef, {
      //   cartItems: combinedItems,
      //   merchantId: item.merchantId,
      // });

      commit("addItemToMerchantCart", combinedItems);

      Toast.open("Added to cart");
      return { success: true };
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong. Please try again.");
      return { success: false, error: e.message };
    }
  },

  async updateItemInMerchantCart({ commit, state }, item) {
    //let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    try {
      // const docRef = doc(db, "Users", userId, "Carts", item.merchantId);

      const date = new Date();
      let formattedItem = {
        ...item,
        updatedAt: date,
      };

      let newCartItems = state.merchantCartItems;

      var targetIndex = newCartItems.findIndex((x) => x.id == item.id);
      if (targetIndex >= 0) {
        Object.assign(newCartItems[targetIndex], formattedItem);
      }

      // lodash here
      const _ = require("lodash");

      const combinedItems = [];

      for (let i = 0; i < newCartItems.length; i++) {
        const currentItem = newCartItems[i];
        let found = false;

        for (let j = 0; j < combinedItems.length; j++) {
          const combinedItem = combinedItems[j];
          if (
            currentItem.productId === combinedItem.productId &&
            currentItem.rewardId === combinedItem.rewardId &&
            _.isEqual(
              currentItem.selectedVariations,
              combinedItem.selectedVariations
            ) &&
            _.isEqual(
              currentItem.selectedCollections,
              combinedItem.selectedCollections
            ) &&
            _.isEqual(currentItem.specialRemark, combinedItem.specialRemark)
          ) {
            // Combine quantities ..
            combinedItem.quantity += currentItem.quantity;

            found = true;
            break;
          }
        }

        if (!found) {
          // Add new item to combinedItems array
          combinedItems.push(currentItem);
        }
      }
      //

      // await setDoc(docRef, {
      //   cartItems: combinedItems,
      //   merchantId: item.merchantId,
      // });

      commit("updateItemInMerchantCart", combinedItems);

      Toast.open("Updated Successfully");
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong, please try again");
    }
  },

  // TODO: Change all to Carts - MerchantId - Array
  // async retrieveCartItemsByMerchant({ commit }, merchantId) {
  //   let userId = getAuth().currentUser.phoneNumber.replace("+", "");

  //   const docRef = doc(db, "Users", userId, "Carts", merchantId);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     commit("retrieveCartItemsByMerchant", docSnap.data().cartItems);
  //   } else {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //   }
  // },

  // TODO: retrieve orders

  async removeItemFromMerchantCart({ commit }, item) {
    try {
      //let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      // const merchantCartRef = doc(
      //   db,
      //   "Users",
      //   userId,
      //   "Carts",
      //   data.merchantId
      // );

      // await updateDoc(merchantCartRef, {
      //   cartItems: arrayRemove(data.item),
      // })
      //   .then(() => {
      //     Toast.open("Item has been removed from cart");
      //   })
      //   .catch((error) => {
      //     Toast.open("Something went wrong", error);
      //   });

      commit("removeItemFromMerchantCart", item);
      return { success: true };
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong. Please try again.");
      return { success: false, error: e.message };
    }
  },

  async clearMerchantCartItems({ commit }) {
    //let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    //const merchantCartRef = doc(db, "Users", userId, "Carts", merchantId);

    // await updateDoc(merchantCartRef, {
    //   cartItems: [],
    // })
    //   .then(() => {
    //     Toast.open("All items have been removed from cart");
    //   })
    //   .catch((error) => {
    //     Toast.open("Something went wrong", error);
    //   });

    commit("clearMerchantCartItems");
  },

  async clearMerchantCartItemsOnOrdersPlaced({ commit }) {
    //let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    // const merchantCartRef = doc(db, "Users", userId, "Carts", merchantId);

    // await updateDoc(merchantCartRef, {
    //   cartItems: [],
    // })
    //   .then(() => {})
    //   .catch((error) => {
    //     Toast.open("Something went wrong", error);
    //   });

    commit("clearMerchantCartItems");
  },

  async placeOrder({ rootState }, data) {
    //let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const orderId =
      "R" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    let formattedData = {
      ...data,
      id: orderId,
      userId: "guest",
      merchantId: rootState.merchantModule.merchantInfo.id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    try {
      await setDoc(doc(db, "Orders", orderId), formattedData);

      //commit("placeOrder", formattedData);
      Toast.open("Order has been placed successfully ✅");
      return { success: true };
    } catch (e) {
      console.error("Error adding document: ", e);

      Toast.open("Something went wrong, please try again");
      return { success: false, error: e.message };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
