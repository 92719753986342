<template>
  <section class="has-background-white px-1 py-2 rounded mx-3 mb-3">
    <!-- TODO: apply v-for, add clickble open product details component -->

    <div
      v-if="cartItems?.length === 0"
      class="has-text-centered p-5 rounded has-fade-in-anim"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/empty_cart.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <!-- TODO ：remove after display feature added -->
        <div class="has-text-grey mx-auto">Your cart is empty</div>
        <p class="has-text-grey-light is-size-7 mb-3">
          Looks like you haven't added anything to your cart yet.
        </p>
        <a class="is-size-7" @click="backToMenu">Back to menu</a>
      </div>
    </div>
    <div v-else class="has-fade-in-anim">
      <article
        v-for="cartItem in cartItems"
        :key="cartItem.id"
        class="is-flex product-list-item"
        @click="editCartItem(cartItem)"
      >
        <div
          class="media-content is-flex cart-list-item-content rounded-2 p-3"
          style="overflow: hidden"
        >
          <!-- <b-button class="mr-3" size="is-small">
            {{ cartItem.quantity }}
          </b-button> -->
          <!-- <b-field>
            <b-numberinput
              v-model="cartItem.quantity"
              :editable="false"
              size="is-small"
            ></b-numberinput>
          </b-field> -->
          <div class="mr-3">
            <div style="width: 45px !important">
              <b-tag class="has-border-grey">{{
                `${cartItem.quantity} x`
              }}</b-tag>
            </div>
            <!-- <p class="image is-64x64 mr-3">
              <img
                class="rounded"
                src="https://bulma.io/images/placeholders/128x128.png"
              />
              
            </p> -->
          </div>

          <div class="content">
            <p class="has-text-weight-semibold is-size-6 is-size-7-mobile mb-0">
              {{ cartItem.name }}
              <!-- <b-icon size="is-small" icon="arrow-top-right"></b-icon> -->
            </p>

            <p
              v-if="cartItem.isRewardItem"
              class="is-size-7 has-text-grey mb-0"
            >
              Reward
            </p>

            <p class="is-size-7 has-text-grey mb-0">
              <span
                v-for="(
                  selectedVariation, selectedVariationIndex
                ) in cartItem.selectedVariations"
                :key="selectedVariationIndex"
              >
                {{
                  selectedVariation.name +
                  (selectedVariationIndex !==
                  cartItem.selectedVariations?.length - 1
                    ? ", "
                    : "")
                }}
              </span>
            </p>

            <p class="is-size-7 has-text-grey mb-0">
              <span
                v-for="(
                  selectedCollection, selectedCollectionIndex
                ) in cartItem.selectedCollections"
                :key="selectedCollectionIndex"
              >
                <span
                  v-for="(
                    selection, selectionIndex
                  ) in selectedCollection.selections"
                  :key="selectionIndex"
                  >{{
                    selection.name +
                    (selectionIndex !==
                    selectedCollection.selections?.length - 1
                      ? ", "
                      : "")
                  }}
                </span></span
              >
            </p>
            <!-- TODO: Set width in right way to let ellipsis appear -->
            <p class="is-size-7 has-text-grey mb-0 ellipsis-remark">
              {{ cartItem.specialRemark }}
            </p>
            <a class="is-size-7">Edit</a>
          </div>
        </div>

        <div class="media-right is-flex">
          <!-- <b-button class="is-hidden-tablet" icon-left="list-status">
              </b-button> -->
          <div class="p-3">
            <p class="is-size-6 is-size-7-mobile">
              <!-- TODO: fix logic -->

              RM
              {{
                (
                  Number(cartItem.totalPrice) * Number(cartItem.quantity)
                ).toFixed(2)
              }}
            </p>
          </div>
        </div>
      </article>
      <hr class="my-3" />
      <div class="is-flex px-3 mb-1">
        <div class="is-flex-grow-1 is-size-7">Subtotal</div>
        <div class="is-size-7">RM {{ this.subtotal }}</div>
      </div>
      <div class="is-flex px-3 mb-1">
        <div class="is-flex-grow-1 has-text-grey is-size-7">
          Service Charge 10%
        </div>
        <div class="has-text-grey is-size-7">RM {{ this.serviceCharge }}</div>
      </div>
      <div class="is-flex px-3 mb-1">
        <div class="is-flex-grow-1 has-text-grey is-size-7">Service Tax 6%</div>
        <div class="has-text-grey is-size-7">RM {{ this.serviceTax }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CartItemList",
  props: {
    cartItems: Array,
    editCartItem: { type: Function },
    updatePaymentDetails: { type: Function },
  },
  computed: {
    // formattedCartItems() {
    //   const _ = require("lodash");

    //   const combinedItems = [];

    //   for (let i = 0; i < this.cartItems.length; i++) {
    //     const currentItem = this.cartItems[i];
    //     let found = false;

    //     for (let j = 0; j < combinedItems.length; j++) {
    //       const combinedItem = combinedItems[j];
    //       if (
    //         currentItem.productId === combinedItem.productId &&
    //         _.isEqual(currentItem.variations, combinedItem.variations) &&
    //         _.isEqual(currentItem.collections, combinedItem.collections)
    //       ) {
    //         // Combine quantities and update price
    //         combinedItem.quantity += currentItem.quantity;

    //         found = true;
    //         break;
    //       }
    //     }

    //     if (!found) {
    //       // Add new item to combinedItems array
    //       combinedItems.push(currentItem);
    //     }
    //   }

    //   return combinedItems;
    // },
    subtotal() {
      let subtotal = 0;
      for (let i = 0; i < this.cartItems?.length; i++) {
        subtotal =
          subtotal +
          Number(this.cartItems[i].totalPrice * this.cartItems[i].quantity);
      }
      return subtotal.toFixed(2);
    },

    getHasServiceCharge() {
      return this.$store.getters["userModule/getMerchantInfo"].hasServiceCharge;
    },
    serviceCharge() {
      let serviceChargePercentage = this.getHasServiceCharge ? 0.1 : 0;
      return (this.subtotal * serviceChargePercentage).toFixed(2);
    },

    getHasServiceTax() {
      return this.$store.getters["userModule/getMerchantInfo"].hasServiceTax;
    },
    serviceTax() {
      let serviceTaxPercentage = this.getHasServiceTax ? 0.06 : 0;
      return (this.subtotal * serviceTaxPercentage).toFixed(2);
    },

    totalAmount() {
      return (
        Number(this.subtotal) +
        Number(this.serviceCharge) +
        Number(this.serviceTax)
      ).toFixed(2);
    },
  },

  async created() {
    await this.$store.dispatch(
      "userModule/retrieveMerchantInfo",
      this.$route.params.merchantId
    );
  },
  data() {
    return {};
  },
  updated() {
    // TODO: get merchant's charge and tax percentage
    this.updatePaymentDetails({
      subtotal: this.subtotal,
      serviceCharge: this.serviceCharge,
      serviceTax: this.serviceTax,
      totalAmount: this.totalAmount,
    });
  },
  methods: {
    backToMenu() {
      this.$router.push(
        `/user/menu/${this.$route.params.merchantId}?tableName=${this.$route.query.tableName}`
      );
    },
  },
};
</script>

<style>
.cart-list-item-content:hover {
}
.cart-list-item + .cart-list-item {
  border-top: 1px solid #f5f5f5 !important;
}
</style>
