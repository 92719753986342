<template>
  <section class="p-3">
    <!-- <div v-if="order?.length === 0" class="has-text-centered has-fade-in-anim">
 
      <div class="has-background-white rounded p-3 mb-3">
        <div class="has-text-centered p-5 rounded">
          <b-image
            :src="require('@/assets/illustration/blank.svg')"
            alt=""
            class="is-128x128 mx-auto mb-5"
          ></b-image>
        
          <div class="has-text-grey mx-auto mb-2">No completed orders yet</div>

          <p class="has-text-grey-light is-size-7 mb-3">
            No order has been completed in the last 10 minutes on this table,
            check with the staff
          </p>
        </div>
      </div>
    </div> -->

    <div>
      <div
        class="has-background-white rounded p-4 mb-5"
        style="border: 1px solid #dcdcdc"
      >
        <div class="is-flex mb-3">
          <span class="is-flex-grow-1 has-text-grey is-size-6">{{
            formatTimestamp(invoice.updatedAt)
          }}</span>

          <span class="is-size-6 has-text-grey">
            <!-- {{ order.status }} -->
            <!-- <b-icon
                  v-if="order.status === 'active'"
                  icon="circle-slice-8"
                  size="is-small"
                  type="is-success"
                >
                </b-icon> -->
          </span>
        </div>

        <div class="mb-3">
          <div v-for="order in invoice.ordersItems" :key="order.id">
            <article
              v-for="item in order.items"
              :key="item.id"
              class="is-flex product-list-item"
            >
              <div
                class="media-content is-flex cart-list-item-content rounded-2 py-3"
                style="overflow: hidden"
              >
                <!-- <b-button class="mr-3" size="is-small">
                {{ cartItem.quantity }}
              </b-button> -->
                <!-- <b-field>
                <b-numberinput
                  v-model="cartItem.quantity"
                  :editable="false"
                  size="is-small"
                ></b-numberinput>
              </b-field> -->
                <figure class="mr-3">
                  <b-tag class="has-border-grey">{{
                    `${item.quantity} x`
                  }}</b-tag>
                  <!-- <p class="image is-64x64 mr-3">
                  <img
                    class="rounded"
                    src="https://bulma.io/images/placeholders/128x128.png"
                  />
                  
                </p> -->
                </figure>
                <div class="content">
                  <p class="is-size-6 is-size-7-mobile mb-0">
                    {{ item.name }}
                    <!-- <b-icon size="is-small" icon="arrow-top-right"></b-icon> -->
                  </p>
                  <p class="is-size-7 has-text-grey mb-0">
                    <span
                      v-for="(
                        selectedVariation, selectedVariationIndex
                      ) in item.selectedVariations"
                      :key="selectedVariationIndex"
                    >
                      {{
                        selectedVariation.name +
                        (selectedVariationIndex !==
                        item.selectedVariations?.length - 1
                          ? ", "
                          : "")
                      }}
                    </span>
                  </p>

                  <p class="is-size-7 has-text-grey mb-0">
                    <span
                      v-for="(
                        selectedCollection, selectedCollectionIndex
                      ) in item.selectedCollections"
                      :key="selectedCollectionIndex"
                    >
                      <span
                        v-for="(
                          selection, selectionIndex
                        ) in selectedCollection.selections"
                        :key="selectionIndex"
                        >{{
                          selection.name +
                          (selectionIndex !==
                          selectedCollection.selections?.length - 1
                            ? ", "
                            : "")
                        }} </span
                      ><br />
                    </span>
                  </p>
                  <!-- TODO: Set width in right way to let ellipsis appear -->
                  <p
                    v-if="item.specialRemark !== ''"
                    class="is-size-7 has-text-grey-light mb-0 ellipsis-remark"
                  >
                    {{ `" ${item.specialRemark} "` }}
                  </p>
                </div>
              </div>

              <div class="media-right is-flex">
                <!-- <b-button class="is-hidden-tablet" icon-left="list-status">
                  </b-button> -->
                <div class="py-3 pl-3">
                  <p class="is-size-6 is-size-7-mobile">
                    {{
                      (Number(item.totalPrice) * Number(item.quantity)).toFixed(
                        2
                      )
                    }}
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>

        <!-- <div class="has-text-grey has-text-centered">View more</div> -->

        <hr class="my-3" />
        <div class="is-flex">
          <div class="is-flex-grow-1 is-size-7 has-text-grey">Subtotal</div>
          <div class="is-size-7 has-text-grey">{{ invoice.subtotal }}</div>
        </div>
        <div class="is-flex">
          <div class="is-flex-grow-1 has-text-grey is-size-7">
            Service Charge
          </div>
          <div class="has-text-grey is-size-7">
            {{ invoice.serviceCharge }}
          </div>
        </div>
        <div class="is-flex">
          <div class="is-flex-grow-1 has-text-grey is-size-7">Service Tax</div>
          <div class="has-text-grey is-size-7">{{ invoice.serviceTax }}</div>
        </div>

        <hr class="my-2" />
        <div class="is-flex mb-3">
          <span class="is-flex-grow-1 has-text-grey">Total</span>
          <span class="has-text-weight-semibold"
            >RM {{ invoice.totalAmount }}</span
          >
        </div>

        <hr class="my-0" />

        <div class="is-flex mt-3">
          <div class="has-text-grey pr-3 is-flex-grow-1 has-text-left">
            <p class="is-size-6">Payment</p>
          </div>
          <div class="has-text-right" style="width: 200px">
            <p>
              <span class="is-size-6 mr-2 has-text-grey">{{
                invoice.paymentMethod
              }}</span>
              <b-tag
                v-if="invoice.paymentStatus === 'pending'"
                type="is-warning is-light"
                >Pending</b-tag
              >
              <b-tag v-else type="is-success is-light">Paid</b-tag>
            </p>
          </div>
        </div>

        <b-message
          v-if="
            invoice.paymentMethod === 'off-platform' &&
            invoice.paymentStatus === 'pending'
          "
          type="is-light is-danger"
          size="is-small"
          :closable="false"
          class="is-size-7 rounded-2 my-4"
        >
          Please pay at counter. Thank you!
        </b-message>
        <hr class="mt-3 mb-0" />
        <div
          class="is-size-7 has-text-centered my-3 is-flex is-justify-content-center"
        >
          <b-icon icon="leaf-circle" type="is-success" class="mr-1 pt-1" />

          <div class="pt-1 has-text-grey">
            Reduce paper waste with digital invoices
          </div>
        </div>
        <b-button
          icon-left="arrow-down"
          expanded
          @click="downloadReceipt(invoice)"
          >Download Invoice</b-button
        >
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "InvoiceItem",
  props: {
    // updatePaymentMethod: { type: Function },
  },
  data() {
    return {};
  },
  async created() {},
  computed: {
    invoice() {
      return this.$store.getters["userInvoiceModule/getInvoice"];
    },

    //   ordersFilter() {
    //     return this.$store.getters["userOrdersModule/getFilter"];
    //   },

    merchantInfo() {
      return this.$store.getters["userInvoiceModule/getMerchantInfo"];
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      const year = date.getFullYear();

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string
      const formattedDate = `${day} ${month} ${year}, ${hours}.${minutes} ${amPm}`;

      return formattedDate;
    },

    formatDateTimestamp(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      const year = date.getFullYear();

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string
      const formattedDate = `${day} ${month} ${year}, ${hours}.${minutes} ${amPm}`;

      return formattedDate;
    },

    downloadReceipt(invoice) {
      const receiptContent = `
        <html>
          <head>
            <style>
              * {
                font-family: poppins, sans-serif;
              }

              h1 {
                font-size: 18px;
                margin-bottom: 0.5rem;
              }

              h2 {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 5px;
              }

              h5{
                font-size: 12px;
                font-weight: 400;
                margin-top: 0;
                margin-bottom: 2px;
              }

              .px{
                padding-left: 12px;
                padding-right: 12px;
              }

              p{
                font-size: 10px;
              }

              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
                margin-bottom: 20px;
              }

              td, th {
                font-size: 12px;
                padding-top: 4px;
                padding-bottom: 4px;
                padding-right: 8px;
                border-bottom: none;
                vertical-align: top;
              }

              .has-text-left{
                text-align: left;
              }
              .has-text-right{
                text-align: right;
              }
              .has-text-center{
                text-align: center;
              }

              .has-border-top{
                border-top: 1px solid #ddd;
              }

              .total-row {
                font-weight: bold;
              }
              .total-row td {
                font-size: 14px;
                padding-top: 8px;
              }

              .footer {
                text-align: center;
                font-size: 8px;
                margin-top: 20px;
              }
              .ellipsis{
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 124px;
                overflow: hidden;
              }
            </style>
          </head>
          <body>

            <h1 class="has-text-center">${this.merchantInfo.name}</h1>
            <h5 class="has-text-center">${
              this.merchantInfo.companyName || ""
            } ${
        this.merchantInfo.companyCode
          ? `(${this.merchantInfo.companyCode})`
          : ""
      }</h5>
            <h5 class="has-text-center px">${
              this.merchantInfo.detailedAddress || ""
            }</h5>
            <h5 class="has-text-center">${this.merchantInfo.city || ""} ${
        this.merchantInfo.postcode
      } ${this.merchantInfo.state || ""}</h5>
            <h5 class="has-text-center">Contact: +${
              this.merchantInfo.contact
            }</h5>
            <h5 class="has-text-center">${
              this.merchantInfo.SSTNo
                ? `SST No.: ${this.merchantInfo.SSTNo}`
                : ""
            }</h5>
            <br>
            <h1 class="has-text-center">Invoice</h1>
            <h2>${invoice.tableName}</h2>
            <h5>Order ID: ${invoice.id}</h5>
            <h5>Date & Time: ${this.formatDateTimestamp(invoice.createdAt)}</h5>

            <table>
              <thead>
                <tr>
                  <th class="has-text-left">Item</th>
                  <th>Qty</th>
                  <th class="has-text-right">Price</th>
                  <th class="has-text-right">Total</th>
                </tr>
              </thead>

              <div v-for="order in invoice.ordersItems" :key="order.id">
            <article
              v-for="item in order.items"

              <tbody>
                ${invoice.ordersItems.map(
                  (order) =>
                    `
                ${order.items
                  .map(
                    (item) => `
                  <tr>
                    <td>
                      ${item.name}<br>
                     <div>
                      ${item.selectedVariations.map(
                        (variation) => `- ${variation.name}`
                      )}</div><div>
                      ${item.selectedCollections.map((collection) =>
                        collection.selections.map(
                          (selection) => `- ${selection.name}`
                        )
                      )}</div>
                      <div class="ellipsis">
                      ${
                        item.specialRemark !== ""
                          ? `- "${item.specialRemark}"`
                          : ""
                      }</div>
                    </td>
                    <td class="has-text-center">${item.quantity}</td>
                    <td class="has-text-right">${Number(
                      item.totalPrice
                    ).toFixed(2)}</td>
                    <td class="has-text-right">${(
                      Number(item.totalPrice) * Number(item.quantity)
                    ).toFixed(2)}</td>
                  </tr>
                `
                  )
                  .join("")}`
                )}
                  <tr class="has-border-top">
                <td colspan="3">Subtotal:</td>
                <td class="has-text-right">${invoice.subtotal}</td>
              </tr>
              <tr>
                <td colspan="3">Service Tax (6%):</td>
                <td class="has-text-right">${invoice.serviceTax}</td>
              </tr>
              <tr >
                <td colspan="3">Service Charge (10%):</td>
                <td class="has-text-right">${invoice.serviceCharge}</td>
              </tr>

                <tr class="total-row has-border-top">
                  <td colspan="3">Total</td>
                  <td class="has-text-right">${invoice.totalAmount}</td>
                </tr>
              </tbody>
            </table>

            <div class="footer">
              <p>THANK YOU, PLEASE COME AGAIN</p>
              <p>Powered by WokyTalk</p>
            </div>
          </body>
        </html>
      `;

      var opt = {
        margin: 3,
        filename: `Invoice - ${invoice.id}.pdf`,
        // image:        { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: {
          unit: "mm",
          format: [80, 320],
          orientation: "portrait",
        },
      };

      // Convert the HTML content to PDF
      html2pdf().set(opt).from(receiptContent).save();
    },
  },
};
</script>
