<template>
  <section>
    <div
      v-if="ordersByTable?.length === 0"
      class="has-text-centered p-5 rounded has-fade-in-anim has-border-grey"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/no_data.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <div class="has-text-grey mx-auto">No order from this table yet</div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="order in ordersByTable"
        :key="order.id"
        class="has-border-grey rounded mb-4"
      >
        <div
          class="p-3 has-text-grey has-background-light is-flex"
          style="border-radius: 0.75rem 0.75rem 0 0"
        >
          <!-- <div class="is-flex-grow-1 is-flex space-between">
            <b-checkbox
              v-model="selectedOrders"
              :native-value="{
                id: order.id,
                totalAmount: order.totalAmount,
                paymentMethod: order.paymentMethod,
                paymentStatus: order.paymentStatus,
              }"
              @input="updateSelectedOrders(selectedOrders)"
            ></b-checkbox>

            <p
              class="has-text-weight-medium has-text-grey my-auto pr-4"
              type="is-white"
            >
              # {{ orderIndex + 1 }}
            </p>

          </div> -->

          <p class="my-auto is-size-6 is-flex-grow-1">
            {{ formatDateTimestamp2(order.createdAt) }}
          </p>
        </div>

        <div class="pt-2 px-3 pb-3">
          <div
            v-for="item in order.items"
            :key="item.id"
            class="columns is-mobile order-item-row"
          >
            <div class="column is-narrow pt-1 px-0 pb-0">
              <div
                class="has-text-weight-medium has-text-centered"
                style="width: 40px !important"
              >
                {{ `${item.quantity}` }}
              </div>
            </div>
            <div class="column is-narrow pt-1 pb-0 pl-0">
              <span class="has-text-weight-semibold has-text-grey-light"
                ><b-icon icon="close" size="is-small"> </b-icon
              ></span>
            </div>
            <div class="column pt-1 px-0 pb-0 pr-3">
              <div class="columns">
                <div class="column is-two-fifths py-0 pl-0 mb-2 is-size-6">
                  {{ item.name }}
                </div>
                <div class="column is-three-fifths p-0 is-size-6">
                  <b-tag
                    v-if="item.serviceMode === 'Takeaway'"
                    icon="shopping-outline"
                    size="is-small"
                    class="mb-1 has-border-grey"
                    >Takeaway</b-tag
                  >
                  <p class="has-text-grey">
                    <span
                      v-for="(
                        selectedVariation, selectedVariationIndex
                      ) in item.selectedVariations"
                      :key="selectedVariationIndex"
                    >
                      {{
                        selectedVariation.name +
                        (selectedVariationIndex !==
                        item.selectedVariations?.length - 1
                          ? ", "
                          : "")
                      }}
                    </span>
                  </p>
                  <p class="has-text-grey">
                    <span
                      v-for="(
                        selectedCollection, selectedCollectionIndex
                      ) in item.selectedCollections"
                      :key="selectedCollectionIndex"
                    >
                      <span
                        v-for="(
                          selection, selectionIndex
                        ) in selectedCollection.selections"
                        :key="selectionIndex"
                        >{{
                          selection.name +
                          (selectionIndex !==
                          selectedCollection.selections?.length - 1
                            ? ", "
                            : "")
                        }} </span
                      ><br />
                    </span>
                  </p>
                  <p
                    v-if="item.specialRemark !== ''"
                    class="has-text-grey mb-0 is-italic"
                  >
                    {{ `" ${item.specialRemark} "` }}
                  </p>
                </div>

                <div class="is-hidden-tablet mt-2">
                  <p
                    v-if="item.isKitchenChecked === true"
                    class="has-text-grey is-size-6"
                  >
                    Prepared
                    <b-icon
                      icon="check"
                      size="is-small"
                      type="is-grey"
                    ></b-icon>
                  </p>
                  <p v-else class="has-text-grey is-size-6">Preparing ...</p>
                </div>
              </div>
            </div>
            <div class="column is-narrow pt-1 pr-3 pb-0 pl-0 is-hidden-mobile">
              <div class="is-size-7" style="width: 100px">
                <p
                  v-if="item.isKitchenChecked === true"
                  class="has-text-grey is-size-6"
                >
                  Prepared
                  <b-icon icon="check" size="is-small" type="is-grey"></b-icon>
                </p>
                <p v-else class="has-text-grey is-size-6">Preparing ...</p>
              </div>
            </div>

            <div class="column is-narrow pt-1 px-0 pb-0">
              <div
                class="has-text-weight-medium has-text-right"
                style="width: 90px"
              >
                {{
                  (Number(item.totalPrice) * Number(item.quantity)).toFixed(2)
                }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr class="my-0" />
          <div class="p-3">
            <div class="ml-auto is-flex">
              <div
                class="is-size-6 has-text-grey pr-3 is-flex-grow-1 has-text-left mt-2"
              >
                Total
              </div>

              <div class="is-flex has-text-right has-text-weight-semibold">
                <b-tooltip
                  position="is-left"
                  multilined
                  type="is-dark"
                  size="is-small"
                >
                  <b-button
                    class="mr-2"
                    type="is-text"
                    icon-right="information-outline"
                  />
                  <template v-slot:content>
                    <div class="is-flex">
                      <div class="pr-3 is-flex-grow-1 has-text-left">
                        Subtotal
                      </div>
                      <div>{{ order.subtotal }}</div>
                    </div>
                    <div class="is-flex">
                      <div class="pr-3 is-flex-grow-1 has-text-left">
                        Service Charge
                      </div>
                      <div>{{ order.serviceCharge }}</div>
                    </div>
                    <div class="is-flex">
                      <div class="pr-3 is-flex-grow-1 has-text-left">
                        Service Tax
                      </div>
                      <div>{{ order.serviceTax }}</div>
                    </div>
                  </template>
                </b-tooltip>
                <div class="mt-2">{{ order.totalAmount }}</div>
              </div>
            </div>
          </div>
          <hr class="my-0" />
          <div class="p-3">
            <div class="is-flex">
              <div
                class="is-size-6 has-text-grey pr-3 is-flex-grow-1 has-text-left"
                :class="order.paymentStatus === 'pending' ? 'mt-1' : ''"
              >
                Payment status
              </div>
              <div class="has-text-right">
                <div>
                  <!-- <span class="mr-2 has-text-grey">{{
                    order.paymentMethod
                  }}</span> -->
                  <div v-if="order.paymentStatus === 'pending'">
                    <b-tag type="is-warning is-light" class="mt-1"
                      >Pending</b-tag
                    ><b-button
                      class="ml-2"
                      :icon-left="isOpen ? 'chevron-up' : 'chevron-down'"
                      type="is-text"
                      size="is-small"
                      @click="isOpen = !isOpen"
                      :aria-expanded="isOpen"
                      aria-controls="contentIdForA11y2"
                    />
                  </div>
                  <b-tag v-else type="is-success is-light">Paid</b-tag>
                </div>
              </div>
            </div>

            <b-collapse
              aria-id="contentIdForA11y2"
              animation="slide"
              v-model="isOpen"
            >
              <div
                v-if="
                  order.paymentStatus === 'pending' &&
                  order.paymentMethod === 'off-platform'
                "
                class="is-flex mt-3"
              >
                <p
                  class="is-size-6 has-text-danger pr-3"
                  style="align-self: center"
                >
                  <b-icon
                    icon="alert-circle-outline"
                    type="is-danger"
                    size="is-small"
                  >
                  </b-icon>
                  Please collect payment from the customer
                </p>
                <b-button
                  size="is-small"
                  class="ml-auto is-secondary-btn"
                  icon-left="pencil"
                  @click="
                    confirmSetOrderAsPaid(
                      order.id,
                      order.totalAmount,
                      order.userId
                    )
                  "
                  >Set as Paid</b-button
                >
              </div>
            </b-collapse>
          </div>
        </div>

        <!-- <hr class="my-0" />
        <div class="p-3">
          <b-button
            icon-left="printer"
            expanded
            @click="printReceiptTest(order)"
            >Print receipt</b-button
          >
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
//import escpos from "escpos";\
//import SerialPort from "browser-serialport";

export default {
  name: "TableOrderList",
  props: {
    ordersByTable: Array,
  },
  components: {},
  data() {
    return {
      selectedOrders: [],
      port: null,
      isOpen: false,
    };
  },
  computed: {
    merchantInfo() {
      return this.$store.getters["merchantModule/getMerchantInfo"];
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string

      const formattedDate = `${hours}.${minutes} ${amPm}`;

      return formattedDate;
    },

    formatDateTimestamp(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      const year = date.getFullYear();

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string
      const formattedDate = `${day} ${month} ${year}, ${hours}.${minutes} ${amPm}`;

      return formattedDate;
    },

    formatDateTimestamp2(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      //const year = date.getFullYear();

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string
      const formattedDate = `${day} ${month}, ${hours}.${minutes} ${amPm}`;

      return formattedDate;
    },

    confirmSetOrderAsPaid(orderId, orderAmount, userId) {
      this.$buefy.dialog.confirm({
        title: "Update order payment status",
        message:
          "Have you received the payment from the customer for this order?",
        confirmText: "Yes, payment received",
        type: "is-danger",
        hasIcon: true,
        icon: "alert-circle-outline",
        onConfirm: () => this.setOrderAsPaid(orderId, orderAmount, userId),
      });
    },

    async setOrderAsPaid(orderId, orderAmount, userId) {
      await this.$store.dispatch(
        "merchantOrdersModule/setOrderAsPaid",
        orderId
      );

      // TODO: update order with this orderId in selectedOrders
      var targetIndex = this.selectedOrders.findIndex((x) => x.id == orderId);

      if (targetIndex >= 0) {
        this.selectedOrders[targetIndex].paymentStatus = "paid";
      }

      // If merchant enabled loyalty program, customer gain points after paid
      if (this.merchantInfo.isLoyaltyProgramEnabled) {
        await this.$store.dispatch(
          "merchantRewardsModule/updateUserMerchantMemberPoint",
          {
            userId: userId,
            pointChange: orderAmount,
          }
        );
      }
    },

    printReceipt(order) {
      // Get the current date and time
      //const dateTime = new Date().toLocaleString();

      // Create the receipt template
      const receiptContent = `
    <html>
      <head>
        <style>
        @page {  size: 80mm auto; }
          body { width: 80mm; height: fit-content; margin: 0px; padding: 12px; }

          * {
            font-family: poppins, sans-serif;
          }

          h1 {
            font-size: 18px;
            margin-bottom: 0.5rem;
          }

          h2 {
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 5px;
          }

          h5{
            font-size: 12px;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 2px;
          }

          .px{
            padding-left: 12px;
            padding-right: 12px;
          }

          p{
            font-size: 10px;
          }

          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          td, th {
            font-size: 12px;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-right: 8px;
            border-bottom: none;
            vertical-align: top;
          }

          .has-text-left{
            text-align: left;
          }
          .has-text-right{
            text-align: right;
          }
          .has-text-center{
            text-align: center;
          }

          .has-border-top{
            border-top: 1px solid #ddd;
          }

          .total-row {
            font-weight: bold;
          }
          .total-row td {
            font-size: 14px;
            padding-top: 8px;
          }

          .footer {
            text-align: center;
            font-size: 8px;
            margin-top: 20px;
          }
          .ellipsis{
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 124px;
            overflow: hidden;
          }
        </style>
      </head>
      <body>

        <h1 class="has-text-center">${this.merchantInfo.name}</h1>
        <h5 class="has-text-center">${this.merchantInfo.companyName || ""} ${
        this.merchantInfo.companyCode
          ? `(${this.merchantInfo.companyCode})`
          : ""
      }</h5>
        <h5 class="has-text-center px">${
          this.merchantInfo.detailedAddress || ""
        }</h5>
        <h5 class="has-text-center">${this.merchantInfo.city || ""} ${
        this.merchantInfo.postcode
      } ${this.merchantInfo.state || ""}</h5>
        <h5 class="has-text-center">Contact: +${this.merchantInfo.contact}</h5>
        <h5 class="has-text-center">${
          this.merchantInfo.SSTNo ? `SST No.: ${this.merchantInfo.SSTNo}` : ""
        }</h5>
        <br>
        <h1 class="has-text-center">Invoice</h1>
        <h2>${order.tableName}</h2>
        <h5>Order ID: ${order.id}</h5>
        <h5>Date & Time: ${this.formatDateTimestamp(order.createdAt)}</h5>

        <table>
          <thead>
            <tr>
              <th class="has-text-left">Item</th>
              <th>Qty</th>
              <th class="has-text-right">Price</th>
              <th class="has-text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            ${order.items
              .map(
                (item) => `
              <tr>
                <td>
                  ${item.name}<br>
                 <div>
                  ${item.selectedVariations.map(
                    (variation) => `- ${variation.name}`
                  )}</div><div>
                  ${item.selectedCollections.map((collection) =>
                    collection.selections.map(
                      (selection) => `- ${selection.name}`
                    )
                  )}</div>
                  <div class="ellipsis">
                  ${
                    item.specialRemark !== "" ? `- "${item.specialRemark}"` : ""
                  }</div>
                </td>
                <td class="has-text-center">${item.quantity}</td>
                <td class="has-text-right">${Number(item.totalPrice).toFixed(
                  2
                )}</td>
                <td class="has-text-right">${(
                  Number(item.totalPrice) * Number(item.quantity)
                ).toFixed(2)}</td>
              </tr>
            `
              )
              .join("")}
              <tr class="has-border-top">
            <td colspan="3">Subtotal:</td>
            <td class="has-text-right">${order.subtotal}</td>
          </tr>
          <tr>
            <td colspan="3">Service Tax (6%):</td>
            <td class="has-text-right">${order.serviceTax}</td>
          </tr>
          <tr >
            <td colspan="3">Service Charge (10%):</td>
            <td class="has-text-right">${order.serviceCharge}</td>
          </tr>

            <tr class="total-row has-border-top">
              <td colspan="3">Total</td>
              <td class="has-text-right">${order.totalAmount}</td>
            </tr>
          </tbody>
        </table>

        <div class="footer">
          <p>THANK YOU, PLEASE COME AGAIN</p>
          <p>Powered by WokyTalk</p>
        </div>
      </body>
    </html>
  `;

      console.log(receiptContent);

      // // Create a new window for printing
      // const printWindow = window.open("", "Print Receipt");

      // // Write the receipt content to the new window
      // printWindow.document.write(receiptContent);
      // var bodyHeight = printWindow.document.body.offsetHeight + 10;
      // var pageSize = "80mm " + bodyHeight + "px";
      // printWindow.document.styleSheets[0].addRule("@page", "size: " + pageSize);

      // // Print the window
      // printWindow.print();

      // // Close the window
      // // TODO
      // printWindow.close();
    },

    async printReceiptTest(order) {
      console.log(order);

      // ESC/POS commands for your template
      const commands = [
        0x1b,
        0x40, // Initialize printer

        // Add your ESC/POS commands here

        0x0c, // Print and feed paper
      ];

      const uint8Array = new Uint8Array(commands);

      // Send commands to the printer
      if (window.printer) {
        window.printer.send(uint8Array.buffer);
      } else {
        console.error("Printer not connected");
      }

      // const escpos = require("escpos");

      // setTimeout(() => {
      //   console.log("test print start");

      //   // Select the adapter based on your printer type
      //   const device = new escpos.USB();
      //   // const device  = new escpos.Network('localhost');
      //   // const device  = new escpos.Serial('/dev/usb/lp0');

      //   const options = { encoding: "GB18030" /* default */ };
      //   // encoding is optional

      //   const printer = new escpos.Printer(device, options);

      //   device.open(function () {
      //     printer
      //       .font("a")
      //       .align("ct")
      //       .style("bu")
      //       .size(1, 1)
      //       .text("The quick brown fox jumps over the lazy dog")
      //       .text("敏捷的棕色狐狸跳过懒狗")
      //       .barcode("1234567", "EAN8")
      //       .table(["One", "Two", "Three"])
      //       .tableCustom([
      //         { text: "Left", align: "LEFT", width: 0.33 },
      //         { text: "Center", align: "CENTER", width: 0.33 },
      //         { text: "Right", align: "RIGHT", width: 0.33 },
      //       ]);
      //     .qrimage("https://github.com/song940/node-escpos", function () {
      //       this.cut();
      //       this.close();
      //     });
      //   });

      //   console.log("test print end");
      // }, 3000);
    },

    // async connectToPrinter() {
    //   try {
    //     this.port = await navigator.serial.requestPort();
    //     await this.port.open({ baudRate: 115200 });

    //     console.log("Connected to printer");
    //   } catch (error) {
    //     console.error("Failed to connect to printer:", error);
    //   }
    // },
    // async printText() {
    //   try {
    //     const receiptContent =
    //       "Sample Receipt\n\nThank you for your purchase!\n\nItem 1     $10.00\nItem 2     $15.00\nItem 3     $20.00\n\nTotal      $45.00\n\n";
    //     const command = new Uint8Array([0x1b, 0x40]); // ESC/POS command for initialization

    //     const writer = this.port.writable.getWriter();
    //     await writer.write(command);

    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(receiptContent);
    //     await writer.write(data);

    //     await writer.close();
    //     //await this.port.close();

    //     console.log("Text printed");
    //   } catch (error) {
    //     console.error("Failed to print text:", error);
    //   }
    // },
  },
};
</script>
