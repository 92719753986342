import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";
import { query, where } from "firebase/firestore";
import { onSnapshot, Timestamp } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
import { ToastProgrammatic as Toast } from "buefy";

const state = {
  activeOrders: [],
  selectedTableName: "",

  filteredOrdersByDate: [],
};

const getters = {
  getActiveOrders(state) {
    // console.log(state.filter);
    // if (state.filter == "active") {
    //   console.log("active");
    //   return state.activeOrders.filter(
    //     (order) =>
    //       order.status === "preparing" || order.paymentStatus === "pending"
    //   );
    // } else if (state.filter == "completed") {
    //   console.log("completed");
    //   return state.activeOrders.filter(
    //     (order) =>
    //       order.status === "completed" && order.paymentStatus === "completed"
    //   );
    // }
    return state.activeOrders;
  },
  getTablesOrdersData(state, getters, rootState) {
    //let quantityOfTable = rootState.merchantModule.merchantInfo.quantityOfTable;
    let tableNames = rootState.merchantModule.merchantInfo.tableNames;
    let ordersDataByTable = [];

    for (let i = 0; i < tableNames?.length; i++) {
      let tableOrders = state.activeOrders.filter(
        (x) => x.tableName === tableNames[i] && x.status === "active"
      );

      let result = tableOrders.reduce(
        (acc, obj) => {
          obj.items.forEach((item) => {
            acc.totalQuantityOfItems += item.quantity;
            if (item.isKitchenChecked === true) {
              acc.totalQuantityOfCompletedItems += item.quantity;
            }
          });
          if (obj.paymentStatus === "paid") {
            acc.totalPaidOrders++;
          }
          return acc;
        },
        {
          totalQuantityOfItems: 0,
          totalQuantityOfCompletedItems: 0,
          totalPaidOrders: 0,
        }
      );

      ordersDataByTable[i] = {
        tableName: tableNames[i],
        totalQuantityOfItems: result.totalQuantityOfItems,
        totalQuantityOfCompletedItems: result.totalQuantityOfCompletedItems,
        totalOrders: tableOrders.length,
        totalPaidOrders: result.totalPaidOrders,
      };
    }
    return ordersDataByTable;
  },
  getOrdersByTableName(state) {
    return state.activeOrders.filter(
      (order) =>
        order.tableName === state.selectedTableName && order.status === "active"
    );
  },

  getFilteredOrdersByDate(state) {
    return state.filteredOrdersByDate;
  },
};

const mutations = {
  retrieveActiveOrdersByMerchant(state, orders) {
    state.activeOrders = orders;
  },

  newOrderReceived(state, order) {
    state.activeOrders.push(order);
  },
  updateOrder(state, order) {
    var targetIndex = state.activeOrders.findIndex((x) => x.id == order.id);
    if (targetIndex >= 0) {
      Object.assign(state.activeOrders[targetIndex], order);
    }
  },
  setSelectedTableName(state, tableName) {
    state.selectedTableName = tableName;
  },
  setOrderAsPaid(state, orderId) {
    var targetIndex = state.activeOrders.findIndex((x) => x.id == orderId);
    if (targetIndex >= 0) {
      state.activeOrders[targetIndex].paymentStatus = "paid";
    }
  },

  completeOrder(state, orderId) {
    var targetIndex = state.activeOrders.findIndex((x) => x.id == orderId);
    if (targetIndex >= 0) {
      state.activeOrders.splice(targetIndex, 1);
    }
  },

  retrieveOrdersByDate(state, orders) {
    state.filteredOrdersByDate = orders;
  },
};

const actions = {
  async initActiveOrdersRealtimeListeners({ commit, rootState }) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // const todayTimestamp = Timestamp.fromDate(today);

    const querySnapshot = await query(
      collection(db, "Orders"),
      where("merchantId", "==", rootState.merchantModule.merchantInfo.id),
      where("status", "==", "active")
      // where("createdAt", ">=", todayTimestamp)
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          // const source = change.doc.metadata.hasPendingWrites
          //   ? "Local"
          //   : "Server";

          // if (source === "Server") {
          commit("newOrderReceived", change.doc.data());
          // TODO: new order ringtone
          const ringtone = new Audio(require("@/assets/ringtone.mp3"));
          ringtone.play();
          // }
        }
        if (change.type === "modified") {
          commit("updateOrder", change.doc.data());
        }
        if (change.type === "removed") {
          //   commit("removeCategory", change.doc.id);
        }
      });
    });
  },

  async retrieveActiveOrdersByMerchant({ commit, rootState }) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    //const todayTimestamp = Timestamp.fromDate(today);

    const querySnapshot = await getDocs(
      query(
        collection(db, "Orders"),
        where("merchantId", "==", rootState.merchantModule.merchantInfo.id),
        where("status", "==", "active")
        // where("createdAt", ">=", todayTimestamp)
      )
    );
    let temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      temp.push(data);
    });

    commit("retrieveActiveOrdersByMerchant", temp);
  },

  async updateItemKitchenCheckStatus(context, data) {
    const ref = doc(db, "Orders", data.orderId);

    await updateDoc(
      ref,
      {
        items: data.items,
      },
      { merge: true }
    );
    //commit("updateItemKitchenCheckStatus", data);
  },

  setSelectedTableName({ commit }, tableName) {
    commit("setSelectedTableName", tableName);
  },

  async setOrderAsPaid({ commit }, orderId) {
    try {
      const ref = doc(db, "Orders", orderId);

      await updateDoc(
        ref,
        {
          paymentStatus: "paid",
          updatedAt: new Date(),
        },
        { merge: true }
      );
      Toast.open("Order has been updated successfully ✅");

      commit("setOrderAsPaid", orderId);
    } catch (e) {
      console.error("Error removing document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  async setOrdersAsPaid({ commit }, orderIds) {
    try {
      for (let i = 0; i < orderIds.length; i++) {
        const ref = doc(db, "Orders", orderIds[i]);

        await updateDoc(
          ref,
          {
            paymentStatus: "paid",
            updatedAt: new Date(),
          },
          { merge: true }
        );

        commit("setOrderAsPaid", orderIds[i]);
      }
      Toast.open("Orders has been updated successfully ✅");
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
    // TODO: Can be improved
  },

  async completeOrders({ commit }, orderIds) {
    try {
      for (let i = 0; i < orderIds.length; i++) {
        const ref = doc(db, "Orders", orderIds[i]);

        await updateDoc(
          ref,
          {
            status: "completed",
            updatedAt: new Date(),
          },
          { merge: true }
        );

        commit("completeOrder", orderIds[i]);
      }
      Toast.open("Orders has been completed successfully ✅");
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
    // TODO: Can be improved
  },

  async retrieveOrdersByDate({ commit, rootState }, date) {
    const dateStart = new Date(date);
    dateStart.setHours(0, 0, 0, 0);
    const dateStartTimestamp = Timestamp.fromDate(dateStart);

    const dateEnd = new Date(date);
    dateEnd.setHours(24, 0, 0, 0);
    const dateEndTimestamp = Timestamp.fromDate(dateEnd);

    const querySnapshot = await getDocs(
      query(
        collection(db, "Orders"),
        where("merchantId", "==", rootState.merchantModule.merchantInfo.id),
        where("createdAt", ">=", dateStartTimestamp),
        where("createdAt", "<", dateEndTimestamp)
      )
    );
    let temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      temp.push(data);
    });

    commit("retrieveOrdersByDate", temp);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
