<template>
  <b-modal
    v-model="isEditCategoryModalActive"
    scroll="keep"
    :on-cancel="closeEditCategoryModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white">
      <!-- <div class="mb-3 has-text-weight-bold">Add New Item</div> -->
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-bold is-size-5 mb-5">Edit Category</div>
        <b-field label="Category Name" size="is-small">
          <b-input v-model="categoryName" required></b-input>
        </b-field>

        <b-field label="Display on menu" class="pt-3">
          <b-switch type="is-success" v-model="isDisplayOnMenu">{{
            isDisplayOnMenu ? "Enabled" : "Disabled"
          }}</b-switch></b-field
        >

        <!-- <b-field label="Position No." size="is-small">
            <b-numberinput
              v-model="positionNo"
              :min="1"
              :max="this.categories.length + 1"
              type="is-light"
            ></b-numberinput
          ></b-field>
          <b-field label="Preview" grouped group-multiline>
            <div
              class="control"
              v-for="category in categories"
              :key="category.id"
            >
              <b-taglist attached>
                <b-tag>{{
                  category.positionNo === null ? "-" : category.positionNo
                }}</b-tag>
                <b-tag>{{ category.name }}</b-tag>
              </b-taglist>
            </div>
          </b-field> -->
      </div>
    </div>
    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
      <b-button
        type="is-primary action-btn"
        @click="updateCategory"
        :disabled="!isAllRequiredFieldCompleted"
        >Save Changes</b-button
      >
    </footer>
  </b-modal>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';

export default {
  name: "EditCategory",
  components: {},
  props: {
    category: { type: Object, required: true },
    isEditCategoryModalActiveProp: Boolean,
    closeEditCategoryModal: { type: Function },
  },
  data() {
    return {
      categoryName: this.category.name,
      isDisplayOnMenu: this.category.isDisplayOnMenu,
      positionNo: undefined,
    };
  },
  created() {},
  computed: {
    isEditCategoryModalActive: {
      get() {
        return this.isEditCategoryModalActiveProp;
      },
      set() {},
    },

    isAllRequiredFieldCompleted() {
      if (
        this.categoryName !== "" &&
        (this.categoryName !== this.category.name ||
          this.isDisplayOnMenu !== this.category.isDisplayOnMenu)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.closeEditCategoryModal();
    },
    async updateCategory() {
      await this.$store.dispatch("productsModule/updateCategory", {
        id: this.category.id,
        name: this.categoryName,
        isDisplayOnMenu: this.isDisplayOnMenu,
      });

      this.onCancel();
    },
  },
};
</script>
