<template>
  <section>
    <div
      class="is-hidden-tablet has-background-white px-4 py-3 has-border-grey"
      style="
        bottom: 0px;
        position: fixed;
        width: 100%;

        z-index: 1;
      "
    >
      <b-button
        type="is-primary"
        expanded
        class="mt-auto py-5"
        icon-left="cart"
        @click="isCartOpenMobile = true"
        >View Cart
        <!-- <b-tag type="is-primary" class="ml-2 has-border-grey">{{
                  // cartItemsLength
                  1
                }}</b-tag> -->
      </b-button>
    </div>
    <div>
      <!-- <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">Create Order</div>
      </div> -->

      <!-- <b-steps
        v-model="activeStep"
        size="is-small"
        :label-position="'right'"
        :has-navigation="false"
      >
        <b-step-item step="1" label="Select Table" :clickable="false">
          <div class="has-border-grey rounded p-5" style="max-width: 500px"> -->

      <!-- TODO: If membership off? -->
      <!-- <b-field label="Customer Type">
              <b-radio v-model="customerType" name="name" native-value="member">
                Member (existing or new)
              </b-radio>
              <b-radio v-model="customerType" name="name" native-value="guest">
                Guest
              </b-radio>
            </b-field>
            <b-field
              v-if="customerType === 'member'"
              label="Customer Mobile No."
            >
              <div class="is-flex">
                <span
                  class="p-2 mr-2 has-border-grey"
                  style="border-radius: 0.5rem; height: 40px"
                  >+60</span
                >
                <b-input
                  maxlength="10"
                  class="is-flex-grow-1"
                  v-model="userMobileNo.value"
                  :has-counter="false"
                  validation-message="Please enter a valid Malaysian mobile number"
                  :pattern="userMobileNo.pattern"
                  required
                >
                </b-input>
              </div>
            </b-field> -->

      <!-- <b-field label="Select Table">
              <b-select
                placeholder="Please select a table"
                expanded
                v-model="selectedTable"
              >
                <option
                  v-for="(option, index) in merchantTableNames"
                  :value="option"
                  :key="index"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>

            <b-button
              :disabled="!isAllFieldsValid"
              type="is-primary"
              @click="activeStep = 1"
            >
              Next
            </b-button>
          </div>
        </b-step-item>

        <b-step-item step="2" label="Create Order" :clickable="false"> -->
      <!-- :type="{ 'is-success': isProfileSuccess }" -->
      <div class="pr-2 create-order-container">
        <div class="columns">
          <div class="column is-three-fifths py-0 pl-0 pr-0">
            <div class="rounded py-4 pl-4 pr-2 responsiveMenu">
              <div class="is-flex">
                <div
                  class="is-flex-grow-1 is-size-6 has-text-weight-semibold ml-2"
                >
                  Menu
                </div>
              </div>

              <MerchantSelectCategory
                v-if="categories.length !== 0"
                :categories="categories"
                :selectedCategory="selectedCategory"
                :getItems="getItems"
              />
              <div
                v-else
                class="has-text-centered p-5 rounded has-fade-in-anim"
              >
                <div class="has-text-centered pt-3 px-5 rounded">
                  <b-image
                    :src="require('@/assets/illustration/empty.svg')"
                    alt=""
                    class="is-128x128 mx-auto mb-3"
                  ></b-image>
                  <!-- TODO ：remove after display feature added -->
                  <div class="has-text-grey mx-auto is-size-6 mb-1">
                    No category yet
                  </div>
                  <p class="has-text-grey-light is-size-7 mb-3">
                    Looks like you haven't created any category in 'My Products'
                    yet.
                  </p>
                  <a class="is-size-7" @click="goToMyProducts">Create now</a>
                </div>
              </div>
              <!-- <div v-else class="pt-1 px-2 pb-2" style="height: 78px">
                <div class="is-size-6 has-text-grey">
                  <b-skeleton size="is-large" height="16" class="mb-2" />
                <b-skeleton size="is-large" height="34" />

                  There are no categories yet. <a>Create now</a>
                </div>
              </div> -->

              <div class="pr-2 menu-item-list-container">
                <div
                  v-if="categories.length !== 0"
                  class="has-background-white rounded"
                >
                  <MerchantMenuItemList
                    v-if="!isItemsLoading"
                    :items="itemsByCategory"
                    :addToMerchantCart="addToMerchantCart"
                  />

                  <div v-else>
                    <div class="p-3 is-flex" v-for="i in 5" :key="i">
                      <div class="is-narrow">
                        <b-skeleton height="64" width="64" />
                      </div>
                      <div class="pl-3 is-flex-grow-1">
                        <b-skeleton
                          class="mb-3"
                          size="is-large"
                          height="10"
                          width="150"
                        />
                        <b-skeleton size="is-large" height="10" width="50" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="column is-two-fifths responsiveCartUI p-4"
            :class="isCartOpenMobile ? '' : 'is-hidden-mobile'"
          >
            <div class="has-border-grey rounded p-4" style="height: 100%">
              <div class="is-flex mb-3">
                <div class="is-hidden-tablet">
                  <b-button
                    class="mr-3"
                    type="is-text"
                    icon-right="arrow-left"
                    @click="isCartOpenMobile = false"
                  />
                </div>

                <div class="is-flex-grow-1">
                  <div class="is-size-6 py-2 has-text-weight-semibold">
                    Cart
                  </div>
                  <div class="is-size-7">
                    <!-- Guest -->
                    <!-- {{
                          customerType === "member"
                            ? "+60" + userMobileNo.value
                            : "Guest"
                        }} -->
                  </div>
                </div>
                <div>
                  <b-button
                    type="is-danger is-light"
                    icon-right="trash-can-outline"
                    :disabled="merchantCartItems.length === 0"
                    @click="confirmClearAllItems"
                  ></b-button>
                  <!-- :disabled="cartItems.length === 0"
                  @click="confirmClearAllItems" -->
                </div>
              </div>

              <div class="merchant-cart-container">
                <MerchantCartItemList
                  v-if="merchantCartItems"
                  :merchantCartItems="merchantCartItems"
                  :updatePaymentDetails="updatePaymentDetails"
                  :editMerchantCartItem="editMerchantCartItem"
                />
                <!-- TODO -->
                <!-- <PaymentMethod v-if="cartItems.length > 0" /> -->
              </div>

              <div class="has-background-white pt-2">
                <div class="is-flex mb-2 mx-1">
                  <div class="is-flex-grow-1">Total</div>
                  <div
                    class="has-text-weight-semibold"
                    style="padding-right: 0.5rem"
                  >
                    {{ `RM ${Number(totalAmount).toFixed(2)}` }}
                  </div>
                </div>

                <b-field
                  v-if="
                    merchantTableNames !== undefined &&
                    merchantTableNames?.length !== 0
                  "
                  class="mb-4 px-1"
                >
                  <b-select
                    placeholder="Select a table"
                    expanded
                    v-model="selectedTable"
                  >
                    <option
                      v-for="(option, index) in merchantTableNames"
                      :value="option"
                      :key="index"
                    >
                      {{ option }}
                    </option>
                  </b-select>
                </b-field>
                <div
                  v-else
                  class="is-flex has-border-grey rounded-2 px-3 py-2 mb-4"
                >
                  <div class="is-size-6 is-flex-grow-1 has-text-grey">
                    No table yet
                  </div>
                  <div style="display: inline-block; vertical-align: middle">
                    <a class="is-size-7" @click="goToDashboard">Create now</a>
                  </div>
                </div>

                <b-button
                  type="is-primary"
                  expanded
                  class="mt-auto py-5"
                  :disabled="merchantCartItems?.length === 0 || !selectedTable"
                  @click="placeOrder"
                  >Place Order
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </b-step-item
        > -->
      <!-- <template #navigation="{ previous }">
          <div class="mt-4">
            <b-button
              v-if="activeStep !== 0"
              class="mr-3"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
              Previous
            </b-button>
   
          </div>
        </template>
      </b-steps> -->
    </div>

    <AddToMerchantCart
      v-if="isAddingToMerchantCart"
      :item="selectedItem"
      :isAddToMerchantCartModalActiveProp="isAddingToMerchantCart"
      :closeAddToMerchantCartModal="closeAddToMerchantCartModal"
    />

    <EditMerchantCartItem
      v-if="isEditingMerchantCartItem"
      :cartItem="selectedCartItem"
      :isEditMerchantCartItemModalActiveProp="isEditingMerchantCartItem"
      :closeEditMerchantCartItemModal="closeEditMerchantCartItemModal"
    />
  </section>
</template>

<script>
import MerchantSelectCategory from "@/components/Merchant/CreateOrder/MerchantSelectCategory.vue";
import MerchantMenuItemList from "@/components/Merchant/CreateOrder/MerchantMenuItemList.vue";
import AddToMerchantCart from "@/components/Merchant/CreateOrder/AddToMerchantCart.vue";
import MerchantCartItemList from "@/components/Merchant/CreateOrder/MerchantCartItemList.vue";
import EditMerchantCartItem from "@/components/Merchant/CreateOrder/EditMerchantCartItem.vue";

export default {
  name: "CreateOrder",
  components: {
    MerchantSelectCategory,
    MerchantMenuItemList,
    AddToMerchantCart,
    MerchantCartItemList,
    EditMerchantCartItem,
  },
  data() {
    return {
      selectedCategory: {},
      isItemsLoading: true,
      //   activeStep: 0,
      //   customerType: "member",
      userMobileNo: {
        value: "",
        pattern: "^(1)[0-46-9][0-9]{7}|^(1)(1)[0-9]{8}$",
      },
      selectedTable: undefined,

      isAddingToMerchantCart: false,
      selectedItem: {},

      isEditingMerchantCartItem: false,
      selectedCartItem: {},

      subtotal: 0,
      serviceCharge: 0,
      serviceTax: 0,
      totalAmount: 0,

      paymentMethod: "off-platform",

      isCartOpenMobile: false,
    };
  },
  computed: {
    categories() {
      let temp = this.$store.getters["productsModule/getCategories"];

      return temp
        .filter((item) => {
          return item.isDisplayOnMenu === true;
        })
        .sort((a, b) => {
          return (
            (a.positionNo === null) - (b.positionNo === null) ||
            +(a.positionNo > b.positionNo) ||
            -(a.positionNo < b.positionNo)
          );
        });
    },
    itemsByCategory() {
      return this.$store.getters["productsModule/getItemsByCategory"].filter(
        (item) => item.isDisplayOnMenu === true
      );
    },

    merchantCartItems() {
      return this.$store.getters["merchantCartModule/getMerchantCartItems"];
    },

    merchantTableNames() {
      return this.$store.getters["merchantModule/getMerchantTableNames"];
    },

    isAllFieldsValid() {
      //const regexMobileNo = new RegExp(this.userMobileNo.pattern);

      if (this.selectedTable !== "") {
        // if (this.customerType === "member") {
        //   if (regexMobileNo.test(this.userMobileNo.value)) {
        //     return true;
        //   }
        //   return false;
        // } else if (this.customerType === "guest") {
        //   return true;
        // }
        return true;
      } else {
        return false;
      }
      //   if (regexMobileNo.test(this.userMobileNo.value)) {
      //     return true;
      //   }
      //   return false;
    },
  },
  created() {},
  methods: {
    goToMyProducts() {
      this.$router.push("/merchant/products");
    },
    goToDashboard() {
      this.$router.push("/merchant/dashboard");
    },

    async getItems(category) {
      // Do something with the value
      this.selectedCategory = category;
      await this.$store.dispatch(
        "productsModule/initItemsRealtimeListeners",
        category.id
      );

      this.isItemsLoading = true;

      setTimeout(async () => {
        await this.$store.dispatch(
          "productsModule/retrieveItemsByCategory",
          this.selectedCategory.id
        );
        this.isItemsLoading = false;
      }, 300);
    },

    addToMerchantCart(item) {
      this.selectedItem = item;
      this.isAddingToMerchantCart = true;

      this.isEditingMerchantCartItem = false;
    },

    closeAddToMerchantCartModal() {
      this.selectedItem = {};
      this.isAddingToMerchantCart = false;
    },

    editMerchantCartItem(cartItem) {
      this.selectedCartItem = cartItem;
      this.isEditingMerchantCartItem = true;

      this.isAddingToMerchantCart = false;
    },
    closeEditMerchantCartItemModal() {
      this.selectedCartItem = {};
      this.isEditingMerchantCartItem = false;
    },

    updatePaymentDetails(data) {
      // TODO: charge and tax percentage by merchants
      this.subtotal = data.subtotal;
      this.serviceCharge = data.serviceCharge;
      this.serviceTax = data.serviceTax;
      this.totalAmount = data.totalAmount;
    },

    confirmClearAllItems() {
      this.$buefy.dialog.confirm({
        title: "Empty the cart",
        message: "Are you sure you want to remove all items from the cart?",
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () =>
          this.clearAllItems() &&
          this.$buefy.toast.open("Removing items from cart ..."),
      });
    },
    clearAllItems() {
      this.$store.dispatch("merchantCartModule/clearMerchantCartItems");
    },

    async placeOrder() {
      this.isPlacingOrder = true;

      const result = await this.$store.dispatch(
        "merchantCartModule/placeOrder",
        {
          items: [...this.merchantCartItems],
          subtotal: this.subtotal,
          serviceCharge: this.serviceCharge,
          serviceTax: this.serviceTax,
          totalAmount: this.totalAmount,

          tableName: this.selectedTable,
          // passed merchantId in store action

          paymentMethod: this.paymentMethod,
          paymentStatus: "pending",
          status: "active",
        }
      );

      if (result.success) {
        // TODO
        // Show order placed success view
        await this.$store.dispatch(
          "merchantCartModule/clearMerchantCartItemsOnOrdersPlaced",
          this.$route.params.merchantId
        );

        //TODO: show status and navigate to table details page
        // this.$router.push(
        //   `/menu/${this.$route.params.merchantId}/order-confirmed?tableName=${this.$route.query.tableName}`
        // );
      } else {
        // Display error message
      }

      this.isPlacingOrder = false;
      // TODO: if order placed successfully, clear cart data and redirect to status view
    },
  },
};
</script>

<style>
.create-order-container {
  overflow-y: auto;
}

.merchant-cart-container {
  height: calc(100vh - 335px);
  overflow-y: auto;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-right: 0.5rem;
}

.menu-item-list-container {
  overflow-y: auto !important;
  max-height: calc(100vh - 205px);
  scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
  .responsiveCartUI {
    position: absolute;
    top: 70px;
    width: 100%;
    background: #fff;
    height: calc(100vh - 70px);
    z-index: 2;
    padding: 1rem !important;
  }
}
@media screen and (min-width: 769px) {
  .responsiveMenu {
    padding-right: 1.25rem !important;
  }
}
</style>
