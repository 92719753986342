import { render, staticRenderFns } from "./ToServeList.vue?vue&type=template&id=4b6a6bfb&"
import script from "./ToServeList.vue?vue&type=script&lang=js&"
export * from "./ToServeList.vue?vue&type=script&lang=js&"
import style0 from "./ToServeList.vue?vue&type=style&index=0&id=4b6a6bfb&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports