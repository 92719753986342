<template>
  <div class="p-3 has-background-light">
    <div class="has-background-white rounded p-3">
      <p class="is-size-7 mb-2">Enter Code</p>

      <div class="is-flex">
        <div class="mr-3 is-flex-grow-1">
          <b-input
            v-model="code"
            :icon-right="code !== '' ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearCodeInputField"
            @input="toUpperCase"
          ></b-input>
        </div>
        <b-button
          type="is-light"
          label="Search"
          :disabled="this.code === ''"
          @click="onSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CodeInput",
  components: {},
  props: {
    getItemByCode: { type: Function },
  },
  data() {
    return {
      code: "",
    };
  },
  created() {},
  methods: {
    toUpperCase() {
      this.code = this.code.toUpperCase();
    },
    onSearch() {
      this.getItemByCode(this.code);
    },
    clearCodeInputField() {
      this.code = "";
      // TODO: clear result?
    },
  },
};
</script>
