import Vue from "vue";
// import { initializeApp, getCurrentUser, firestore } from "firebase/app";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { store } from "./store/index";

import Buefy from "buefy";
import "buefy/dist/buefy.css";

import "@/styles/index.scss";
import "@/firebase";

Vue.config.productionTip = false;

Vue.use(Buefy);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
