<template>
  <b-modal
    class="modal-mobile-first"
    v-model="isAddToCartModalActive"
    scroll="keep"
    :on-cancel="closeAddToCartModal"
    :can-cancel="['escape', 'outside']"
    style="max-width: 500px !important"
  >
    <div class="has-background-white">
      <!-- <div class="pt-3 pb-2 has-background-white rounded-to bar-design">
        <div
          class="has-background-grey-lighter mx-auto rounded"
          style="width: 50px; height: 6px"
        ></div>
      </div> -->

      <div class="content p-5">
        <img v-if="item.imageURL !== ''" class="rounded" :src="item.imageURL" />
        <div class="is-flex mt-3 mb-5">
          <div class="is-flex-grow-1 has-text-weight-semibold">
            {{ item.name }}
          </div>
          <div class="has-text-right pl-3" style="width: 120px">
            <p v-if="!item.hasBasePriceDiscount" class="is-size-6-mobile">
              RM {{ item.basePrice }}
            </p>
            <div v-else class="is-block">
              <div class="is-size-6-mobile ml-2 has-text-weight-semibold">
                RM
                {{
                  (
                    item.basePrice *
                    (1 - item.basePriceDiscountPercentage / 100)
                  ).toFixed(2)
                }}
              </div>
              <span
                class="is-size-7-mobile has-text-grey mb-0"
                style="text-decoration: line-through"
              >
                RM {{ item.basePrice }}
              </span>
              <b-tag
                class="ml-2"
                type="is-success is-light"
                style="font-size: 0.6rem"
                >{{ `-${item.basePriceDiscountPercentage}%` }}</b-tag
              >
            </div>
          </div>
        </div>

        <div v-if="item.hasVariations" class="has-fade-in-anim mb-4">
          <div
            v-for="(variation, variationIndex) in item.variations"
            :key="variationIndex"
            class="rounded has-border-grey p-4 mt-3"
          >
            <p class="mb-2">
              <span class="has-text-weight-semibold">{{
                variation.title
              }}</span>

              <span
                v-if="!selectedVariations[variationIndex]"
                class="ml-2 has-text-grey is-size-7"
                >Choose 1</span
              >
              <span v-else class="ml-2 has-text-grey is-size-7">
                <b-icon
                  icon="check-circle"
                  size="is-small"
                  type="is-success"
                  class="mr-1"
                >
                </b-icon>
              </span>
            </p>

            <div class="is-block">
              <span v-for="option in variation.options" :key="option.id">
                <div class="is-flex mt-4">
                  <b-radio
                    v-model="selectedVariations[variationIndex]"
                    type="is-info"
                    :name="option.name"
                    :native-value="option"
                    required
                    :disabled="!option.isAvailable"
                    class="is-flex-grow-1"
                  >
                    {{ option.name }}
                  </b-radio>

                  <div v-if="option.isAvailable" class="has-text-grey">
                    {{
                      option.priceIncrease === "0.00"
                        ? ""
                        : `+ ${option.priceIncrease}`
                    }}
                  </div>
                  <div v-else class="has-text-grey is-size-7">
                    <b-tag>Out of stock</b-tag>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <!-- Collection -->
        <div v-if="item.hasCollections" class="has-fade-in-anim mb-4">
          <div
            v-for="(collection, collectionIndex) in item.collections"
            :key="collectionIndex"
            class="rounded has-border-grey p-4 mt-3"
          >
            <div class="mb-2 is-flex">
              <span class="has-text-weight-semibold">{{
                collection.title
              }}</span>

              <div class="mt-1 ml-2 has-text-grey is-size-7">
                <span
                  v-if="
                    selectedCollections[collectionIndex].selections?.length ===
                    0
                  "
                  >{{ `Select ${collection.requiredSelectionQuantity}` }}</span
                >
                <div v-else>
                  <span
                    v-if="
                      collection.requiredSelectionQuantity -
                        selectedCollections[collectionIndex].selections
                          ?.length !==
                      0
                    "
                  >
                    {{
                      `Select ${
                        collection.requiredSelectionQuantity -
                        selectedCollections[collectionIndex].selections?.length
                      } more`
                    }}</span
                  >
                  <span v-else>
                    <b-icon
                      icon="check-circle"
                      size="is-small"
                      type="is-success"
                    >
                    </b-icon>
                    {{
                      `${selectedCollections[collectionIndex].selections?.length} selected`
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div class="is-block">
              <span v-for="option in collection.options" :key="option.id">
                <div class="is-flex mt-4">
                  <b-checkbox
                    v-model="selectedCollections[collectionIndex].selections"
                    class="is-flex-grow-1"
                    type="is-info"
                    :name="option.name"
                    :native-value="option"
                    :disabled="
                      !option.isAvailable ||
                      (!checkIfSelectedById(
                        selectedCollections[collectionIndex].selections,
                        option.id
                      ) &&
                        selectedCollections[collectionIndex].selections
                          ?.length === collection.requiredSelectionQuantity)
                    "
                  >
                    {{ option.name }}
                  </b-checkbox>

                  <div v-if="option.isAvailable" class="has-text-grey">
                    {{
                      option.priceIncrease === "0.00"
                        ? ""
                        : `+ ${option.priceIncrease}`
                    }}
                  </div>
                  <div v-else class="has-text-grey is-size-7">
                    <b-tag>Out of stock</b-tag>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <!-- TODO Add-on -->

        <div class="mb-4 rounded has-border-grey p-4 mt-3 is-block">
          <p class="mb-2">
            <span class="has-text-weight-semibold">Service</span>
          </p>

          <div class="is-block">
            <span>
              <div class="is-flex mt-4">
                <b-radio
                  class="mb-3 is-flex-grow-1"
                  v-model="serviceMode"
                  name="service"
                  native-value="Dine-in"
                >
                  Dine-in
                </b-radio>
              </div>
            </span>
            <span>
              <div class="is-flex mt-2">
                <b-radio
                  class="is-flex-grow-1"
                  v-model="serviceMode"
                  name="service"
                  native-value="Takeaway"
                >
                  Takeaway
                </b-radio>

                <div v-if="takeawayPriceIncrease" class="has-text-grey">
                  {{
                    takeawayPriceIncrease === "0.00"
                      ? ""
                      : `+ ${takeawayPriceIncrease}`
                  }}
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="is-flex mt-5">
          <div class="is-flex-grow-1 has-text-weight-semibold py-2">
            Quantity
          </div>

          <b-field>
            <b-numberinput
              v-model="quantity"
              :disabled="this.item.rewardId"
              type="is-light"
              :editable="false"
              :min="1"
              :max="50"
            ></b-numberinput>
          </b-field>
        </div>
        <hr class="my-4" />
        <div class="mt-3">
          <b-collapse
            animation="slide"
            aria-id="contentIdForA11y3"
            :open="false"
          >
            <template #trigger="props">
              <div
                class="is-flex"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="has-text-weight-semibold is-flex-grow-1 mb-0">
                  Special Remark
                </p>
                <a class="">
                  <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
                  </b-icon>
                </a>
              </div>
            </template>

            <b-field class="mt-1">
              <b-input v-model="specialRemark" maxlength="80"></b-input>
            </b-field>
          </b-collapse>
        </div>
      </div>

      <footer
        class="modal-card-foot is-justify-content-left customized-modal-actions"
      >
        <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
        <b-button
          type="is-primary action-btn"
          @click="addToCart"
          :disabled="!isAllRequiredFieldCompleted"
          >Add to Cart</b-button
        >
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AddToCart",
  components: {},
  props: {
    item: { type: Object, required: true },
    isAddToCartModalActiveProp: Boolean,
    closeAddToCartModal: { type: Function },
  },
  data() {
    return {
      selectedVariations: [],
      selectedCollections: [],

      serviceMode: "Dine-in",
      specialRemark: "",
      quantity: 1,
    };
  },
  created() {
    const temp = [];
    for (let i = 0; i < this.item.collections?.length; i++) {
      temp[i] = { selections: [] };
    }
    this.selectedCollections = temp;
  },
  computed: {
    isAddToCartModalActive: {
      get() {
        return this.isAddToCartModalActiveProp;
      },
      set() {},
    },
    isVariationsCompleted() {
      if (!this.item.hasVariations) {
        return true;
      } else {
        for (let i = 0; i < this.item.variations?.length; i++) {
          if (this.selectedVariations[i] === undefined) {
            return false;
          }
        }
        return true;
      }
    },
    isCollectionsCompleted() {
      if (!this.item.hasCollections) {
        return true;
      } else {
        for (let i = 0; i < this.item.collections?.length; i++) {
          if (
            this.selectedCollections[i].selections?.length !==
            this.item.collections[i].requiredSelectionQuantity
          ) {
            return false;
          }
        }
        return true;
      }
    },
    isAllRequiredFieldCompleted() {
      // TODO: + add-on length if feature added

      if (this.isVariationsCompleted && this.isCollectionsCompleted) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.closeAddToCartModal();
    },

    sumPriceIncrease() {
      let sumPriceIncrease = 0;

      let sumSelectedVariationsPriceIncrease = this.selectedVariations.reduce(
        (accumulator, item) => {
          return accumulator + Number(item.priceIncrease);
        },
        0
      );

      sumPriceIncrease = sumPriceIncrease + sumSelectedVariationsPriceIncrease;

      for (let j = 0; j < this.selectedCollections.length; j++) {
        let sumSelectedCollectionsPriceIncrease = this.selectedCollections[
          j
        ].selections.reduce((accumulator, item) => {
          return accumulator + Number(item.priceIncrease);
        }, 0);

        sumPriceIncrease =
          sumPriceIncrease + sumSelectedCollectionsPriceIncrease;
      }

      return sumPriceIncrease;
    },

    async addToCart() {
      let itemToAdd = {
        productId: this.item.id,
        name: this.item.name,
        basePrice: this.item.basePrice,
        hasBasePriceDiscount: this.item.hasBasePriceDiscount,
        basePriceDiscountPercentage: this.item.basePriceDiscountPercentage,

        hasVariations: this.item.hasVariations,
        hasCollections: this.item.hasCollections,
        variations: this.item.variations,
        collections: this.item.collections,

        selectedVariations: this.selectedVariations,
        selectedCollections: this.selectedCollections,

        serviceMode: this.serviceMode,

        quantity: this.quantity,
        specialRemark: this.specialRemark,

        sumPriceIncrease: await this.sumPriceIncrease(),
        totalPrice: this.item.hasBasePriceDiscount
          ? Number(
              this.item.basePrice *
                (1 - this.item.basePriceDiscountPercentage / 100)
            ) + Number(await this.sumPriceIncrease())
          : Number(this.item.basePrice) + Number(await this.sumPriceIncrease()),
        merchantId: this.$route.params.merchantId,

        rewardId: this.item.rewardId ? this.item.rewardId : "",
        isRewardItem: this.item.rewardId ? true : false,
      };

      const result = await this.$store.dispatch(
        "cartModule/addItemToCart",
        itemToAdd
      );

      if (result.success) {
        if (this.item.rewardId) {
          // TODO
          // await this.$store.dispatch(
          //   "rewardsModule/setRewardAsUsedOnAddToCart",
          //   this.item.rewardId
          // );
        }
      }

      this.onCancel();
    },

    checkIfSelectedById(arr, id) {
      const targetIndex = arr.findIndex((obj) => obj.id === id);

      if (targetIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.bar-design {
  position: absolute;
  width: 100%;
  max-width: 500px !important;
  border-radius: 1rem 1rem 0 0;
  z-index: 1;
}

.modal {
  margin-top: 0px !important;
}

.animation-content.modal-content {
  border-radius: 1rem 1rem 0 0;
}
</style>
