<template>
  <div>
    <b-field>
      <div class="is-flex is-block-mobile">
        <b-input
          v-model="variationTitle"
          placeholder="Name a variation"
          class="mb-4 mr-3 is-flex-grow-1"
        ></b-input>

        <b-button
          icon-left="plus"
          class="is-secondary-btn"
          :disabled="variationTitle === ''"
          @click="createVariation"
        >
          Create
        </b-button>
      </div>
    </b-field>
    <div
      v-if="variations.length === 0"
      class="is-size-6 has-text-grey has-text-centered rounded has-background-light mt-4 p-4"
    >
      No variation yet
    </div>
    <div
      v-else
      v-for="(variationItem, variationIndex) in variations"
      :key="variationIndex"
    >
      <div class="rounded pt-3 pb-4 px-4 has-border-grey mt-4">
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <span class="is-size-7 has-text-weight-light"
              >Variation {{ variationIndex + 1 }}</span
            >
            <div class="has-text-weight-semibold mb-2">
              {{ variationItem.title }}
            </div>
          </div>
          <b-button
            type="is-danger is-light"
            icon-right="delete"
            class="is-flex-grow-0 mt-1"
            @click="confirmRemoveVariation(variationItem.title, variationIndex)"
          />
        </div>

        <div
          v-if="variationItem.options.length === 0"
          class="is-size-7 has-text-grey mb-3"
        >
          No option yet
        </div>

        <b-field v-else grouped group-multiline>
          <div
            v-for="option in variationItem.options"
            :key="option.id"
            class="control"
          >
            <b-tag
              type="is-light"
              attached
              close-type="is-light"
              closable
              aria-close-label="Close tag"
              @close="removeOptionFromVariation(variationIndex, option.id)"
            >
              {{ option.name + " +RM " + option.priceIncrease }}
            </b-tag>
          </div>
        </b-field>

        <b-collapse
          :open="false"
          aria-id="contentIdForA11y1"
          position="is-bottom"
          animation="slide"
        >
          <template #trigger="props">
            <b-button
              v-if="!props.open"
              icon-left="plus"
              class="is-secondary-btn mt-3"
              size="is-small"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              >Add Option</b-button
            >
            <div v-else>
              <b-button :aria-expanded="props.open" type="is-small" class="mr-3"
                >Cancel</b-button
              >

              <b-button
                :aria-expanded="props.open"
                type="is-primary"
                size="is-small"
                :disabled="
                  optionNameInput[variationIndex] === '' ||
                  optionPriceIncreaseInput[variationIndex] === undefined
                "
                @click="
                  addOptionToVariation(
                    variationIndex,
                    optionNameInput[variationIndex],
                    optionPriceIncreaseInput[variationIndex]
                  )
                "
                >Add</b-button
              >
            </div>
          </template>
          <div class="has-fade-in-anim">
            <hr />

            <b-input
              class="mb-4"
              v-model="optionNameInput[variationIndex]"
              placeholder="Name an Option"
              size="is-small"
            ></b-input>
            <b-numberinput
              v-model="optionPriceIncreaseInput[variationIndex]"
              type="is-light"
              controls-rounded
              class="mb-4"
              min="0"
              placeholder="Price Increase"
              size="is-small"
            ></b-numberinput>
          </div>
        </b-collapse>
        <div
          v-if="variationItem.options.length < 2"
          class="is-size-7 has-text-danger mb-0"
        >
          <hr class="mt-4 mb-3" />
          Please add at least 2 options
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemVariations",
  components: {},
  props: {
    variationsProp: Array,
  },
  computed: {},
  data() {
    return {
      variations: this.variationsProp,

      variationTitle: "",

      optionNameInput: [],
      optionPriceIncreaseInput: [],
    };
  },

  methods: {
    createVariation() {
      const todayDate = new Date();
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let randomCode = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        randomCode += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      const variationId =
        "VR" +
        todayDate.getFullYear().toString().slice(-2) +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        todayDate.getDate().toString().padStart(2, "0") +
        randomCode;

      this.variations.push({
        id: variationId,
        title: this.variationTitle,
        options: [],
      });
      this.variationTitle = "";
    },
    removeVariation(variationIndex) {
      this.variations.splice(variationIndex, 1);
      this.optionNameInput[variationIndex] = "";
      this.optionPriceIncreaseInput[variationIndex] = null;
    },
    confirmRemoveVariation(title, variationIndex) {
      this.$buefy.dialog.confirm({
        title: "Remove Variation",
        message: `Are you sure you want to remove variation '${title}'?`,
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () => this.removeVariation(variationIndex),
      });
    },
    addOptionToVariation(variationIndex, optionName, optionPriceIncrease) {
      const todayDate = new Date();
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let randomCode = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        randomCode += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      const variationsOptionId =
        "VRO" +
        todayDate.getFullYear().toString().slice(-2) +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        todayDate.getDate().toString().padStart(2, "0") +
        randomCode;

      this.variations[variationIndex].options.push({
        id: variationsOptionId,
        name: optionName,
        priceIncrease: Number(optionPriceIncrease).toFixed(2),
        isAvailable: true,
      });
      this.optionNameInput[variationIndex] = "";
      this.optionPriceIncreaseInput[variationIndex] = null;
    },
    removeOptionFromVariation(variationIndex, optionId) {
      const targetIndex = this.variations[variationIndex].options.findIndex(
        (obj) => obj.id === optionId
      );

      if (targetIndex > -1) {
        this.variations[variationIndex].options.splice(targetIndex, 1);
      }
    },
  },
};
</script>
