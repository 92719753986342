<template>
  <div class="content p-5 customized-modal-content">
    <div class="has-text-weight-semibold has-text-dark">
      Membership & Loyalty Program
    </div>
    <p class="has-text-grey is-size-7 mb-4">
      Decide whether this product can be redeemed with points and its point
      value. Learn more in <a @click="goToSettings">Settings</a>.
    </p>
    <!-- <div v-if="isLoyaltyProgramEnabled">
      <b-switch
        :value="isPointRedeemable"
        @input="$emit('update:isPointRedeemable', $event)"
        class="mb-5 has-text-grey"
        type="is-success"
        >{{
          isPointRedeemable
            ? "Redeemable with points"
            : "Not redeemable with points"
        }}</b-switch
      >
      <div v-if="isPointRedeemable">
        <p class="mb-0">Point Value</p>
        <p class="is-size-7 has-text-grey mb-2">
          Reminder: Customers earn 1 point on every $1 spent
        </p>
        <b-field>
          <b-numberinput
            :value="pointValue"
            @input="$emit('update:pointValue', $event)"
            :min="1"
            type="is-light"
          ></b-numberinput>
        </b-field>
      </div>
    </div>
    <b-notification v-else size="is-small" :closable="false" class="mb-4">
      <b-icon icon="alert-circle-outline" size="is-small" class="mr-2">
      </b-icon>
      <span class="is-size-7"
        >To use this feature, you need to enable this program in
        <a @click="goToSettings">Settings</a> .</span
      >
    </b-notification> -->
    <b-message
      type="is-light is-warning"
      size="is-small"
      aria-close-label="Close notification"
      :closable="false"
      class="is-size-7 rounded-2"
    >
      This feature is coming soon ...
    </b-message>
  </div>
</template>

<script>
export default {
  name: "ItemPointRedemption",
  components: {},
  props: {
    isPointRedeemable: Boolean,
    pointValue: Number,
  },

  data() {
    return {};
  },

  computed: {
    isLoyaltyProgramEnabled() {
      return this.$store.getters["merchantModule/getMerchantInfo"]
        .isLoyaltyProgramEnabled;
    },
  },
  methods: {
    goToSettings() {
      this.$router.push("/merchant/settings");
    },
  },
};
</script>
