<template>
  <section>
    <div
      v-if="ToServeLogs?.length === 0 || ToServeLogs === undefined"
      class="has-text-centered p-5 rounded has-fade-in-anim has-border-grey"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/no_data.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <div class="has-text-grey mx-auto">No order item to serve yet</div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(item, itemIndex) in ToServeLogs"
        :key="item.id"
        class="has-border-grey rounded mb-4"
        :class="item.isServed ? 'served-dish' : ''"
      >
        <div
          class="p-3 has-background-light is-flex"
          style="border-radius: 0.75rem 0.75rem 0 0"
        >
          <div class="is-flex-grow-1 is-flex space-between">
            <!-- <b-checkbox></b-checkbox> -->
            <p
              class="has-text-weight-medium has-text-grey my-auto pr-4"
              type="is-white"
            >
              # {{ itemIndex + 1 }}
            </p>
            <p
              class="is-size-6 my-auto px-3"
              style="border-left: 1px solid #c9c9c9"
            >
              <span class="has-text-grey mr-1">Table</span>
              <span class="has-text-weight-semibold has-letter-spacing-1">
                {{ `${item.tableName}` }}</span
              >
            </p>

            <!-- <span class="is-size-6 ml-2">
                  {{ `Table ${order.tableNo}` }}</span
                > -->
          </div>

          <p class="is-size-6 my-auto pl-3 has-text-grey">
            {{ formatTimestamp(item.createdAt) }}
          </p>
        </div>

        <div class="pt-2 px-3 pb-3">
          <div class="columns is-mobile order-item-row">
            <div class="column is-narrow p-0">
              <b-checkbox
                v-model="item.isServed"
                type="is-success"
                :class="item.isServed ? '' : 'to-serve-style'"
                @input="itemOnCheck(ToServeLogs)"
              ></b-checkbox>
            </div>

            <div class="column is-narrow pt-1 px-0 pb-0">
              <div
                class="has-text-weight-semibold has-text-centered"
                style="width: 40px !important"
              >
                {{ `${item.quantity}` }}
              </div>
            </div>
            <div class="column is-narrow pt-1 pb-0 pl-0">
              <span class="has-text-weight-semibold has-text-grey-light"
                ><b-icon icon="close" size="is-small"> </b-icon
              ></span>
            </div>

            <div class="column pt-1 px-0 pb-0">
              <div class="columns">
                <div
                  class="column is-two-fifths py-0 pl-0 mb-2 has-text-weight-semibold"
                >
                  {{ item.name }}
                </div>
                <div class="column is-three-fifths p-0">
                  <b-tag
                    v-if="item.serviceMode === 'Takeaway'"
                    icon="shopping-outline"
                    size="is-small"
                    class="mb-1"
                    type="is-warning is-light"
                    style="border: 1px solid #bc9c43"
                    >Takeaway</b-tag
                  >
                  <p>
                    <span
                      v-for="(
                        selectedVariation, selectedVariationIndex
                      ) in item.selectedVariations"
                      :key="selectedVariationIndex"
                    >
                      {{
                        selectedVariation.name +
                        (selectedVariationIndex !==
                        item.selectedVariations?.length - 1
                          ? ", "
                          : "")
                      }}
                    </span>
                  </p>
                  <p>
                    <span
                      v-for="(
                        selectedCollection, selectedCollectionIndex
                      ) in item.selectedCollections"
                      :key="selectedCollectionIndex"
                    >
                      <span
                        v-for="(
                          selection, selectionIndex
                        ) in selectedCollection.selections"
                        :key="selectionIndex"
                        >{{
                          selection.name +
                          (selectionIndex !==
                          selectedCollection.selections?.length - 1
                            ? ", "
                            : "")
                        }} </span
                      ><br />
                    </span>
                  </p>
                  <p
                    v-if="item.specialRemark !== ''"
                    class="has-text-danger mb-0 is-italic"
                  >
                    {{ `" ${item.specialRemark} "` }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ToServeList",
  props: {
    ToServeLogs: Array,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    formatTimestamp(timestamp) {
      var date;
      if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        date = new Date(timestamp);
      }

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string

      const formattedDate = `${hours}:${minutes} ${amPm}`;

      return formattedDate;
    },

    async itemOnCheck(ToServeLogs) {
      await this.$store.dispatch(
        "toServeLogsModule/updateSingleToServeLog",
        ToServeLogs
      );
    },
  },
};
</script>

<style>
.served-dish {
  filter: opacity(0.5);
  /* text-decoration: line-through; */
}

.to-serve-style span {
  border-color: #f14668 !important;
}
</style>
