import { db } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";
import { getDocs, collection } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";
import { query, where, onSnapshot } from "firebase/firestore";

import { ToastProgrammatic as Toast } from "buefy";

const state = {
  categories: [],
  currentItems: [],
};

const getters = {
  getCategories: (state) => state.categories,
  getItemsByCategory: (state) => state.currentItems,
};

const mutations = {
  createCategory(state, category) {
    state.categories.push(category);
  },
  updateCategory(state, category) {
    var targetIndex = state.categories.findIndex((x) => x.id == category.id);
    if (targetIndex >= 0) {
      state.categories.splice(targetIndex, 1, category);
    }
  },
  removeCategory(state, categoryId) {
    var targetIndex = state.categories.findIndex((x) => x.id == categoryId);

    if (targetIndex >= 0) {
      state.categories.splice(targetIndex, 1);
    }
  },
  retrieveCategories(state, categories) {
    state.categories = categories;
  },
  addItem(state, item) {
    state.currentItems.push(item);
  },
  updateItem(state, item) {
    var targetIndex = state.currentItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      Object.assign(state.currentItems[targetIndex], item);
    }
  },
  removeItem(state, itemId) {
    // TODO: Debug
    var targetIndex = state.currentItems.findIndex((x) => x.id == itemId);
    if (targetIndex >= 0) {
      state.currentItems.splice(targetIndex, 1);
    }
  },

  retrieveItemsByCategory(state, items) {
    state.currentItems = items;
  },
  updateItemStock(state, item) {
    var targetIndex = state.currentItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      Object.assign(state.currentItems[targetIndex], item);
    }
  },
};

const actions = {
  async initCategoriesRealtimeListeners({ commit, rootState }) {
    const querySnapshot = await query(
      collection(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Categories"
      )
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("createCategory", {
              id: change.doc.id,
              name: change.doc.data().name,
              isDisplayOnMenu: change.doc.data().isDisplayOnMenu,
              positionNo: change.doc.data().positionNo,
              createdAt: change.doc.data().timestamp,
            });
          }
        }
        if (change.type === "modified") {
          commit("updateCategory", {
            id: change.doc.id,
            name: change.doc.data().name,
          });
        }
        if (change.type === "removed") {
          commit("removeCategory", change.doc.id);
        }
      });
    });
  },

  async initItemsRealtimeListeners({ commit, rootState }, categoryId) {
    const querySnapshot = await query(
      collection(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Categories",
        categoryId,
        "Items"
      )
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("addItem", change.doc.data());
          }
        }
        if (change.type === "modified") {
          commit("updateItem", change.doc.data());
          commit("updateItemStock", change.doc.data());
        }
        if (change.type === "removed") {
          commit("removeItem", change.doc.id);
        }
      });
    });
  },

  //

  async retrieveCategories({ commit, rootState }) {
    const querySnapshot = await getDocs(
      collection(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Categories"
      )
    );

    let tempCategories = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      tempCategories.push(data);
    });

    // TODO: sort by position no
    commit("retrieveCategories", tempCategories);
  },

  async createCategory({ commit, rootState }, category) {
    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const categoryId =
      "C" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    try {
      const docRef = doc(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Categories",
        categoryId
      );

      await setDoc(
        docRef,
        {
          id: categoryId,
          name: category.name,
          isDisplayOnMenu: category.isDisplayOnMenu,
          positionNo: category.positionNo,
          createdAt: new Date(),
        },
        { merge: true }
      );

      Toast.open("Category has been created successfully ✅");

      commit("createCategory", {
        id: categoryId,
        name: category.name,
        isDisplayOnMenu: category.isDisplayOnMenu,
        positionNo: category.positionNo,
        createdAt: new Date(),
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  async updateCategory({ commit, rootState }, category) {
    try {
      const docRef = doc(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Categories",
        category.id
      );

      await updateDoc(docRef, {
        name: category.name,
        isDisplayOnMenu: category.isDisplayOnMenu,
      });

      Toast.open("Category has been updated successfully ✅");

      commit("updateCategory", {
        id: category.id,
        name: category.name,
        isDisplayOnMenu: category.isDisplayOnMenu,
      });
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  async removeCategory({ commit, rootState }, categoryId) {
    try {
      const docRef = doc(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Categories",
        categoryId
      );
      await deleteDoc(docRef);
      Toast.open("Category has been removed successfully ✅");

      commit("removeCategory", categoryId);
    } catch (e) {
      console.error("Error removing document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  // TODO: update, because changed DS
  async retrieveItemsByCategory({ commit, rootState }, categoryId) {
    const querySnapshot = await getDocs(
      query(
        collection(
          db,
          "Merchants",
          rootState.merchantModule.merchantInfo.id,
          "Items"
        ),
        where("categoryId", "==", categoryId)
      )
    );
    let tempItems = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const data = doc.data();

      tempItems.push(data);
    });

    // TODO: sort by position no
    commit("retrieveItemsByCategory", tempItems);
  },

  async addItem({ commit, rootState }, item) {
    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const itemId =
      "P" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    try {
      const docRef = doc(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Items",
        itemId
      );
      const date = new Date();
      await setDoc(docRef, {
        id: itemId,
        ...item,
        merchantId: rootState.merchantModule.merchantInfo.id,
        createdAt: date,
        updatedAt: date,
      });

      Toast.open("Item has been added successfully ✅");

      commit("addItem", {
        id: itemId,
        ...item,
        merchantId: rootState.merchantModule.merchantInfo.id,
        createdAt: date,
        updatedAt: date,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  async updateItem({ commit, rootState }, item) {
    const docRef = doc(
      db,
      "Merchants",
      rootState.merchantModule.merchantInfo.id,
      "Items",
      item.id
    );

    const date = new Date();
    try {
      await updateDoc(docRef, {
        ...item,
        merchantId: rootState.merchantModule.merchantInfo.id,
        updatedAt: date,
      });

      Toast.open("Item has been updated successfully ✅");

      commit("updateItem", {
        ...item,
        merchantId: rootState.merchantModule.merchantInfo.id,
        updatedAt: date,
      });
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  async removeItem({ commit, rootState }, item) {
    try {
      const docRef = doc(
        db,
        "Merchants",
        rootState.merchantModule.merchantInfo.id,
        "Items",
        item.id
      );

      await deleteDoc(docRef);

      Toast.open("Item has been removed successfully ✅");

      commit("removeItem", item.id);
    } catch (e) {
      console.error("Error removing document: ", e);
      Toast.open("Something went wrong, please try again ⚠️");
    }
  },

  async updateItemStock({ commit, rootState }, item) {
    const docRef = doc(
      db,
      "Merchants",
      rootState.merchantModule.merchantInfo.id,
      "Items",
      item.id
    );

    const date = new Date();

    await updateDoc(docRef, {
      isAvailable: item.isAvailable,
      variations: item.variations,
      collections: item.collections,
      updatedAt: date,
    })
      .then(() => {
        Toast.open("Item's stock has been updated successfully ✅");
      })
      .catch((e) => {
        console.error("Error updating document: ", e);
        Toast.open("Something went wrong, please try again ⚠️");
      });

    commit("updateItemStock", {
      id: item.id,
      isAvailable: item.isAvailable,
      variations: item.variations,
      collections: item.collections,

      updatedAt: date,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
