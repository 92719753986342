import { db } from "@/firebase";
import { collection } from "firebase/firestore";
import { query, where, onSnapshot } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

const state = {
  tableCompletedOrders: [],
};

const getters = {
  getTableCompletedOrders(state) {
    return state.tableCompletedOrders;
  },
};

const mutations = {
  addOrder(state, item) {
    state.tableCompletedOrders.push(item);
  },
  clearTableCompletedOrders(state) {
    state.tableCompletedOrders = [];
  },
};

const actions = {
  async initTableCompletedOrdersRealtimeListeners({ commit }, data) {
    // Get the current timestamp
    var currentTime = new Date().getTime();

    // Calculate the timestamp for 10 minutes ago
    var tenMinutesAgo = currentTime - 10 * 60 * 1000; // 10 minutes * 60 seconds * 1000 milliseconds

    // Create a new Date object with the timestamp for 10 minutes ago
    var dateTenMinutesAgo = new Date(tenMinutesAgo);

    const dateTenMinutesAgoTimestamp = Timestamp.fromDate(dateTenMinutesAgo);

    const querySnapshot = await query(
      collection(db, "Orders"),
      where("merchantId", "==", data.merchantId),
      where("tableName", "==", data.tableName),
      where("status", "==", "completed"),
      where("updatedAt", ">=", dateTenMinutesAgoTimestamp)
    );
    // const unsubscribe =
    onSnapshot(querySnapshot, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const source = change.doc.metadata.hasPendingWrites
            ? "Local"
            : "Server";

          if (source === "Server") {
            commit("addOrder", change.doc.data());
          }
        }
        if (change.type === "modified") {
          // commit("updateItem", change.doc.data());
          // commit("updateItemStock", change.doc.data());
        }
        if (change.type === "removed") {
          // commit("removeItem", change.doc.id);
        }
      });
    });
  },
  resetTableCompletedOrders({ commit }) {
    commit("clearTableCompletedOrders");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
