import { db } from "@/firebase";
//import { collection, addDoc } from "firebase/firestore";
import { doc, getDoc, setDoc, arrayUnion } from "firebase/firestore";

const state = {
  merchantInfo: {},
  userInfo: {},
  isNewUser: null,
};

const getters = {
  getMerchantInfo: (state) => state.merchantInfo,
  getMerchantTableNames: (state) => state.merchantInfo.tableNames,
  getIsNewUser: (state) => state.isNewUser,
};

const mutations = {
  retrieveMerchantInfo(state, merchant) {
    state.merchantInfo = merchant;
  },
  retrieveUserInfo(state, user) {
    state.userInfo = user;
  },
  setIsNewUserValue(state, value) {
    state.isNewUser = value;
  },
};

const actions = {
  async verifyMerchantInfo(context, data) {
    const docRef = doc(db, "Merchants", data.merchantId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      //commit("retrieveMerchantInfo", docSnap.data());
      if (docSnap.data().tableNames.includes(data.tableName)) {
        return { isMerchantExists: true, isMerchantTableExists: true };
      } else {
        return { isMerchantExists: true, isMerchantTableExists: false };
      }
    } else {
      // console.log("No such document!");
      return false;
    }
  },

  async retrieveMerchantInfo({ commit }, merchantId) {
    const docRef = doc(db, "Merchants", merchantId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      commit("retrieveMerchantInfo", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },

  async retrieveUserInfo({ commit }, mobileNo) {
    const docRef = doc(db, "Users", mobileNo);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      commit("retrieveUserInfo", docSnap.data());
      commit("setIsNewUserValue", false);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      commit("setIsNewUserValue", true);
    }
  },

  async addUserInfo(context, data) {
    const userId = data.mobileNo;

    try {
      //const docRef =
      await setDoc(doc(db, "Users", userId.toString()), {
        id: userId,
        contact: userId,
        createdAt: new Date(),
        lastLoginAt: new Date(),
        postcodes: arrayUnion(data.postcode),
      });
      //console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  async updateUserOnLogin({ state }, data) {
    try {
      var hasPostcode = state.userInfo?.postcodes?.includes(data.postcode);

      if (hasPostcode) {
        await setDoc(
          doc(db, "Users", data.mobileNo.toString()),
          {
            lastLoginAt: new Date(),
          },
          { merge: true }
        );
      } else {
        await setDoc(
          doc(db, "Users", data.mobileNo.toString()),
          {
            lastLoginAt: new Date(),
            postcodes: arrayUnion(data.postcode),
          },
          { merge: true }
        );
      }

      //console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
