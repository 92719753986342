import Vue from "vue";
import Vuex from "vuex";

import merchantModule from "./modules/Merchant/merchant";
import productsModule from "./modules/Merchant/products";
import merchantOrdersModule from "./modules/Merchant/orders";
import toServeLogsModule from "./modules/Merchant/toServeLogs";
import productImagesModule from "./modules/Merchant/productImages";
import logoImageModule from "./modules/Merchant/logoImage";
import merchantRewardsModule from "./modules/Merchant/rewards";
import merchantCartModule from "./modules/Merchant/merchantCart";
import merchantInvoiceModule from "./modules/Merchant/invoice";

import userModule from "./modules/User/user";
import menuModule from "./modules/User/menu";
import cartModule from "./modules/User/cart";
import userOrdersModule from "./modules/User/orders";
import userInvoicesModule from "./modules/User/invoices";
import userInvoiceModule from "./modules/User/invoice";

import rewardsModule from "./modules/User/rewards";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    merchantModule: merchantModule,
    productsModule: productsModule,
    merchantOrdersModule: merchantOrdersModule,
    toServeLogsModule: toServeLogsModule,
    productImagesModule: productImagesModule,
    logoImageModule: logoImageModule,
    merchantRewardsModule: merchantRewardsModule,
    merchantCartModule: merchantCartModule,
    merchantInvoiceModule: merchantInvoiceModule,

    userModule: userModule,
    menuModule: menuModule,
    cartModule: cartModule,
    userInvoicesModule: userInvoicesModule,
    userInvoiceModule: userInvoiceModule,
    userOrdersModule: userOrdersModule,
    rewardsModule: rewardsModule,
  },
  state: {},

  getters: {},

  mutations: {},

  actions: {},
});
