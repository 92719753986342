<template>
  <section class="has-background-light mx-auto" style="height: 100vh">
    <div class="mx-auto" style="width: 100%; max-width: 500px">
      <div class="has-background-light" style="max-width: 500px">
        <div
          class="p-2 is-flex has-background-white"
          style="
            position: fixed;
            width: 100%;
            max-width: 500px;
            z-index: 1;
            border-bottom: 1px solid #dcdcdc;
          "
        >
          <div>
            <b-button
              type="is-text"
              icon-right="arrow-left"
              @click="backToMenu"
            ></b-button>
          </div>
          <div class="is-flex-grow-1 p-2">
            <div class="has-text-weight-semibold has-text-centered mr-6">
              Active Orders
              <div class="is-size-7 has-text-weight-medium has-text-grey">
                on Table {{ this.$route.query.tableName }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="padding-top: 75px !important; padding-bottom: 107px !important"
        >
          <OrderList v-if="!isOrdersLoading" />
          <!-- skeleton ui below -->
          <div v-else class="p-3">
            <OrderSkeleton />
          </div>
        </div>

        <!-- <b-tabs
          v-model="activeTab"
          class="p-0"
          animated
          expanded
          @input="updateOrdersFilter"
          style="padding-top: 65px !important"
        >
          <b-tab-item label="Active" :value="'active'">
            <div class="order-list-container">
              <orderList v-if="activeTab === 'active'" />
            </div>
          </b-tab-item>
          <b-tab-item label="Completed" :value="'completed'">
            <div class="order-list-container">
              <orderList v-if="activeTab === 'completed'" />
            </div>
          </b-tab-item>
        </b-tabs> -->
      </div>
    </div>
  </section>
</template>

<script>
import OrderList from "@/components/User/MyOrders/OrderList.vue";
import OrderSkeleton from "@/components/User/MyOrders/OrderSkeleton.vue";

export default {
  name: "MyOrders",
  components: { OrderList, OrderSkeleton },
  data() {
    return {
      //activeTab: "active",
      isOrdersLoading: true,
    };
  },
  computed: {
    userOrders() {
      return this.$store.getters["userOrdersModule/getUserOrders"];
    },
  },
  async created() {
    // TODO: check again for mobile logged in user
    await this.$store.dispatch(
      "userOrdersModule/retrieveTableOrdersByMerchant",
      {
        merchantId: this.$route.params.merchantId,
        tableName: this.$route.query.tableName,
      }
    );

    setTimeout(async () => {
      this.isOrdersLoading = false;
    }, 800);
  },
  methods: {
    // async updateOrdersFilter() {
    //   await this.$store.dispatch(
    //     "userOrdersModule/updateOrdersFilter",
    //     this.activeTab
    //   );
    // },
    backToMenu() {
      this.$router.push(
        `/user/menu/${this.$route.params.merchantId}?tableName=${this.$route.query.tableName}`
      );
    },
  },
};
</script>

<style>
.order-list-container {
  overflow-y: auto !important;
  max-height: calc(100vh - 118px);
  scroll-behavior: smooth;
}

nav.tabs {
  max-width: 500px;
}
</style>
