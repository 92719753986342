<template>
  <section>
    <div class="has-background-white rounded p-3 mx-3 mb-3">
      <div class="is-size-6 has-text-weight-semibold mb-4">Payment Method</div>
      <div class="is-size-6 is-flex mb-3">
        <b-icon icon="cash" type="is-grey-light"> </b-icon>
        <div class="ml-2">Pay at counter</div>
      </div>

      <b-message
        type="is-light is-warning"
        size="is-small"
        aria-close-label="Close notification"
        :closable="false"
        class="is-size-7 rounded-2"
      >
        More payment methods coming soon ...
      </b-message>
    </div>
  </section>
</template>

<script>
export default {
  name: "PaymentMethod",
  props: {
    // updatePaymentMethod: { type: Function },
  },
};
</script>
