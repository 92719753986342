<template>
  <b-modal
    v-model="isCreateCategoryModalActive"
    scroll="keep"
    :on-cancel="closeCreateCategoryModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white">
      <!-- <div class="mb-3 has-text-weight-bold">Add New Item</div> -->
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-bold is-size-5 mb-5">Create Category</div>
        <b-field label="Category Name" size="is-small">
          <b-input v-model="categoryName" required></b-input>
        </b-field>
        <b-field label="Display on menu" class="pt-3">
          <b-switch type="is-success" v-model="isDisplayOnMenu">{{
            isDisplayOnMenu ? "Enabled" : "Disabled"
          }}</b-switch></b-field
        >
        <!-- <b-field label="Position No." size="is-small">
            <b-numberinput
              v-model="positionNo"
              :min="1"
              :max="this.categories.length + 1"
              type="is-light"
            ></b-numberinput
          ></b-field>
          <b-field label="Preview" grouped group-multiline>
            <div
              class="control"
              v-for="category in categories"
              :key="category.id"
            >
              <b-taglist attached>
                <b-tag>{{
                  category.positionNo === null ? "-" : category.positionNo
                }}</b-tag>
                <b-tag>{{ category.name }}</b-tag>
              </b-taglist>
            </div>
          </b-field> -->
      </div>
    </div>
    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
      <b-button
        type="is-primary action-btn"
        @click="createCategory"
        :disabled="!isAllRequiredFieldCompleted"
        >Confirm</b-button
      >
    </footer>
  </b-modal>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';

export default {
  name: "CreateCategory",
  components: {},
  props: {
    isCreateCategoryModalActiveProp: Boolean,
    closeCreateCategoryModal: { type: Function },
  },
  data() {
    return {
      categoryName: "",
      positionNo: null,
      isDisplayOnMenu: true,
    };
  },
  computed: {
    isCreateCategoryModalActive: {
      get() {
        return this.isCreateCategoryModalActiveProp;
      },
      set() {},
    },

    isAllRequiredFieldCompleted() {
      if (this.categoryName !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.closeCreateCategoryModal();
    },
    async createCategory() {
      await this.$store.dispatch("productsModule/createCategory", {
        name: this.categoryName,
        positionNo: this.positionNo,
        isDisplayOnMenu: this.isDisplayOnMenu,
      });
      this.onCancel();
    },
  },
};
</script>
