import { db } from "@/firebase";
//import { collection, addDoc } from "firebase/firestore";
import { arrayUnion, arrayRemove } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
// import { collection, query, where, getDocs } from "firebase/firestore";

const state = {
  toServeLogs: [],
};

const getters = {
  getToServeLogs: (state) => state.toServeLogs,
};

const mutations = {
  addToServeLog(state, log) {
    state.toServeLogs.push(log);
  },
  removeToServeLog(state, logId) {
    var targetIndex = state.toServeLogs.findIndex((x) => x.id == logId);
    if (targetIndex >= 0) {
      state.toServeLogs.splice(targetIndex, 1);
    }
  },
  updateToServeLogs(state, updatedLogs) {
    state.toServeLogs = updatedLogs;
  },

  clearCompletedToServeLogs(state, incompleteLogs) {
    state.toServeLogs = incompleteLogs;
  },
};

const actions = {
  async initToServeLogsRealtimeListeners({ commit, rootState }) {
    const toServeLogsRef = doc(
      db,
      "ToServeLogs",
      rootState.merchantModule.merchantInfo.id
    );

    // const unsub =
    onSnapshot(toServeLogsRef, (doc) => {
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";

      if (source === "Server") {
        commit("updateToServeLogs", doc.data().toServeLogs);
      }
    });
  },

  async addToServeLog({ commit, rootState }, data) {
    const toServeLogsRef = doc(
      db,
      "ToServeLogs",
      rootState.merchantModule.merchantInfo.id
    );

    let log = {
      ...data,
      isServed: false,
      createdAt: new Date(),
    };

    const docSnap = await getDoc(toServeLogsRef);

    if (docSnap.exists()) {
      updateDoc(toServeLogsRef, {
        toServeLogs: arrayUnion(log),
      })
        .then(() => {
          console.log("Document updated successfully");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setDoc(toServeLogsRef, {
        toServeLogs: [log],
        merchantId: rootState.merchantModule.merchantInfo.id,
      })
        .then(() => {
          console.log("Document written successfully");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }

    commit("addToServeLog", log);
  },

  async removeToServeLog({ commit, rootState }, logId) {
    const toServeLogsRef = doc(
      db,
      "ToServeLogs",
      rootState.merchantModule.merchantInfo.id
    );

    getDoc(toServeLogsRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const toServeLogs = docSnap.data().toServeLogs;

          const objectToRemove = toServeLogs.find((obj) => obj.id === logId);

          if (objectToRemove) {
            const updatedArray = arrayRemove(objectToRemove);
            updateDoc(toServeLogsRef, { toServeLogs: updatedArray });
          }
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    commit("removeToServeLog", logId);
  },

  // TODO Clear completed log

  async updateSingleToServeLog({ rootState }, updatedToServeLogs) {
    const toServeLogsRef = doc(
      db,
      "ToServeLogs",
      rootState.merchantModule.merchantInfo.id
    );

    updateDoc(
      toServeLogsRef,
      {
        toServeLogs: updatedToServeLogs,
      },
      { merge: true }
    );

    //commit?
  },
  async clearCompletedToServeLogs({ commit, rootState }, incompleteLogs) {
    const toServeLogsRef = doc(
      db,
      "ToServeLogs",
      rootState.merchantModule.merchantInfo.id
    );

    updateDoc(
      toServeLogsRef,
      {
        toServeLogs: incompleteLogs,
      },
      { merge: true }
    );
    commit("clearCompletedToServeLogs", incompleteLogs);
    //
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
