<template>
  <section class="rounded">
    <!-- TODO: apply v-for, add clickble open product details component -->

    <div
      v-if="items.length === 0"
      class="has-text-centered p-5 rounded has-fade-in-anim"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/no_data.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <div class="has-text-grey mx-auto">No item in this category yet</div>
      </div>
    </div>
    <div v-else class="has-fade-in-anim">
      <article
        v-for="item in items"
        :key="item.id"
        class="is-flex product-list-item"
      >
        <div
          class="media-content is-flex product-list-item-content rounded-2 p-3"
          @click="editItem(item)"
        >
          <figure>
            <p v-if="item.imageURL != ''" class="image is-64x64 mr-3">
              <img class="rounded-2" :src="item.imageURL" />
            </p>
            <div v-else class="image is-64x64 mr-3">
              <div
                class="rounded-2 has-background-light no-image-icon-container"
              >
                <b-icon icon="image" type="is-grey-light"></b-icon>
              </div>
            </div>
          </figure>
          <div class="content">
            <p class="has-text-weight-semibold is-size-6 is-size-7-mobile mb-2">
              {{ item.name }}
              <!-- <b-icon size="is-small" icon="arrow-top-right"></b-icon> -->
            </p>

            <p class="is-size-7-mobile">RM {{ item.basePrice }}</p>
          </div>
        </div>

        <div class="media-right my-auto is-flex">
          <!-- <b-button class="is-hidden-tablet" icon-left="list-status">
          </b-button> -->
          <div class="p-3 is-hidden-mobile">
            <b-button @click="manageStock(item)"
              ><span class="is-size-6">Manage Stock</span></b-button
            >
          </div>

          <b-dropdown
            class="py-3"
            aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
          >
            <template #trigger>
              <b-button icon-left="dots-vertical" type="is-text"></b-button>
            </template>

            <b-dropdown-item
              aria-role="listitem"
              class="is-hidden-tablet"
              @click="manageStock(item)"
              ><div class="media">
                <b-icon class="media-left" icon="list-status"></b-icon>
                <div class="media-content">
                  <h3>Manage Stock</h3>
                </div>
              </div></b-dropdown-item
            >
            <b-dropdown-item
              aria-role="listitem"
              @click="confirmRemoveItem(item.name, item.categoryId, item.id)"
              ><div class="media has-text-danger">
                <b-icon class="media-left" icon="delete-outline"></b-icon>
                <div class="media-content">
                  <h3>Remove Item</h3>
                </div>
              </div></b-dropdown-item
            >
          </b-dropdown>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "ItemList",
  props: {
    items: Array,
    loading: Boolean,
    editItem: { type: Function },
    manageStock: { type: Function },
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    confirmRemoveItem(itemName, categoryId, itemId) {
      this.$buefy.dialog.confirm({
        title: "Remove Item",
        message: `Are you sure you want to remove '${itemName}' ?`,
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () =>
          this.removeItem(categoryId, itemId) &&
          this.$buefy.toast.open("Removing Item. . ."),
      });
    },
    async removeItem(categoryId, itemId) {
      await this.$store.dispatch("productsModule/removeItem", {
        categoryId: categoryId,
        id: itemId,
      });
    },
  },
};
</script>

<style>
.product-list-item-content:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.product-list-item + .product-list-item {
  border-top: 1px solid #f5f5f5 !important;
}

.no-image-icon-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}
</style>
