<template>
  <section>
    <!-- TODO: apply v-for, add clickble open product details component -->

    <div
      v-if="itemByCode.length === 0"
      class="has-text-centered p-5 rounded has-fade-in-anim"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/search.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <!-- TODO ：remove after display feature added -->
        <div class="has-text-grey mx-auto mt-5">
          Please enter a valid code from physical menu, and search
        </div>
      </div>
    </div>
    <div v-else class="has-fade-in-anim">
      <article
        class="is-flex product-list-item"
        v-for="item in itemByCode"
        :key="item.id"
      >
        <div
          class="media-content is-flex merchant-menu-list-item-content rounded-2 p-3"
        >
          <figure>
            <p v-if="item.imageURL != ''" class="image is-64x64 mr-3">
              <img
                class="rounded-2"
                :src="
                  item.imageURL != ''
                    ? item.imageURL
                    : 'https://bulma.io/images/placeholders/128x128.png'
                "
              />
            </p>
            <div v-else class="image is-64x64 mr-3">
              <div
                class="rounded-2 has-background-light no-image-icon-container"
              >
                <b-icon icon="image" type="is-grey-light"></b-icon>
              </div>
            </div>
          </figure>
          <div class="content">
            <p class="has-text-weight-semibold is-size-7-mobile mb-0">
              {{ item.name }}
              <!-- <b-icon size="is-small" icon="arrow-top-right"></b-icon> -->
            </p>
            <p class="is-size-7 has-text-grey">{{ item.description }}</p>

            <p v-if="!item.hasBasePriceDiscount" class="is-size-7-mobile">
              RM {{ item.basePrice }}
            </p>
            <div v-else class="is-flex">
              <span
                class="is-size-7-mobile has-text-grey mb-0"
                style="text-decoration: line-through"
              >
                RM {{ item.basePrice }}
              </span>
              <span
                class="is-size-7-mobile ml-2 has-text-weight-semibold has-text-success"
              >
                RM
                {{
                  (
                    item.basePrice *
                    (1 - item.basePriceDiscountPercentage / 100)
                  ).toFixed(2)
                }}
              </span>
              <b-tag
                class="ml-2"
                type="is-success is-light"
                style="font-size: 0.6rem"
                >{{ `-${item.basePriceDiscountPercentage}%` }}</b-tag
              >
            </div>
          </div>
        </div>

        <div class="media-right my-auto is-flex">
          <!-- <b-button class="is-hidden-tablet" icon-left="list-status">
              </b-button> -->
          <div class="p-3">
            <div v-if="item.isAvailable">
              <!-- TODO: collection check -->

              <b-button icon-right="plus" @click="addToCart(item)"></b-button>
            </div>
            <span v-else class="is-size-7 has-text-grey">Out of Stock</span>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "ItemByCode",
  props: {
    itemByCode: Array,
    addToCart: { type: Function },
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.merchant-menu-list-item-content:hover {
}
.merchant-menu-list-item + .merchant-menu-list-item {
  border-top: 1px solid #f5f5f5 !important;
}

.no-image-icon-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
