<template>
  <div class="mx-auto p-5" style="max-width: 400px">
    <div class="card p-5 rounded">
      <div class="has-text-centered">
        <img
          alt="Vue logo"
          src="@/assets/text-logo.png"
          class="rd-logo"
          style="
            height: 45px !important;
            width: auto !important;
            padding-top: 0.5rem;
            image-rendering: -webkit-optimize-contrast;
          "
        />
      </div>
      <!-- <h1 class="is-size-5 has-text-weight-semibold">Login</h1> -->
      <div class="flex mb-5 has-text-centered">
        <span class="is-size-7 has-text-grey mr-1">Not our member?</span>
        <a class="is-size-7 is-clickable">Contact Us</a>
        <!-- TODO: Contact Us via WhatsApp -->
        <!-- TODO: Open sign up to public -->
        <!-- <a class="is-size-7 is-clickable" @click="goToSignUp">Sign Up Now</a> -->
      </div>
      <b-field label="Email">
        <b-input
          type="email"
          v-model="merchantEmail.value"
          :value="merchantEmail.value"
          maxlength="50"
          :has-counter="false"
          validation-message="Please enter a valid email"
          :pattern="merchantEmail.pattern"
          required
        >
        </b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          type="password"
          v-model="password.value"
          :value="password.value"
          password-reveal
          validation-message="8-16 characters, at least one lowercase, one uppercase, one number, one special character"
          :pattern="password.pattern"
          required
        >
        </b-input>
      </b-field>
      <b-button
        :disabled="!validateInputs || isLoggingIn"
        type="is-primary"
        expanded
        class="mt-5"
        @click="merchantLogin"
      >
        {{ isLoggingIn ? "Logging in" : "Login" }}
        <b-icon
          v-if="isLoggingIn"
          icon="loading"
          size="is-small"
          custom-class="fa-spin"
          class="ml-1"
        ></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ERROR_MESSAGES } from "@/errorCodes.js";

export default {
  name: "MerchantLogin",
  data() {
    return {
      merchantEmail: {
        value: "",
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
      },
      password: {
        value: "",
        pattern:
          "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$",
      },
      isLoggingIn: false,
    };
  },
  computed: {
    validateInputs() {
      const regexEmail = new RegExp(this.merchantEmail.pattern);
      const regexPassword = new RegExp(this.password.pattern);

      if (
        regexEmail.test(this.merchantEmail.value) &&
        regexPassword.test(this.password.value)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    merchantLogin() {
      this.isLoggingIn = true;
      const auth = getAuth();
      signInWithEmailAndPassword(
        auth,
        this.merchantEmail.value,
        this.password.value
      )
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("user", user);

          if (user.emailVerified) {
            this.$buefy.toast.open("Login successfully 🎉");
            this.$router.push("/merchant/dashboard");
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message:
                "Please check your email to verify your account for first time login",
            });
          }
          this.isLoggingIn = false;
        })
        .catch((error) => {
          if (error.code !== undefined) {
            const errorCode = error.code;
            // const errorMessage = error.message;

            this.$buefy.toast.open(
              `Error: ${ERROR_MESSAGES[errorCode] || errorCode}`
            );
          }
          this.isLoggingIn = false;
        });
    },

    // TODO: Open sign up to public once subscription module done in the future
    // goToSignUp() {
    //   this.$router.push("/merchant/sign-up");
    // },
  },
};
</script>
