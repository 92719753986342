<template>
  <!-- TODO : clear 'added but not saved' variation when onCancel -->
  <!-- TODO: remove uploaded image but unsaved item image when navigate away.. -->
  <b-modal
    v-model="isInvoiceModalActive"
    scroll="keep"
    :on-cancel="closeInvoiceModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white p-5">
      <div class="mb-5">
        <span class="has-text-weight-bold is-size-5">Invoice</span>
      </div>
      <div v-if="invoice">
        <div class="columns">
          <!-- QR Section -->
          <div class="p-3 rounded column is-half">
            <div>
              <div
                class="is-flex-grow-1 pl-1 pt-1 has-text-grey has-text-weight-medium ml-5"
              >
                Scan QR to Download
              </div>
              <!-- QR Code here -->
              <img
                v-if="qrImageUrl !== null"
                :src="qrImageUrl"
                alt="QR Code"
                style="width: 350px"
              />
              <div v-else class="p-6">
                <b-skeleton
                  class="rounded"
                  width="260px"
                  height="260px"
                ></b-skeleton>
              </div>
            </div>
          </div>

          <div
            class="p-5 mb-6 box has-border-grey rounded is-size-6 column is-half is-two-fifths-desktop is-one-third-widescreen"
            style="height: fit-content"
          >
            <div class="columns mb-1 is-mobile">
              <div
                class="column p-0 is-narrow has-text-grey"
                style="width: 100px"
              >
                Invoice ID
              </div>
              <div class="column py-0">{{ `${invoice.id}` }}</div>
            </div>
            <div class="columns mb-1 is-mobile">
              <div
                class="column p-0 is-narrow has-text-grey"
                style="width: 100px"
              >
                Table
              </div>
              <div class="column py-0">{{ `${invoice.tableName}` }}</div>
            </div>

            <div class="columns mb-1 is-mobile">
              <div
                class="column p-0 is-narrow has-text-grey"
                style="width: 100px"
              >
                Created at
              </div>
              <div class="column py-0 pr-0">
                <!-- {{ `${formatTimestampToDateTime(invoice.createdAt)}` }} -->
              </div>
            </div>
            <hr class="my-3" />
            <div>
              <div v-for="order in invoice.ordersItems" :key="order.id">
                <div>
                  <!-- <div class="columns mb-2 is-mobile">
                  <div
                    class="column p-0 is-narrow has-text-grey"
                    style="width: 100px"
                  >
                    Order ID
                  </div>
                  <div class="column py-0">{{ `${order.id}` }}</div>
                </div> -->

                  <div v-for="item in order.items" :key="item.id" class="mb-2">
                    <div class="columns mb-2 is-mobile">
                      <div class="column p-0 is-narrow" style="width: 25px">
                        {{ item.quantity }}
                      </div>
                      <div
                        class="column p-0 is-narrow has-text-grey ml-2"
                        style="width: 10px"
                      >
                        {{ `x` }}
                      </div>
                      <div class="column py-0">
                        <p>{{ `${item.name}` }}</p>

                        <p class="has-text-grey is-size-7">
                          <span
                            v-for="(
                              selectedVariation, selectedVariationIndex
                            ) in item.selectedVariations"
                            :key="selectedVariationIndex"
                          >
                            {{
                              selectedVariation.name +
                              (selectedVariationIndex !==
                              item.selectedVariations?.length - 1
                                ? ", "
                                : "")
                            }}
                          </span>
                        </p>
                        <p class="has-text-grey is-size-7">
                          <span
                            v-for="(
                              selectedCollection, selectedCollectionIndex
                            ) in item.selectedCollections"
                            :key="selectedCollectionIndex"
                          >
                            <span
                              v-for="(
                                selection, selectionIndex
                              ) in selectedCollection.selections"
                              :key="selectionIndex"
                              >{{
                                selection.name +
                                (selectionIndex !==
                                selectedCollection.selections?.length - 1
                                  ? ", "
                                  : "")
                              }} </span
                            ><br />
                          </span>
                        </p>
                        <p
                          v-if="item.specialRemark !== ''"
                          class="has-text-grey mb-0 is-italic is-size-7"
                        >
                          {{ `" ${item.specialRemark} "` }}
                        </p>
                      </div>
                      <div
                        class="column p-0 is-narrow has-text-right ml-2"
                        style="width: 60px"
                      >
                        {{ `${(item.quantity * item.totalPrice).toFixed(2)}` }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="my-3" />

            <div class="columns mb-1 is-mobile">
              <div class="column p-0 is-narrow" style="width: 120px">
                Subtotal
              </div>
              <div class="column p-0 has-text-right">
                {{ `${invoice.subtotal}` }}
              </div>
            </div>

            <div class="columns mb-1 is-mobile">
              <div
                class="column p-0 is-narrow has-text-grey"
                style="width: 120px"
              >
                Service Tax
              </div>
              <div class="column p-0 has-text-right has-text-grey">
                {{ `${invoice.serviceTax}` }}
              </div>
            </div>

            <div class="columns mb-1 is-mobile">
              <div
                class="column p-0 is-narrow has-text-grey"
                style="width: 120px"
              >
                Service Charge
              </div>
              <div class="column p-0 has-text-right has-text-grey">
                {{ `${invoice.serviceCharge}` }}
              </div>
            </div>

            <hr class="my-3" />
            <div class="columns mb-1 is-mobile is-size-5">
              <div class="column p-0 is-narrow" style="width: 120px">Total</div>
              <div class="column p-0 has-text-right">
                {{ `${invoice.totalAmount}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        Loading ...
        <!-- TODO: Skeleton? -->
      </div>
    </div>

    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </b-modal>
</template>

<script>
import QRCode from "qrcode";
//import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "InvoiceModal",
  components: {},
  props: {
    isInvoiceModalActiveProp: Boolean,
    closeInvoiceModal: { type: Function },
    invoice: Object,
  },
  data() {
    return {
      isLoading: false,
      isQROpen: false,
      qrImageUrl: null,
    };
  },

  created() {},

  mounted() {
    this.renderQR();
  },

  computed: {
    isInvoiceModalActive: {
      get() {
        return this.isInvoiceModalActiveProp;
      },
      set() {},
    },
  },
  methods: {
    onCancel() {
      this.closeInvoiceModal();
    },

    formatTimestampToDateTime(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      const year = date.getFullYear();

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string

      const formattedDateTime = `${day} ${month} ${year}, ${hours}.${minutes} ${amPm}`;

      return formattedDateTime;
    },

    async generateQR() {
      const url = `https://${window.location.host}/invoice/${this.invoice.merchantId}/${this.invoice.id}`;

      const mainCanvas = document.createElement("canvas");

      const width = 600;
      const height = 600;

      mainCanvas.width = width;
      mainCanvas.height = height;

      const mainCtx = mainCanvas.getContext("2d");

      await QRCode.toCanvas(mainCanvas, url, {
        width: mainCanvas.width,
        height: mainCanvas.height,
      });

      const labelCanvas = document.createElement("canvas");
      labelCanvas.width = mainCanvas.width;
      labelCanvas.height = mainCanvas.height;

      const labelCtx = labelCanvas.getContext("2d");

      labelCtx.font = "18px Poppins";
      labelCtx.fillStyle = "#000";
      labelCtx.textAlign = "center";

      const label = `Invoice ${this.invoice.id}`;
      const x = labelCanvas.width / 2;
      let y = 40;

      const qrHeight = labelCanvas.height - 20;
      const labelHeight = parseInt(labelCtx.font) * 1.2;
      if (y + labelHeight > qrHeight) {
        y = qrHeight - labelHeight;
      }

      labelCtx.fillText(label, x, y);

      const logo = new Image();
      logo.src = require("@/assets/square-logo.jpeg");

      await new Promise((resolve) => {
        logo.onload = resolve;
      });

      const logoSize = labelCanvas.width * 0.2;
      const logoX = (labelCanvas.width - logoSize) / 2;
      const logoY = (labelCanvas.height - logoSize) / 2;

      labelCtx.drawImage(logo, logoX, logoY, logoSize, logoSize);

      mainCtx.drawImage(mainCanvas, 0, 0);

      mainCtx.drawImage(labelCanvas, 0, 0);

      return mainCanvas.toDataURL("image/png");
    },

    async renderQR() {
      this.qrImageUrl = await this.generateQR();
    },
  },
};
</script>

<style>
.product-image-preview img {
  height: 195px !important;
}

.img-loading img {
  filter: opacity(0.5);
}

button.delete.is-small {
  background: hsl(243.08deg 100% 69.41%);
}
</style>
