<template>
  <section class="responsive-padding">
    <div class="mb-2">
      <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">Categories</div>

        <div class="ml-auto">
          <b-button
            class="is-secondary-btn is-hidden-tablet"
            icon-left="plus"
            @click="createCategory"
          >
          </b-button>
          <b-button
            class="is-secondary-btn is-hidden-mobile"
            icon-left="plus"
            label="Create Category"
            @click="createCategory"
          >
          </b-button>
        </div>
      </div>

      <CreateCategory
        v-if="isCreatingCategory"
        :isCreateCategoryModalActiveProp="isCreatingCategory"
        :closeCreateCategoryModal="closeCreateCategoryModal"
      />

      <CategoryList
        v-if="categories.length !== 0"
        :categories="categories"
        class="has-fade-in-anim"
        :getItems="getItems"
        :selectedCategory="selectedCategory"
      />
      <div
        v-else
        class="has-text-centered p-5 rounded has-fade-in-anim has-border-grey"
      >
        <div class="has-text-centered pt-3 px-5 rounded">
          <b-image
            :src="require('@/assets/illustration/empty.svg')"
            alt=""
            class="is-128x128 mx-auto mb-3"
          ></b-image>
          <!-- TODO ：remove after display feature added -->
          <div class="has-text-grey mx-auto is-size-6 mb-1">
            No category yet
          </div>
          <p class="has-text-grey-light is-size-7 mb-3">
            Looks like you haven't created any category yet.
          </p>
          <a class="is-size-7" @click="createCategory">Create now</a>
        </div>
      </div>
    </div>

    <div
      v-if="categories.length !== 0"
      class="has-background-white has-border-grey rounded p-4"
      style="height: calc(100vh - 235px)"
    >
      <div class="mb-3 is-flex">
        <!-- TODO: Pass in selected category name -->
        <div class="my-auto">
          <span
            v-if="selectedCategory !== {} && !isItemsLoading"
            class="has-text-weight-medium is-size-6 my-2"
          >
            {{ `${itemsByCategory?.length} Items` }}
          </span>
          <b-skeleton
            v-else
            width="60"
            height="24"
            class="my-auto"
          ></b-skeleton>
        </div>

        <div class="ml-auto">
          <b-button
            class="is-secondary-btn is-hidden-tablet"
            icon-left="plus"
            @click="addItem"
          >
          </b-button>
          <b-button
            class="is-secondary-btn is-hidden-mobile"
            icon-left="plus"
            label="Add Item"
            @click="addItem"
          >
          </b-button>

          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
          >
            <template #trigger>
              <b-button
                icon-left="dots-horizontal"
                class="is-default-button ml-3"
              ></b-button>
            </template>

            <b-dropdown-item aria-role="listitem" @click="editCategory"
              ><div class="media">
                <b-icon class="media-left" icon="square-edit-outline"></b-icon>
                <div class="media-content">
                  <h3>Edit Category</h3>
                </div>
              </div></b-dropdown-item
            >
            <b-dropdown-item
              aria-role="listitem"
              @click="
                confirmRemoveCategory(
                  selectedCategory.name,
                  itemsByCategory.length
                )
              "
              ><div class="media has-text-danger">
                <b-icon class="media-left" icon="delete-outline"></b-icon>
                <div class="media-content">
                  <h3>Remove Category</h3>
                </div>
              </div></b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="item-list-container">
        <EditCategory
          v-if="isEditingCategory"
          :category="selectedCategory"
          :isEditCategoryModalActiveProp="isEditingCategory"
          :closeEditCategoryModal="closeEditCategoryModal"
        />

        <!-- TODO: Add props, pass in product item list (array) of selected category -->

        <ItemList
          v-if="!isItemsLoading"
          :items="itemsByCategory"
          :editItem="editItem"
          :manageStock="manageStock"
        />

        <div v-else>
          <div class="p-3" v-for="i in 3" :key="i">
            <b-skeleton size="is-large" height="64" />
          </div>
        </div>
      </div>
    </div>
    <AddItem
      v-if="isAddingItem"
      :isAddItemModalActiveProp="isAddingItem"
      :closeAddItemModal="closeAddItemModal"
      :category="selectedCategory"
    />
    <EditItem
      v-if="isEditingItem"
      :isEditItemModalActiveProp="isEditingItem"
      :closeEditItemModal="closeEditItemModal"
      :category="selectedCategory"
      :item="selectedItem"
    />

    <ManageStock
      v-if="isManagingStock"
      :isManageStockModalActiveProp="isManagingStock"
      :closeManageStockModal="closeManageStockModal"
      :item="selectedItem"
    />
  </section>
</template>

<script>
import CreateCategory from "@/components/Merchant/ProductManagement/CreateCategory.vue";
import CategoryList from "@/components/Merchant/ProductManagement/CategoryList.vue";

import AddItem from "@/components/Merchant/ProductManagement/AddItem.vue";
import EditCategory from "@/components/Merchant/ProductManagement/EditCategory.vue";
import ItemList from "@/components/Merchant/ProductManagement/ItemList.vue";
import EditItem from "@/components/Merchant/ProductManagement/EditItem.vue";

import ManageStock from "@/components/Merchant/ProductManagement/ManageStock.vue";

export default {
  name: "ProductManagement",
  components: {
    CreateCategory,
    CategoryList,
    AddItem,
    EditCategory,
    ItemList,
    EditItem,
    ManageStock,
  },
  data() {
    return {
      selectedCategory: {},
      isCreatingCategory: false,
      isEditingCategory: false,

      selectedItem: {},
      isAddingItem: false,
      isEditingItem: false,
      isManagingStock: false,

      isCategoriesLoading: true,
      isItemsLoading: true,
    };
  },
  computed: {
    categories() {
      let temp = this.$store.getters["productsModule/getCategories"];

      return temp.sort((a, b) => {
        return (
          (a.positionNo === null) - (b.positionNo === null) ||
          +(a.positionNo > b.positionNo) ||
          -(a.positionNo < b.positionNo)
        );
      });
    },

    itemsByCategory() {
      return this.$store.getters["productsModule/getItemsByCategory"];
    },
  },
  async beforeRouteLeave(to, from, next) {
    // run your function here
    //await this.onCancel();
    //console.log("User is navigating away from this component.");

    // call next() to allow the navigation to proceed
    next();
  },
  async created() {},
  async mounted() {},
  methods: {
    addCategory(category) {
      this.$store.dispatch("productsModule/createCategory", {
        name: category,
        positionNo: null,
      });
    },
    promptAddCategory() {
      this.$buefy.dialog.prompt({
        message: `Name the Category`,
        inputAttrs: {
          placeholder: "e.g. Beverages",
          maxlength: 40,
        },
        trapFocus: true,
        onConfirm: (value) => this.addCategory(value),
      });
    },
    async getItems(category) {
      // Do something with the value
      this.selectedCategory = category;
      await this.$store.dispatch(
        "productsModule/initItemsRealtimeListeners",
        category.id
      );

      this.isItemsLoading = true;

      setTimeout(async () => {
        await this.$store.dispatch(
          "productsModule/retrieveItemsByCategory",
          this.selectedCategory.id
        );
        this.isItemsLoading = false;
      }, 300);
    },

    createCategory() {
      this.isCreatingCategory = true;
    },
    closeCreateCategoryModal() {
      this.isCreatingCategory = false;
    },

    addItem() {
      this.isAddingItem = true;
    },
    closeAddItemModal() {
      this.isAddingItem = false;
    },

    editCategory() {
      this.isEditingCategory = true;
    },
    closeEditCategoryModal() {
      this.isEditingCategory = false;
    },

    confirmRemoveCategory(categoryName, itemsCount) {
      this.$buefy.dialog.confirm({
        title: "Remove Category",
        message:
          itemsCount > 0
            ? `Are you sure you want to remove '${categoryName}' category with ${itemsCount} items?`
            : `Are you sure you want to remove '${categoryName}' category?`,
        confirmText: "Yes",
        type: "is-danger",
        onConfirm: () => this.removeCategory(),
      });
    },
    async removeCategory() {
      await this.$store.dispatch(
        "productsModule/removeCategory",
        this.selectedCategory.id
      );
      // TODO: let category menu show 1st selected
      this.getItems(this.categories[0]);
    },

    editItem(item) {
      this.selectedItem = item;
      this.isEditingItem = true;
    },
    closeEditItemModal() {
      this.selectedItem = {};
      this.isEditingItem = false;
    },

    manageStock(item) {
      this.selectedItem = item;
      this.isManagingStock = true;
    },
    closeManageStockModal() {
      this.selectedItem = {};
      this.isManagingStock = false;
    },
  },
};
</script>

<style>
.item-list-container {
  height: calc(100vh - 323px);
  overflow-y: auto;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-right: 0.75rem;
  padding-bottom: 90px;
}
</style>
