import { db } from "@/firebase";
import { doc } from "firebase/firestore";
import { getDoc, setDoc } from "firebase/firestore";
import { updateDoc, arrayRemove } from "firebase/firestore";

import { getAuth } from "firebase/auth";

import { ToastProgrammatic as Toast } from "buefy";

const state = {
  cartItems: [],
  orders: [],
};

const getters = {
  getCartItems: (state) => state.cartItems,
  getCartItemsLength: (state) =>
    state.cartItems.reduce(
      (accumulator, currentValue) => accumulator + currentValue.quantity,
      0
    ),
};

const mutations = {
  addItemToCart(state, combinedItems) {
    state.cartItems = combinedItems;
  },

  updateItemInCart(state, combinedItems) {
    state.cartItems = combinedItems;
  },

  retrieveCartItemsByMerchant(state, items) {
    state.cartItems = items;
  },

  placeOrder(state, data) {
    state.orders.push(data);
  },

  removeItemFromCart(state, item) {
    var targetIndex = state.cartItems.findIndex((x) => x.id == item.id);
    if (targetIndex >= 0) {
      state.cartItems.splice(targetIndex, 1);
    }
  },

  clearCartItems(state) {
    state.cartItems = [];
  },
};

const actions = {
  async addItemToCart({ commit, state }, item) {
    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const itemId =
      "F" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    try {
      const date = new Date();
      // TODO: Change all to Carts - MerchantId - Array

      let newCartItems = state.cartItems;

      newCartItems.push({
        id: itemId,
        ...item,
        // userId: userId,
        isKitchenChecked: false,
        createdAt: date,
        updatedAt: date,
      });

      const _ = require("lodash");

      const combinedItems = [];

      for (let i = 0; i < newCartItems.length; i++) {
        const currentItem = newCartItems[i];
        let found = false;

        for (let j = 0; j < combinedItems.length; j++) {
          const combinedItem = combinedItems[j];
          if (
            currentItem.productId === combinedItem.productId &&
            currentItem.rewardId === combinedItem.rewardId &&
            _.isEqual(
              currentItem.selectedVariations,
              combinedItem.selectedVariations
            ) &&
            _.isEqual(
              currentItem.selectedCollections,
              combinedItem.selectedCollections
            ) &&
            _.isEqual(currentItem.specialRemark, combinedItem.specialRemark) &&
            _.isEqual(currentItem.serviceMode, combinedItem.serviceMode)
          ) {
            // Combine quantities ..
            combinedItem.quantity += currentItem.quantity;

            found = true;
            break;
          }
        }

        if (!found) {
          // Add new item to combinedItems array
          combinedItems.push(currentItem);
        }
      }
      //

      let isLoginAsGuest = getAuth().currentUser.isAnonymous;
      if (!isLoginAsGuest) {
        let userId = getAuth().currentUser.phoneNumber.replace("+", "");

        const docRef = doc(db, "Users", userId, "Carts", item.merchantId);

        await setDoc(docRef, {
          cartItems: combinedItems,
          merchantId: item.merchantId,
        });
      }
      console.log("debug", combinedItems);
      commit("addItemToCart", combinedItems);

      Toast.open("Added to cart");
      return { success: true };
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong. Please try again.");
      return { success: false, error: e.message };
    }
  },

  async updateItemInCart({ commit, state }, item) {
    try {
      const date = new Date();
      let formattedItem = {
        ...item,
        updatedAt: date,
      };

      let newCartItems = state.cartItems;

      var targetIndex = newCartItems.findIndex((x) => x.id == item.id);
      if (targetIndex >= 0) {
        Object.assign(newCartItems[targetIndex], formattedItem);
      }

      const _ = require("lodash");

      const combinedItems = [];

      for (let i = 0; i < newCartItems.length; i++) {
        const currentItem = newCartItems[i];
        let found = false;

        for (let j = 0; j < combinedItems.length; j++) {
          const combinedItem = combinedItems[j];
          if (
            currentItem.productId === combinedItem.productId &&
            currentItem.rewardId === combinedItem.rewardId &&
            _.isEqual(
              currentItem.selectedVariations,
              combinedItem.selectedVariations
            ) &&
            _.isEqual(
              currentItem.selectedCollections,
              combinedItem.selectedCollections
            ) &&
            _.isEqual(currentItem.specialRemark, combinedItem.specialRemark) &&
            _.isEqual(currentItem.serviceMode, combinedItem.serviceMode)
          ) {
            // Combine quantities ..
            combinedItem.quantity += currentItem.quantity;

            found = true;
            break;
          }
        }

        if (!found) {
          // Add new item to combinedItems array
          combinedItems.push(currentItem);
        }
      }
      //

      let isLoginAsGuest = getAuth().currentUser.isAnonymous;
      if (!isLoginAsGuest) {
        let userId = getAuth().currentUser.phoneNumber.replace("+", "");

        const docRef = doc(db, "Users", userId, "Carts", item.merchantId);

        await setDoc(docRef, {
          cartItems: combinedItems,
          merchantId: item.merchantId,
        });
      }

      commit("updateItemInCart", combinedItems);

      Toast.open("Updated Successfully");
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong, please try again");
    }
  },

  // TODO: Change all to Carts - MerchantId - Array
  async retrieveCartItemsByMerchant({ commit }, merchantId) {
    let userId = getAuth().currentUser.phoneNumber.replace("+", "");

    const docRef = doc(db, "Users", userId, "Carts", merchantId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      commit("retrieveCartItemsByMerchant", docSnap.data().cartItems);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },

  // TODO: retrieve orders

  async removeItemFromCart({ commit }, data) {
    try {
      let isLoginAsGuest = getAuth().currentUser.isAnonymous;
      if (!isLoginAsGuest) {
        let userId = getAuth().currentUser.phoneNumber.replace("+", "");

        const merchantCartRef = doc(
          db,
          "Users",
          userId,
          "Carts",
          data.merchantId
        );

        await updateDoc(merchantCartRef, {
          cartItems: arrayRemove(data.item),
        })
          .then(() => {
            Toast.open("Item has been removed from cart");
          })
          .catch((error) => {
            Toast.open("Something went wrong", error);
          });
      }

      commit("removeItemFromCart", data.item);
      return { success: true };
    } catch (e) {
      console.error("Error updating document: ", e);
      Toast.open("Something went wrong. Please try again.");
      return { success: false, error: e.message };
    }
  },

  async clearCartItems({ commit }, merchantId) {
    let isLoginAsGuest = getAuth().currentUser.isAnonymous;
    if (!isLoginAsGuest) {
      let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      const merchantCartRef = doc(db, "Users", userId, "Carts", merchantId);

      await updateDoc(merchantCartRef, {
        cartItems: [],
      })
        .then(() => {
          Toast.open("All items have been removed from cart");
        })
        .catch((error) => {
          Toast.open("Something went wrong", error);
        });
    }

    commit("clearCartItems");
  },

  async clearCartItemsOnOrdersPlaced({ commit }, merchantId) {
    let isLoginAsGuest = getAuth().currentUser.isAnonymous;
    if (!isLoginAsGuest) {
      let userId = getAuth().currentUser.phoneNumber.replace("+", "");

      const merchantCartRef = doc(db, "Users", userId, "Carts", merchantId);

      await updateDoc(merchantCartRef, {
        cartItems: [],
      })
        .then(() => {})
        .catch((error) => {
          Toast.open("Something went wrong", error);
        });
    }
    commit("clearCartItems");
  },

  async placeOrder({ commit }, data) {
    let isLoginAsGuest = getAuth().currentUser.isAnonymous;
    let userId;

    if (!isLoginAsGuest) {
      userId = getAuth().currentUser.phoneNumber.replace("+", "");
    } else {
      userId = getAuth().currentUser.uid;
    }

    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const orderId =
      "R" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    let formattedData = {
      ...data,
      id: orderId,
      userId: userId,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    try {
      await setDoc(doc(db, "Orders", orderId), formattedData);

      commit("placeOrder", formattedData);
      //Toast.open("Order has been placed successfully");
      return { success: true };
    } catch (e) {
      console.error("Error adding document: ", e);

      Toast.open("Something went wrong, please try again");
      return { success: false, error: e.message };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
