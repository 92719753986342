<template>
  <section class="has-background-light">
    <b-navbar
      class="py-3 px-3"
      style="
        backdrop-filter: blur(16px);
        background-color: rgba(234, 237, 240, 0.8);
        border-bottom: 1px solid rgb(223, 227, 231);
        position: sticky;
        top: 0px;
        width: 100%;
      "
    >
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            alt="Wokytalk"
            src="@/assets/text-logo.png"
            class="rd-logo"
            style="image-rendering: -webkit-optimize-contrast"
          />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item href="#"> Home </b-navbar-item>
        <b-navbar-item @click="scrollToSection('products')">
          Products
        </b-navbar-item>
        <b-navbar-item @click="scrollToSection('services')">
          Services
        </b-navbar-item>

        <b-navbar-item @click="scrollToSection('contact')">
          Contact us</b-navbar-item
        >
        <b-navbar-item @click="scrollToSection('about')"> About</b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a
              class="button is-primary"
              href="https://forms.gle/z8j9JoMCJsUkNm6X9"
            >
              <strong>Request for Demo</strong>
            </a>
            <a class="button" @click="goToLogin"> Log in </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="has-background-white">
      <div class="container">
        <div class="columns mx-auto">
          <div class="column px-5 pt-5 pb-5">
            <div
              class="has-text-weight-semibold is-size-5-mobile is-size-5-tablet is-size-4-desktop is-size-3-widescreen pl-5 pt-5 mb-5"
            >
              Empowering F&B excellence through digital solutions
            </div>
            <p class="has-text-grey pl-5 is-size-6-desktop is-size-7">
              To improve operational efficiency, enhance customer experiences, &
              leverage data-driven insights, companies can drive revenue growth
              and capitalize on new market opportunities
            </p>

            <b-button
              class="ml-5 mt-5"
              type="is-primary is-outlined"
              @click="scrollToSection('products')"
            >
              Learn more</b-button
            >
          </div>
          <div class="column p-5 has-text-centered">
            <img
              alt="image"
              src="@/assets/illustration/banner-img.png"
              style="max-height: 400px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="pt-6" id="products">
        <div
          class="has-text-grey has-text-weight-semibold is-size-3 has-text-centered px-4 pt-4 mb-4"
        >
          Products
        </div>
      </div>
    </div>

    <div class="container p-4">
      <div class="has-background-white rounded box">
        <div class="mx-auto has-text-centered">
          <div
            class="is-justify-content-center has-text-centered is-flex-tablet is-block-mobile mt-3 mb-5"
          >
            <img
              alt="Wokytalk"
              src="@/assets/text-logo.png"
              class="rd-logo"
              style="image-rendering: -webkit-optimize-contrast; height: 40px"
            />
            <p class="pt-1">
              <span class="has-text-dark has-text-weight-semibold is-size-5">
                F&B System
              </span>
              <span class="has-text-grey">with QR Ordering</span>
            </p>
          </div>

          <div class="is-block-mobile">
            <div class="mx-auto" style="width: auto">
              <div class="mx-auto" style="width: fit-content">
                <div
                  v-for="feature in features"
                  :key="feature.id"
                  class="is-flex mb-3"
                >
                  <b-icon
                    icon="check-circle"
                    class="mr-2"
                    type="is-success"
                  ></b-icon>
                  <p class="has-text-left">{{ feature.title }}</p>
                </div>

                <b-button
                  class="my-5"
                  expanded
                  type="is-primary is-outlined"
                  tag="a"
                  href="https://forms.gle/z8j9JoMCJsUkNm6X9"
                >
                  Request for Demo
                </b-button>
              </div>

              <div
                class="py-4 pl-3 mb-5"
                style="width: inherit; overflow-x: scroll"
              >
                <div class="cards">
                  <div
                    v-for="merchantScreenshot in merchantScreenshots"
                    :key="merchantScreenshot.id"
                    class="screenshot-card mb-0 rounded p-0 screenshot"
                  >
                    <div class="mb-2">
                      <b-image
                        :src="
                          require(`@/assets/screenshots/merchant/${merchantScreenshot.filename}`)
                        "
                        alt="image"
                        class="screenshot-img"
                      ></b-image>
                    </div>

                    <div class="has-text-left">
                      <div
                        class="has-text-weight-medium has-text-grey is-size-7 mb-1"
                      >
                        {{ merchantScreenshot.title }}
                      </div>
                      <!-- <p class="is-size-7 has-text-grey">
                        {{ screenshot.description }}
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-4 pl-3" style="width: inherit; overflow-x: scroll">
                <div class="cards">
                  <div
                    v-for="userScreenshot in userScreenshots"
                    :key="userScreenshot.id"
                    class="mobile-screenshot-card mb-0 rounded p-0 screenshot"
                  >
                    <div class="mb-2">
                      <b-image
                        :src="
                          require(`@/assets/screenshots/user/${userScreenshot.filename}`)
                        "
                        alt="image"
                        class="screenshot-img"
                      ></b-image>
                    </div>

                    <div class="has-text-left">
                      <div
                        class="has-text-weight-medium has-text-grey is-size-7 mb-1"
                      >
                        {{ userScreenshot.title }}
                      </div>
                      <!-- <p class="is-size-7 has-text-grey">
                        {{ screenshot.description }}
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mr-0 p-5 mb-6 horizontal-scroll-container">
      <div class="cards">
        <div
          v-for="sellingPoint in sellingPoints"
          :key="sellingPoint.id"
          class="feature-card mb-0 rounded box p-5"
        >
          <div style="height: 120px">
            <div class="has-text-weight-semibold mb-2">
              {{ sellingPoint.title }}
            </div>
            <p class="is-size-7 has-text-grey mb-4">
              {{ sellingPoint.description }}
            </p>
          </div>
          <div>
            <b-image
              :src="
                require(`@/assets/illustration/usp/${sellingPoint.filename}`)
              "
              alt="image"
            ></b-image>
          </div>
        </div>
      </div>
    </div>

    <div
      class="has-text-grey has-text-weight-semibold is-size-3 has-text-centered px-4 pt-4 is-size-4"
    >
      Testimonials
    </div>

    <div class="container mr-0 p-5 mb-5 horizontal-scroll-container">
      <div class="cards">
        <div
          v-for="testimonial in testimonials"
          :key="testimonial.id"
          class="feature-card mb-0 rounded box p-5"
        >
          <div>
            <p class="is-size-6 has-text-grey mb-4">
              {{ testimonial.content }}
            </p>

            <p class="is-size-6 has-text-grey-dark mb-4">
              - {{ testimonial.name }}, {{ testimonial.role }}
            </p>
          </div>
          <!-- <div>
            <b-image
              :src="
                require(`@/assets/illustration/usp/${sellingPoint.filename}`)
              "
              alt="image"
            ></b-image>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Other Services -->

    <div class="container">
      <div class="pt-6" id="services">
        <div
          class="has-text-grey has-text-weight-semibold is-size-3 has-text-centered p-4"
        >
          Other Services
        </div>
        <div class="p-5">
          <div
            class="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd"
          >
            <div class="column is-one-thirds">
              <div class="rounded box p-4 has-text-centered">
                <img
                  alt="image"
                  src="@/assets/illustration/webdd.png"
                  style="max-height: 200px"
                />
                <div class="has-text-weight-semibold mb-3">
                  Web Design & Development
                </div>
                <p class="is-size-7 has-text-grey has-text-left mb-3">
                  We build visually appealing, functional websites that reflect
                  the unique business profile and brand identity while ensuring
                  compatibility and intuitive user experiences across devices
                  and browsers.
                </p>
              </div>
            </div>
            <div class="column is-one-thirds">
              <div class="rounded box p-4 has-text-centered">
                <img
                  alt="image"
                  src="@/assets/illustration/marketing.png"
                  style="max-height: 200px"
                />
                <div class="has-text-weight-semibold mb-3">
                  Digital Marketing
                </div>
                <p class="is-size-7 has-text-grey has-text-left mb-3">
                  We utilize various online strategies such as SEO, social media
                  marketing, content marketing, and online advertising to
                  increase brand visibility, drive website traffic, and generate
                  leads.
                </p>
              </div>
            </div>
            <div class="column is-one-thirds">
              <div class="rounded box p-4 has-text-centered">
                <img
                  alt="image"
                  src="@/assets/illustration/ecom.png"
                  style="max-height: 200px"
                />
                <div class="has-text-weight-semibold mb-3">E-commerce</div>
                <p class="is-size-7 has-text-grey has-text-left mb-3">
                  We facilitate online sales by creating and managing secure
                  platforms with features like payment processing, inventory
                  management, order fulfillment, and customer support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="has-background-white">
      <div class="container">
        <div class="pt-6" id="contact">
          <div
            class="has-text-grey has-text-weight-semibold is-size-3 has-text-centered p-4"
          >
            Contact us
          </div>
          <div class="mx-auto" style="width: fit-content">
            <div class="px-5 is-flex">
              <b-icon
                icon="instagram"
                class="mr-2 has-text-grey-light"
              ></b-icon>
              <a class="mb-2" href="https://www.instagram.com/wokytalk/"
                >wokytalk</a
              >
            </div>
            <div class="px-5 is-flex">
              <b-icon icon="facebook" class="mr-2 has-text-grey-light"></b-icon>
              <a class="mb-2" href="https://www.facebook.com/wokytalk/"
                >WokyTalk</a
              >
            </div>
            <div class="px-5 is-flex">
              <b-icon icon="email" class="mr-2 has-text-grey-light"></b-icon>
              <a class="mb-2" href="mailto:wokytalk@gmail.com"
                >wokytalk@gmail.com</a
              >
            </div>
          </div>
          <div class="has-text-centered">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSd2EdcY6g_2Cso_WR3l8ieFCK-zYDV75OENUPoYn_kCSTqR0Q/viewform?embedded=true"
              width="100%"
              max-width="640"
              height="860"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              >Loading…</iframe
            >
          </div>
        </div>
        <div class="pt-6" id="about">
          <div
            class="has-text-grey has-text-weight-semibold is-size-3 has-text-centered p-4"
          >
            About
          </div>
          <div class="p-5 is-size-6">
            WokyTalk Digital is a leading digital services provider offering
            software-as-a-service solutions to empower businesses through
            digitalization.

            <b-collapse
              :open="false"
              position="is-bottom"
              aria-id="contentIdForA11y4"
              class="mt-3"
            >
              <template #trigger="props">
                <a
                  aria-controls="contentIdForA11y4"
                  :aria-expanded="props.open"
                >
                  <b-button
                    type="is-light"
                    class="is-size-6"
                    :icon-left="!props.open ? 'chevron-down' : 'chevron-up'"
                  >
                    {{ !props.open ? "Show more" : "Show less" }}</b-button
                  >
                </a>
              </template>
              <p class="mb-3">
                We specialize in enhancing operational efficiency, increasing
                sales volume, and providing comprehensive digital marketing
                solutions. Our custom SaaS platforms streamline key business
                processes, while our data-driven marketing strategies boost
                brand visibility and generate quality leads. <br /><br />

                With a dedicated team and a commitment to client success, we
                help businesses thrive in the digital age by leveraging
                technology to drive growth and maximize potential.
              </p>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <div class="footer has-background-dark">
      <section>
        <div class="has-text-centered has-text-white">
          <div class="mb-3 is-size-6">
            Copyright © 2023 WokyTalk Digital. All rights reserved.
          </div>
          <div class="is-size-7">
            <ul class="is-inline-flex-tablet">
              <li class="as-globalfooter-mini-legal-link" role="listitem">
                <span
                  data-slot-name="footerConfig"
                  data-feature-name="Footer Navigation"
                  data-display-name="Privacy Policy"
                  data-autom="footer"
                  >Privacy Policy</span
                >
              </li>
              <span class="mx-1 is-hidden-mobile"> | </span>
              <li class="as-globalfooter-mini-legal-link" role="listitem">
                <span
                  data-slot-name="footerConfig"
                  data-feature-name="Footer Navigation"
                  data-display-name="Terms of Use"
                  data-autom="footer"
                  >Terms of Use</span
                >
              </li>
              <span class="mx-1 is-hidden-mobile"> | </span>
              <li class="as-globalfooter-mini-legal-link" role="listitem">
                <span
                  data-slot-name="footerConfig"
                  data-feature-name="Footer Navigation"
                  data-display-name="Sales and Refunds"
                  target="new"
                  data-autom="footer"
                  >Sales and Refunds</span
                >
              </li>
              <span class="mx-1 is-hidden-mobile"> | </span>
              <li class="as-globalfooter-mini-legal-link" role="listitem">
                <span
                  data-slot-name="footerConfig"
                  data-feature-name="Footer Navigation"
                  data-display-name="Legal"
                  data-autom="footer"
                  >Legal</span
                >
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "WebPage",
  components: {},
  data() {
    return {
      sellingPoints: [
        {
          id: 1,
          title: "Convenient mobile ordering",
          description:
            "Easy and user-friendly interface for hassle-free ordering with customizable options and special requests.",
          filename: "usp-order.png",
        },
        {
          id: 2,
          title: "Multiple payment options",
          description:
            "Flexible payment methods including cash and upcoming digital payment options.",
          filename: "usp-payment.png",
        },
        {
          id: 3,
          title: "Effective communication",
          description:
            "Smoothly communicate preferences and instructions to ensure accurate order preparation and customer satisfaction.",
          filename: "usp-communication.png",
        },
        {
          id: 4,
          title: "Direct kitchen orders",
          description:
            "Direct transmission of orders to the kitchen for minimized delays and prompt food preparation.",
          filename: "usp-kitchen.png",
        },
        {
          id: 5,
          title: "Reduce paper waste",
          description:
            "Minimize paper usage through digital menus and e-invoices to contribute to environmental sustainability.",
          filename: "usp-paperless.png",
        },
        {
          id: 6,
          title: "Loyalty and marketing integration",
          description:
            "Integrated loyalty programs and marketing features to reward customers and encourage repeat business.",
          filename: "usp-marketing.png",
        },
        {
          id: 7,
          title: "Advanced technology stack",
          description:
            "Utilize Google's powerful solutions for a seamless ordering experience, leveraging their expertise in scalability and reliability.",
          filename: "usp-tech.png",
        },
        {
          id: 8,
          title: "User-friendly for all ages",
          description:
            "Intuitive interface catering to users of all ages, including the elderly, for a pleasant ordering experience.",
          filename: "usp-friendly.png",
        },
      ],
      features: [
        { id: 1, title: "Table Management" },
        { id: 2, title: "Order & Payment Management" },
        { id: 3, title: "QR Self-Ordering & Waiter-Assisted Ordering" },
        { id: 4, title: "Kitchen Display" },
        { id: 5, title: "Waiter Display" },
        { id: 6, title: "Product & Stock Management" },
        { id: 7, title: "Sales Reporting" },
        { id: 8, title: "Digital Invoices" },
        { id: 9, title: "Marketing Features" },
      ],
      testimonials: [
        {
          name: "Madam Wong",
          role: "Restaurant Owner",
          company: "Tasty Bites",
          content:
            "' WokyTalk F&B System simplifies operations, manages menu and orders efficiently. QR self-ordering enhances customer experience, while kitchen display system improves accuracy and speed. Sales reporting and analytics optimize business decisions. A game-changer for time savings and increased profitability. '",
        },
        {
          name: "Maria",
          role: "Head Chef",
          company: "Gourmet Delights",
          content:
            "' WokyTalk F&B System revolutionizes kitchen operations. Seamless integration manages recipes, tracks inventory, and streamlines food preparation. Kitchen display system improves communication, resulting in faster ticket times. Highly recommended for exceptional dishes and outstanding dining experiences. '",
        },
        {
          name: "Steve",
          role: "Restaurant Manager",
          company: "Nanyang Papa",
          content:
            "' WokyTalk F&B System significantly improves operations. Simplifies reservation management, optimizes table turnover, and provides valuable sales insights. Integrated product and stock management effectively tracks inventory. Marketing features drive business growth. Truly grateful for this remarkable product. '",
        },
        {
          name: "Pei Fen",
          role: "Dine-In Customer",
          company: "",
          content:
            "' WokyTalk Ordering with QR makes ordering easy. Quick staff response ensures a smooth dining experience. Digital invoices and streamlined processes make payment and enjoying delicious food a breeze. Highly recommended for a modern and hassle-free dining experience. '",
        },
      ],

      merchantScreenshots: [
        {
          id: 1,
          title: "Table Management",
          description: "...",
          filename: "ss-table.png",
        },
        {
          id: 2,
          title: "Order & Payment Management",
          description: "...",
          filename: "ss-order.png",
        },
        {
          id: 3,
          title: "Waiter-Assisted Ordering",
          description: "...",
          filename: "ss-create-order.png",
        },
        {
          id: 4,
          title: "Kitchen Display",
          description: "...",
          filename: "ss-kitchen.png",
        },
        {
          id: 5,
          title: "Waiter Display",
          description: "...",
          filename: "ss-serve.png",
        },
        {
          id: 6,
          title: "Product & Stock Management",
          description: "...",
          filename: "ss-product.png",
        },
        {
          id: 7,
          title: "Sales Reporting",
          description: "...",
          filename: "ss-reports.png",
        },
        {
          id: 8,
          title: "Settings",
          description: "...",
          filename: "ss-settings.png",
        },
        {
          id: 9,
          title: "Edit Profile",
          description: "...",
          filename: "ss-profile.png",
        },
      ],
      userScreenshots: [
        {
          id: 1,
          title: "Start Order",
          description: "...",
          filename: "ss-start.jpg",
        },
        {
          id: 2,
          title: "Digital Menu",
          description: "...",
          filename: "ss-menu.jpg",
        },
        {
          id: 3,
          title: "Order with Code on Physical Menu",
          description: "...",
          filename: "ss-orderwithcode.jpg",
        },
        {
          id: 4,
          title: "Cart",
          description: "...",
          filename: "ss-cart.jpg",
        },
        {
          id: 5,
          title: "Order Confirmed",
          description: "...",
          filename: "ss-success.jpg",
        },
        {
          id: 6,
          title: "Order History",
          description: "...",
          filename: "ss-order.jpg",
        },
      ],
    };
  },
  computed: {},
  methods: {
    goToLogin() {
      this.$router.push("/merchant/dashboard");
    },

    scrollToSection(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
a.navbar-item {
  border-radius: 0.75rem;
}
a.navbar-item:hover,
a.navbar-item:focus {
  background-color: transparent !important;
}

.navbar-menu.is-active {
  border-radius: 0.75rem;
  padding: 0.75rem 0.5rem;
}

.horizontal-scroll-container {
  position: relative;
  overflow-x: auto;
}
.cards {
  display: inline-flex;
}

.feature-card {
  margin-right: 1rem;
  width: 300px;
  height: auto;
}

.screenshot-card {
  margin-right: 1rem;
  width: 300px;
  height: auto;
}

.mobile-screenshot-card {
  margin-right: 1rem;
  width: 150px;
  height: auto;
}

::-webkit-scrollbar {
  height: 8px !important;
}
::-webkit-scrollbar-track {
  background: #fefefe !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
}
.screenshot-img img {
  border-radius: 0.25rem;
  border: 1px solid #eeeeee;
}
</style>
