<template>
  <div class="has-background-white rounded pt-1 px-2 pb-2">
    <p class="is-size-7 mb-2">Select Category</p>
    <!-- <div class="select-category rounded has-border-grey py-2 px-3">
        Main Courses
      </div> -->

    <b-dropdown aria-role="list" expanded v-if="categories">
      <template #trigger>
        <b-button
          expanded
          :label="selectedCategory.name"
          icon-right="chevron-down"
        />
      </template>

      <b-dropdown-item
        v-for="category in categories"
        :key="category.id"
        aria-role="listitem"
        @click="onSelectCategory(category)"
        >{{ category.name }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "SelectCategory",
  components: {},
  props: {
    categories: Array,
    getItems: { type: Function },
    selectedCategory: { type: Object },
  },
  data() {
    return {};
  },
  created() {
    this.getItems(this.categories[0]);
  },
  methods: {
    onSelectCategory(category) {
      this.getItems(category);
    },
  },
};
</script>
