<template>
  <b-modal
    v-model="isCreateTableModalActive"
    scroll="keep"
    :on-cancel="closeCreateTableModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white">
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-bold is-size-5 mb-5">Create Table</div>
        <b-field label="Table Name" size="is-small">
          <b-input v-model="tableName" required></b-input>
        </b-field>
      </div>
    </div>
    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
      <b-button
        type="is-primary action-btn"
        @click="createTable"
        :disabled="!isAllRequiredFieldCompleted"
        >Confirm</b-button
      >
    </footer>
  </b-modal>
</template>

<script>
export default {
  name: "CreateTable",
  components: {},
  props: {
    isCreateTableModalActiveProp: Boolean,
    closeCreateTableModal: { type: Function },
  },
  data() {
    return {
      tableName: "",
    };
  },
  computed: {
    isCreateTableModalActive: {
      get() {
        return this.isCreateTableModalActiveProp;
      },
      set() {},
    },

    isAllRequiredFieldCompleted() {
      if (this.tableName !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.closeCreateTableModal();
    },
    async createTable() {
      await this.$store.dispatch("merchantModule/createTable", this.tableName);
      this.onCancel();
    },
  },
};
</script>
