<template>
  <section class="responsive-padding" style="padding-right: 1rem !important">
    <div>
      <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">Settings</div>
      </div>

      <div class="py-4 pr-5 settings-container">
        <div style="max-width: 500px">
          <div class="p-4 has-border-grey rounded mb-4">
            <div class="mb-4 has-text-weight-semibold">Payment</div>
            <p class="mb-3 is-size-6">
              Service Charge <b-tag class="ml-1">10%</b-tag>
            </p>
            <b-switch
              v-model="hasServiceCharge"
              type="is-success"
              class="has-text-grey"
            >
              <span class="is-size-6">{{
                hasServiceCharge ? "Enabled" : "Disabled"
              }}</span>
            </b-switch>

            <hr class="my-4" />
            <p class="mb-3 is-size-6">
              Service Tax <b-tag class="ml-1">6%</b-tag>
            </p>
            <b-switch
              v-model="hasServiceTax"
              type="is-success"
              class="has-text-grey"
            >
              <span class="is-size-6">{{
                hasServiceTax ? "Enabled" : "Disabled"
              }}</span>
            </b-switch>

            <!-- <b-button
            type="is-primary"
            class="mt-5"
            :disabled="
              this.hasServiceCharge === this.getHasServiceCharge &&
              this.hasServiceTax === this.getHasServiceTax
            "
            @click="saveSettings"
            >Save Changes</b-button
          > -->
          </div>

          <div class="p-4 has-border-grey rounded">
            <div class="mb-4 has-text-weight-semibold">Marketing</div>
            <p class="mb-1 is-size-6">Membership & Loyalty Program</p>
            <p class="is-size-7 has-text-grey mb-3">
              It is to incentivize customers to keep coming back to purchase by
              offering rewards redeemable with points. <br />
              <br />After enabling this program, customers earn 1 point on every
              $1 spent,
              <br />
              and you can decide which product can be redeemed with points and
              its point value.
            </p>
            <!-- <b-switch
              v-model="isLoyaltyProgramEnabled"
              type="is-success"
              class="has-text-grey"
            >
              <span class="is-size-6">{{
                isLoyaltyProgramEnabled ? "Enabled" : "Disabled"
              }}</span>
            </b-switch> -->

            <b-message
              type="is-light is-warning"
              size="is-small"
              aria-close-label="Close notification"
              :closable="false"
              class="is-size-7 rounded-2"
            >
              This feature is coming soon ...
            </b-message>

            <!-- <b-button
            type="is-primary"
            class="mt-5"
            :disabled="
              this.isLoyaltyProgramEnabled === this.getIsLoyaltyProgramEnabled
            "
            @click="saveSettings"
            >Save Changes</b-button
          > -->
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-button
        class="my-4"
        type="is-primary"
        :disabled="
          this.hasServiceCharge === this.getHasServiceCharge &&
          this.hasServiceTax === this.getHasServiceTax
        "
        @click="saveSettings"
        >Save Changes</b-button
      >
      <!-- && this.isLoyaltyProgramEnabled === this.getIsLoyaltyProgramEnabled -->
    </div>
  </section>
</template>

<script>
export default {
  name: "SettingsPage",
  components: {},
  data() {
    return {
      hasServiceCharge: false,
      hasServiceTax: false,
      //isLoyaltyProgramEnabled: false,
    };
  },

  computed: {
    getHasServiceCharge() {
      return this.$store.getters["merchantModule/getMerchantInfo"]
        .hasServiceCharge;
    },
    getHasServiceTax() {
      return this.$store.getters["merchantModule/getMerchantInfo"]
        .hasServiceTax;
    },
    // getIsLoyaltyProgramEnabled() {
    //   return this.$store.getters["merchantModule/getMerchantInfo"]
    //     .isLoyaltyProgramEnabled;
    // },
  },
  watch: {
    getHasServiceCharge(newValue) {
      this.hasServiceCharge = newValue;
    },
    getHasServiceTax(newValue) {
      this.hasServiceTax = newValue;
    },
    // getIsLoyaltyProgramEnabled(newValue) {
    //   this.isLoyaltyProgramEnabled = newValue;
    // },
  },
  created() {
    this.hasServiceCharge = this.getHasServiceCharge;
    this.hasServiceTax = this.getHasServiceTax;
    //this.isLoyaltyProgramEnabled = this.getIsLoyaltyProgramEnabled;
  },
  methods: {
    saveSettings() {
      this.$store.dispatch("merchantModule/updateSettings", {
        hasServiceCharge: this.hasServiceCharge,
        hasServiceTax: this.hasServiceTax,
        //isLoyaltyProgramEnabled: this.isLoyaltyProgramEnabled,
      });
    },
  },
};
</script>

<style>
.settings-container {
  height: calc(100vh - 210px);
  overflow-y: auto;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
</style>
