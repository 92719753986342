<template>
  <b-modal
    v-model="isManageStockModalActive"
    scroll="keep"
    :on-cancel="closeManageStockModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white">
      <!-- <div class="mb-3 has-text-weight-bold">Add New Item</div> -->
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-bold is-size-5 mb-5">Manage Stock</div>

        <!-- <b-field :label="item.name" size="is-small">
            Variation List with toggle control
          </b-field> -->
        <div class="p-4 rounded has-border-grey">
          <div class="is-flex">
            <div class="is-flex-grow-1 is-size-6">{{ item.name }}</div>

            <b-field class="ml-4">
              <b-switch
                v-model="isItemAvailable"
                type="is-success"
                :left-label="true"
              >
                <span class="has-text-grey is-size-7">
                  {{ !isItemAvailable ? "Out of stock" : "" }}</span
                >
              </b-switch>
            </b-field>
          </div>

          <div
            class="is-size-7 has-text-grey"
            v-if="
              (item.hasVariations || item.hasCollections) && isItemAvailable
            "
          >
            <hr class="my-3" />
            <p class="mb-1">Set your main item as 'Out of stock' if:</p>
            <ul class="mt-0 ml-3">
              <li v-if="item.hasVariations">
                No at least one option available in each variation
              </li>
              <li v-if="item.hasCollections">
                No at least required quantity of option available in each
                collection
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-5">
          <div
            v-if="item.hasVariations && isItemAvailable"
            class="has-fade-in-anim"
          >
            <div class="mb-3 has-text-weight-semibold">Variations</div>
            <div
              v-for="(variation, variationIndex) in variations"
              :key="variationIndex"
              class="variation-container rounded has-border-grey px-4 pt-4 pb-2"
            >
              <p class="has-text-weight-semibold mb-0">
                {{ variation.title }}
              </p>
              <p class="is-size-7 has-text-grey">At least 1 option available</p>
              <div
                v-for="(option, optionIndex) in variation.options"
                :key="optionIndex"
              >
                <!-- TODO: key and option id -->
                <div class="is-flex mb-3">
                  <div class="is-flex-grow-1 is-size-6 py-1">
                    {{ option.name }}
                  </div>
                  <b-field class="ml-4">
                    <b-switch
                      v-model="option.isAvailable"
                      type="is-success"
                      :left-label="true"
                    >
                      <span class="has-text-grey is-size-7">
                        {{ !option.isAvailable ? "Out of stock" : "" }}</span
                      >
                    </b-switch>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <div
            v-if="item.hasCollections && isItemAvailable"
            class="has-fade-in-anim"
          >
            <div class="mb-3 has-text-weight-semibold">Collections</div>
            <div
              v-for="(collection, collectionIndex) in collections"
              :key="collectionIndex"
              class="variation-container rounded has-border-grey px-4 pt-4 pb-2"
            >
              <p class="has-text-weight-semibold mb-0">
                {{ collection.title }}
              </p>
              <p class="is-size-7 has-text-grey">
                At least {{ collection.requiredSelectionQuantity }} options
                available
              </p>
              <div
                v-for="(option, optionIndex) in collection.options"
                :key="optionIndex"
              >
                <!-- TODO: key and option id -->
                <div class="is-flex mb-3">
                  <div class="is-flex-grow-1 is-size-6">
                    {{ option.name }}
                  </div>
                  <b-field class="ml-4">
                    <b-switch
                      v-model="option.isAvailable"
                      type="is-success"
                      :left-label="true"
                    >
                      <span class="has-text-grey is-size-7">
                        {{ !option.isAvailable ? "Out of stock" : "" }}</span
                      >
                    </b-switch>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
      <b-button
        type="is-primary action-btn"
        @click="updateItemStock"
        :disabled="!isChangesValidToSave"
        >Save Changes</b-button
      >
    </footer>
  </b-modal>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';

export default {
  name: "ManageStock",
  components: {},
  props: {
    item: { type: Object, required: true },
    isManageStockModalActiveProp: Boolean,
    closeManageStockModal: { type: Function },
  },
  data() {
    return {
      itemId: this.item.id,
      categoryId: this.item.categoryId,
      isItemAvailable: this.item.isAvailable,
      variations: this.item.variations,
      collections: this.item.collections,
    };
  },
  created() {},
  computed: {
    isManageStockModalActive: {
      get() {
        return this.isManageStockModalActiveProp;
      },
      set() {},
    },

    isAllVariationsAtLeastOneOptionAvailable() {
      if (!this.item.hasVariations) {
        return true;
      } else {
        let availableOptions = [];
        for (let i = 0; i < this.variations.length; i++) {
          availableOptions = this.variations[i].options.filter(
            (item) => item.isAvailable === true
          );
          if (availableOptions.length < 1) {
            return false;
          }
        }

        return true;
      }
    },
    isAllCollectionsRequiredQuantityOptionAvailable() {
      if (!this.item.hasCollections) {
        return true;
      } else {
        let availableOptions = [];
        for (let i = 0; i < this.collections.length; i++) {
          // for (let j = 0; j < this.variations[i].options.length; j++) {
          // if (this.variations[i].options.isAvailable === 0) {
          //   return false;
          // }
          availableOptions = this.collections[i].options.filter(
            (item) => item.isAvailable === true
          );
          if (
            availableOptions.length <
            this.collections[i].requiredSelectionQuantity
          ) {
            return false;
          }
        }

        return true;
      }
    },

    isChangesValidToSave() {
      if (!this.isItemAvailable) {
        return true;
      } else {
        if (
          this.isAllVariationsAtLeastOneOptionAvailable &&
          this.isAllCollectionsRequiredQuantityOptionAvailable
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    // TODO: at least one option available in each variation
    // TODO: at least 'required quantity of selection' options available in each collection

    // isAllRequiredFieldCompleted() {
    //   if (
    //     this.categoryName !== "" &&
    //     this.categoryName !== this.category.name
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.closeManageStockModal();
    },
    async updateItemStock() {
      await this.$store.dispatch("productsModule/updateItemStock", {
        id: this.itemId,
        categoryId: this.categoryId,
        isAvailable: this.isItemAvailable,
        variations: this.variations,
        collections: this.collections,
      });

      this.onCancel();
    },
  },
};
</script>

<style>
.variation-container:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
