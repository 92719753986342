<template>
  <section>
    <div
      v-if="activeOrders?.length === 0"
      class="has-text-centered p-5 rounded has-fade-in-anim has-border-grey"
    >
      <div class="has-text-centered p-5 rounded">
        <b-image
          :src="require('@/assets/illustration/no_data.svg')"
          alt=""
          class="is-128x128 mx-auto mb-3"
        ></b-image>
        <div class="has-text-grey mx-auto">No order yet</div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(order, orderIndex) in activeOrders"
        :key="order.id"
        class="has-border-grey rounded mb-4"
      >
        <div
          class="p-3 has-background-light is-flex"
          style="border-radius: 0.75rem 0.75rem 0 0"
        >
          <div class="is-flex-grow-1 is-flex space-between">
            <!-- <b-checkbox></b-checkbox> -->
            <p
              class="has-text-weight-medium has-text-grey my-auto pr-4"
              type="is-white"
            >
              # {{ orderIndex + 1 }}
            </p>
            <p
              class="is-size-6 my-auto px-3"
              style="border-left: 1px solid #c9c9c9"
            >
              <span class="has-text-grey mr-1">Table</span>
              <span class="has-text-weight-semibold has-letter-spacing-1">
                {{ `${order.tableName}` }}</span
              >
            </p>

            <!-- <span class="is-size-6 ml-2">
                {{ `Table ${order.tableName}` }}</span
              > -->
          </div>

          <p class="is-size-6 my-auto pl-3 has-text-grey">
            {{ formatDateTimestamp(order.createdAt) }}
          </p>
        </div>

        <div>
          <div
            v-for="item in order.items"
            :key="item.id"
            class="columns is-mobile order-item-row px-3 py-2 mb-0"
            :class="item.isKitchenChecked ? 'completed-dish' : ''"
          >
            <div class="column is-narrow p-0">
              <b-checkbox
                v-model="item.isKitchenChecked"
                type="is-success"
                @input="
                  itemOnCheck(order.id, order.items, item, order.tableName)
                "
                :class="item.isKitchenChecked ? '' : 'to-prepare-style'"
              ></b-checkbox>
            </div>

            <div class="column is-narrow pt-1 px-0 pb-0">
              <div
                class="has-text-weight-semibold has-text-centered"
                style="width: 40px !important"
              >
                {{ `${item.quantity}` }}
              </div>
            </div>
            <div class="column is-narrow pt-1 pb-0 pl-0">
              <span class="has-text-weight-semibold has-text-grey-light"
                ><b-icon icon="close" size="is-small"> </b-icon
              ></span>
            </div>

            <div class="column pt-1 px-0 pb-0">
              <div class="columns">
                <div
                  class="column is-two-fifths py-0 pl-0 mb-2 has-text-weight-semibold"
                >
                  {{ item.name }}
                </div>
                <div class="column is-three-fifths p-0">
                  <b-tag
                    v-if="item.serviceMode === 'Takeaway'"
                    icon="shopping-outline"
                    size="is-small"
                    class="mb-1"
                    type="is-warning is-light"
                    style="border: 1px solid #bc9c43"
                    >Takeaway</b-tag
                  >
                  <p>
                    <span
                      v-for="(
                        selectedVariation, selectedVariationIndex
                      ) in item.selectedVariations"
                      :key="selectedVariationIndex"
                    >
                      {{
                        selectedVariation.name +
                        (selectedVariationIndex !==
                        item.selectedVariations?.length - 1
                          ? ", "
                          : "")
                      }}
                    </span>
                  </p>
                  <p>
                    <span
                      v-for="(
                        selectedCollection, selectedCollectionIndex
                      ) in item.selectedCollections"
                      :key="selectedCollectionIndex"
                    >
                      <span
                        v-for="(
                          selection, selectionIndex
                        ) in selectedCollection.selections"
                        :key="selectionIndex"
                        >{{
                          selection.name +
                          (selectionIndex !==
                          selectedCollection.selections?.length - 1
                            ? ", "
                            : "")
                        }} </span
                      ><br />
                    </span>
                  </p>
                  <p
                    v-if="item.specialRemark !== ''"
                    class="has-text-danger mb-0 is-italic"
                  >
                    {{ `" ${item.specialRemark} "` }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ToPrepareList",
  props: {
    activeOrders: Array,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    formatDateTimestamp(timestamp) {
      var date = new Date(timestamp.seconds * 1000);

      // Get the day and pad it with leading zeros if necessary
      const day = date.getDate().toString().padStart(2, "0");

      // Get the month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];

      // Get the year
      const year = date.getFullYear();

      // Get the hours and minutes and format them as a string in 12-hour format with am/pm
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amPm = hours < 12 ? "am" : "pm";
      hours = hours % 12 || 12;

      // Combine the date components into a formatted string
      const formattedDate = `${day} ${month} ${year}, ${hours}.${minutes} ${amPm}`;

      return formattedDate;
    },

    async itemOnCheck(orderId, items, itemOnCheck, tableName) {
      let updatedData = {
        orderId: orderId,
        items: items,
      };

      await this.$store.dispatch(
        "merchantOrdersModule/updateItemKitchenCheckStatus",
        updatedData
      );

      // TODO if check/uncheck, add/remove

      if (itemOnCheck.isKitchenChecked === true) {
        let logData = {
          id: itemOnCheck.id,
          name: itemOnCheck.name,
          selectedVariations: itemOnCheck.selectedVariations,
          selectedCollections: itemOnCheck.selectedCollections,
          quantity: itemOnCheck.quantity,
          specialRemark: itemOnCheck.specialRemark,
          productId: itemOnCheck.productId,
          merchantId: itemOnCheck.merchantId,

          serviceMode: itemOnCheck.serviceMode,

          tableName: tableName,
          orderId: orderId,
        };

        await this.$store.dispatch("toServeLogsModule/addToServeLog", logData);
      } else {
        await this.$store.dispatch(
          "toServeLogsModule/removeToServeLog",
          itemOnCheck.id
        );
      }

      // TODO add/remove to serve log
    },
  },
};
</script>

<style>
.completed-dish {
  filter: opacity(0.5);
  background-color: #f5f5f5;
  /* text-decoration: line-through; */
}

.to-prepare-style span {
  border-color: #f14668 !important;
}
</style>
