<template>
  <section class="has-background-light mx-auto" style="height: 100vh">
    <div class="mx-auto" style="width: 100%; max-width: 500px">
      <div class="has-background-light" style="max-width: 500px">
        <div
          class="p-2 is-flex has-background-white"
          style="
            position: fixed;
            width: 100%;
            max-width: 500px;
            z-index: 1;
            border-bottom: 1px solid #dcdcdc;
          "
        >
          <div class="is-flex-grow-1 p-2">
            <div class="has-text-weight-semibold has-text-centered">
              My Invoice
            </div>
          </div>
        </div>
        <div style="padding-top: 60px !important">
          <div>
            <InvoiceItem v-if="!isOrderLoading" />

            <div v-else class="p-3">
              <OrderSkeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InvoiceItem from "@/components/User/MyInvoice/InvoiceItem.vue";
import OrderSkeleton from "@/components/User/MyOrders/OrderSkeleton.vue";

export default {
  name: "MyInvoice",
  components: { InvoiceItem, OrderSkeleton },
  data() {
    return {
      isOrderLoading: true,
    };
  },
  computed: {},
  async created() {
    await this.$store.dispatch(
      "userInvoiceModule/retrieveMerchantInfo",
      this.$route.params.merchantId
    );
    await this.$store.dispatch(
      "userInvoiceModule/retrieveInvoice",
      this.$route.params.invoiceId
    );
    setTimeout(async () => {
      this.isOrderLoading = false;
    }, 800);
  },
  methods: {},
};
</script>

<style>
.order-list-container {
  overflow-y: auto !important;
  max-height: calc(100vh - 117px);
  scroll-behavior: smooth;
}

nav.tabs {
  max-width: 500px;
}
</style>
