<template>
  <section>
    <div>
      <div v-if="categories">
        <b-tabs
          size="is-small"
          class="block"
          @input="onSelectCategory"
          v-model="activeTab"
        >
          <b-tab-item
            v-for="category in categories"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          ></b-tab-item>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CategoryList",
  props: {
    categories: Array,
    getItems: { type: Function },
    selectedCategory: { type: Object },
  },
  data() {
    return {
      activeTab: this.selectedCategory.id,
    };
  },
  watch: {
    selectedCategory(newValue) {
      this.activeTab = newValue.id;
    },
  },

  created() {
    this.getItems(this.categories[0]);
  },
  updated() {
    this.activeTab = this.selectedCategory.id;
  },
  methods: {
    onSelectCategory(categoryId) {
      let targetIndex = this.categories.findIndex(
        (item) => item.id === categoryId
      );
      this.getItems(this.categories[targetIndex]);
    },
  },
};
</script>

<style>
nav.tabs.is-small {
  width: calc(100vw - 40px);
}
</style>
