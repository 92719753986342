import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import MerchantSignUp from "../views/Merchant/Auth/SignUp.vue";
import MerchantLogin from "../views/Merchant/Auth/Login.vue";

import DashboardPage from "../views/Merchant/DashboardPage.vue";
import TableDetailsPage from "../views/Merchant/TableDetailsPage.vue";
import CreateOrder from "../views/Merchant/CreateOrder.vue";
import ProductManagement from "../views/Merchant/ProductManagement.vue";
import KitchenDisplay from "../views/Merchant/KitchenDisplay.vue";
import ToServe from "../views/Merchant/ToServe.vue";
import InvoicesPage from "../views/Merchant/InvoicesPage.vue";
import SalesReports from "../views/Merchant/SalesReports.vue";
import SettingsPage from "../views/Merchant/SettingsPage.vue";

import EditProfile from "../views/Merchant/EditProfile.vue";

import UserLogin from "../views/User/Auth/Login.vue";
import MerchantMenu from "../views/User/MerchantMenu.vue";
import CartView from "../views/User/CartView.vue";

import PlaceOrderSuccessView from "../views/User/PlaceOrderSuccessView.vue";
import MyOrders from "../views/User/MyOrders.vue";
// import DownloadInvoices from "../views/User/DownloadInvoices.vue";
import MyInvoice from "../views/User/MyInvoice.vue";

import WebPage from "@/views/Main/WebPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "WebPage",
    component: WebPage,
  },
  {
    path: "/merchant/sign-up-by-admin",
    name: "MerchantSignUp",
    component: MerchantSignUp,
  },
  {
    path: "/merchant/login",
    name: "MerchantLogin",
    component: MerchantLogin,
  },

  {
    path: "/merchant",
    meta: {
      requiresMerchantAuth: true,
    },
    component: () => import("@/layouts/MerchantLayout.vue"),
    children: [
      {
        path: "/merchant/dashboard",
        name: "DashboardPage",
        component: DashboardPage,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/dashboard/:tableName",
        name: "TableDetailsPage",
        component: TableDetailsPage,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/create-order",
        name: "CreateOrder",
        component: CreateOrder,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/products",
        name: "ProductManagement",
        component: ProductManagement,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/kitchen",
        name: "KitchenDisplay",
        component: KitchenDisplay,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/to-serve",
        name: "ToServe",
        component: ToServe,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/invoices",
        name: "InvoicesPage",
        component: InvoicesPage,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/sales-reports",
        name: "SalesReports",
        component: SalesReports,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/settings",
        name: "SettingsPage",
        component: SettingsPage,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/merchant/edit-profile",
        name: "EditProfile",
        component: EditProfile,
        meta: {
          requiresMerchantAuth: true,
        },
      },
      {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
      // {
      //   path: ":merchantId/:id/onboard",
      //   name: "CustomerOnboard",
      //   component: CustomerOnboard,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },

      // {
      //   path: "/products",
      //   name: "Products",
      //   component: Products,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
    ],

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/user/login",
    name: "UserLogin",
    component: UserLogin,
  },
  {
    path: "/user/menu",
    meta: {
      requiresUserAuth: true,
    },
    component: () => import("@/layouts/UserLayout.vue"),
    children: [
      {
        path: "/user/menu/:merchantId",
        name: "MerchantMenu",
        component: MerchantMenu,
        meta: { requiresUserAuth: true },
      },
      {
        path: "/user/menu/:merchantId/cart",
        name: "CartView",
        component: CartView,
        meta: { requiresUserAuth: true },
      },
      {
        path: "/user/menu/:merchantId/order-confirmed",
        name: "PlaceOrderSuccessView",
        component: PlaceOrderSuccessView,
        meta: { requiresUserAuth: true },
      },
      {
        path: "/user/menu/:merchantId/orders",
        name: "MyOrders",
        component: MyOrders,
        meta: { requiresUserAuth: true },
      },
      // {
      //   path: "/user/menu/:merchantId/completed-orders",
      //   name: "DownloadInvoices",
      //   component: DownloadInvoices,
      //   meta: { requiresUserAuth: false },
      // },
    ],
  },
  {
    path: "/invoice/:merchantId/:invoiceId",
    name: "MyInvoice",
    component: MyInvoice,
    meta: {
      //requiresUserAuth: false
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  // console.log("merchant/user auth currentUser", auth);

  // check for Merchant Auth
  if (to.matched.some((record) => record.meta.requiresMerchantAuth)) {
    onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        // proceed to route
        if (user.email !== null) {
          next();
        } else {
          next({
            path: "/merchant/login",
            query: {
              redirect: to.fullPath,
            },
          });
        }

        // ...
      } else {
        // User is signed out
        // ...
        // go to login page
        next({
          path: "/merchant/login",
          query: {
            redirect: to.fullPath,
          },
        });
      }
    });

    // if (!user) {
    //   // go to login page
    //   next({
    //     path: "/merchant/login",
    //     query: {
    //       redirect: to.fullPath,
    //     },
    //   });
    // } else {
    //   // proceed to route
    //   next();
    // }

    // check for User Auth
  } else if (to.matched.some((record) => record.meta.requiresUserAuth)) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        // proceed to route
        if ((user.uid !== null, user.accessToken !== null)) {
          next();
        } else {
          next({
            path: "/user/login",
            query: {
              ...to.query,
              merchantId: to.params.merchantId,
            },
          });
        }
        // ...
      } else {
        // User is signed out
        // ...
        // go to login page
        next({
          path: "/user/login",
          query: {
            ...to.query,
            merchantId: to.params.merchantId,
          },
        });
      }
    });
  }
  // else if(to.matched.some(record => record.meta.requiresGuest)){
  //   // check if not logged in
  //   if(firebase.auth().currentUser){
  //     // go to login page
  //     next({
  //       path: '/',
  //       query: {
  //         redirect: to.fullPath
  //       }
  //     });
  //   }else{
  //     // proceed to route
  //     next();
  //   }
  // }
  else {
    // proceed to route
    next();
  }
});

export default router;
