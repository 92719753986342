<template>
  <section class="responsive-padding">
    <div>
      <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">Dashboard</div>

        <div class="ml-auto">
          <b-button
            class="is-secondary-btn is-hidden-tablet"
            icon-left="plus"
            @click="createTable"
          >
          </b-button>
          <b-button
            class="is-secondary-btn is-hidden-mobile"
            icon-left="plus"
            label="Create Table"
            @click="createTable"
          >
          </b-button>

          <!-- <b-dropdown
            ref="settingsDropdown"
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            trap-focus
          >
            <template #trigger>
              <b-button class="is-hidden-tablet" icon-left="cog"> </b-button>
              <b-button
                class="is-hidden-mobile"
                icon-left="cog"
                label="Settings"
              >
              </b-button>
            </template>

            <b-dropdown-item
              aria-role="menu-item"
              :focusable="false"
              custom
              paddingless
            >
              <div class="is-size-5 p-2 has-text-weight-semibold">Settings</div>
              <div class="p-2">
                <p class="mb-2 has-text-weight-medium">Quantity of Table</p>
                <b-field class="mb-3">
                  <b-numberinput
                    v-model="quantityOfTable"
                    min="1"
                    max="100"
                    type="is-light"
                  ></b-numberinput>
                </b-field>
              </div>
              <div class="is-flex m-2">
                <b-button
                  label="Cancel"
                  size="is-small"
                  class="mr-3"
                  @click="closeSettingsDropdown"
                />
                <b-button
                  label="Save Changes"
                  size="is-small"
                  type="is-primary"
                  @click="updateQuantityOfTable"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown> -->
        </div>
      </div>

      <CreateTable
        v-if="isCreatingTable"
        :isCreateTableModalActiveProp="isCreatingTable"
        :closeCreateTableModal="closeCreateTableModal"
      />

      <div class="table-list-container">
        <TableList v-if="!isLoading" :TablesOrdersData="TablesOrdersData" />

        <div
          v-else
          class="columns is-variable is-2-mobile is-2-tablet is-3-desktop is-mobile is-multiline"
        >
          <div
            class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd"
            v-for="i in 24"
            :key="i"
          >
            <b-skeleton size="is-large" height="180" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CreateTable from "@/components/Merchant/Dashboard/CreateTable.vue";
import TableList from "@/components/Merchant/Dashboard/TableList.vue";

export default {
  name: "DashboardPage",
  components: { CreateTable, TableList },
  data() {
    return {
      isCreatingTable: false,
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;

    setTimeout(async () => {
      this.isLoading = false;
    }, 500);
  },
  computed: {
    TablesOrdersData() {
      const ordersDataByTable =
        this.$store.getters["merchantOrdersModule/getTablesOrdersData"];

      let sortedOrdersDataByTable = ordersDataByTable.sort((a, b) =>
        a.tableName.localeCompare(b.tableName, undefined, { numeric: true })
      );
      return sortedOrdersDataByTable;
    },
  },
  watch: {},
  created() {},
  methods: {
    createTable() {
      this.isCreatingTable = true;
    },
    closeCreateTableModal() {
      this.isCreatingTable = false;
    },
  },
};
</script>

<style>
.table-list-container {
  height: calc(100vh - 170px);
  overflow-y: auto;
  padding-right: 0.75rem;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
</style>
