<template>
  <!-- TODO: remove uploaded image but unsaved item image when navigate away.. -->
  <b-modal
    v-model="isAddItemModalActive"
    scroll="keep"
    :on-cancel="closeAddItemModal"
    :can-cancel="['escape', 'outside']"
  >
    <div class="has-background-white">
      <div class="p-5 customized-modal-content">
        <div class="has-text-weight-bold is-size-5 mb-5">Add Item</div>

        <b-field label="Name" size="is-small">
          <b-input v-model="itemName" required></b-input>
        </b-field>

        <b-field label="Description" size="is-small">
          <b-input v-model="itemDescription"></b-input>
        </b-field>

        <b-field label="Image">
          <b-upload
            v-if="itemImage?.length === 0"
            v-model="itemImage"
            drag-drop
            accept=".png, .jpg, .jpeg"
            @input="onFileChange"
          >
            <section class="section" style="width: 100%">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Drop your files here or click to upload</p>
              </div>
            </section>
          </b-upload>
          <div v-else>
            <b-image
              :src="imagePreviewSrc"
              alt="product-image"
              class="m-0 product-image-preview"
              :responsive="false"
              :class="isUploadingImage ? 'img-loading' : ''"
            ></b-image>
          </div>
        </b-field>

        <div v-if="imageURL === '' && isUploadingImage" class="mb-5">
          <b-progress
            :value="uploadProgress"
            format="percent"
            show-value
            type="is-success"
            >Uploading {{ uploadProgress }}%</b-progress
          >
        </div>
        <div v-else class="tags">
          <span class="tag is-primary is-light" v-if="itemImage.name">
            {{ itemImage.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile()"
            ></button>
          </span>
        </div>

        <b-field label="Base Price (RM)">
          <b-input
            v-model="itemBasePrice"
            placeholder=""
            type="number"
            step=".01"
          >
          </b-input>
        </b-field>

        <b-field label="Base Price Discount (%)" class="mb-3">
          <b-switch type="is-success" v-model="hasBasePriceDiscount"
            >{{ hasBasePriceDiscount ? "Enabled" : "Disabled" }}
          </b-switch>
        </b-field>
        <b-field>
          <b-numberinput
            v-if="hasBasePriceDiscount"
            v-model="basePriceDiscountPercentage"
            min="0"
            max="100"
            type="is-light"
          >
          </b-numberinput>
        </b-field>

        <div class="has-text-weight-semibold has-text-dark">Item Code</div>
        <p class="has-text-grey is-size-7 mb-2">
          For customers who prefer a physical menu to place order by code
        </p>
        <b-field>
          <b-input
            v-model="itemCode"
            placeholder="Example: A001"
            @input="toUpperCase"
          ></b-input>
        </b-field>

        <b-field label="Display on menu">
          <b-switch type="is-success" v-model="isDisplayOnMenu"
            >{{ isDisplayOnMenu ? "Enabled" : "Disabled" }}
          </b-switch></b-field
        >
      </div>
      <hr class="my-0" />
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-semibold has-text-dark">Variations</div>
        <p class="has-text-grey is-size-7 mb-4">
          It is usually for standard attributes. In each variation, customers
          are required to select only one option.
        </p>
        <b-switch
          class="mb-4 has-text-grey"
          type="is-success"
          v-model="hasVariations"
          @input="clearVariations"
          >{{ hasVariations ? "Enabled" : "Disabled" }}</b-switch
        >

        <ItemVariations v-if="hasVariations" :variationsProp="variations" />
      </div>

      <hr class="my-0" />
      <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-semibold has-text-dark">Collections</div>
        <p class="has-text-grey is-size-7 mb-4">
          It is usually for free combination. In each collection, customers are
          required to select a specified number of options.
        </p>
        <b-switch
          class="mb-4 has-text-grey"
          type="is-success"
          v-model="hasCollections"
          @input="clearCollections"
          >{{ hasCollections ? "Enabled" : "Disabled" }}</b-switch
        >

        <ItemCollections v-if="hasCollections" :collectionsProp="collections" />
      </div>

      <!-- TODO: add on feature-->
      <hr class="my-0" v-if="addOnDesignConfirmed" />
      <div
        class="content p-5 customized-modal-content"
        v-if="addOnDesignConfirmed"
      >
        <div class="has-text-weight-semibold has-text-dark">Add-Ons</div>
        <p class="has-text-grey is-size-7 mb-4">
          It is usually for upsells or cross-sells to increase the average order
          value. Customers can optionally add multiple additional items.
        </p>
        <b-switch
          class="mb-4 has-text-grey"
          type="is-success"
          v-model="hasAddOns"
          @input="clearAddOns"
          >{{ hasAddOns ? "Enabled" : "Disabled" }}</b-switch
        >

        <ItemAddOns v-if="hasAddOns" :addOnsProp="addOns" />
      </div>
      <!-- end here -->

      <hr class="my-0" />
      <!-- <div class="content p-5 customized-modal-content">
        <div class="has-text-weight-semibold has-text-dark">
          Membership & Loyalty Program
        </div>
        <p class="has-text-grey is-size-7 mb-4">
          Decide whether this product can be redeemed with points and its point
          value. Learn more in <a @click="goToSettings">Settings</a>.
        </p>
        <div v-if="isLoyaltyProgramEnabled">
          <b-switch
            class="mb-5 has-text-grey"
            type="is-success"
            v-model="isPointRedeemable"
           
            >{{
              isPointRedeemable
                ? "Redeemable with points"
                : "Not redeemable with points"
            }}</b-switch
          >
          <div v-if="isPointRedeemable">
            <p class="mb-0">Point Value</p>
            <p class="is-size-7 has-text-grey mb-2">
              Reminder: Customers earn 1 point on every $1 spent
            </p>
            <b-field>
              <b-numberinput
                v-model="pointValue"
                :min="1"
                type="is-light"
              ></b-numberinput>
            </b-field>
          </div>
        </div>
        <b-notification v-else size="is-small" :closable="false" class="mb-4">
          <b-icon icon="alert-circle-outline" size="is-small" class="mr-2">
          </b-icon>
          <span class="is-size-7"
            >To use this feature, you need to enable this program in
            <a @click="goToSettings">Settings</a> .</span
          >
        </b-notification>
      </div> -->

      <ItemPointRedemption
        :isPointRedeemable.sync="isPointRedeemable"
        :pointValue.sync="pointValue"
      />
    </div>

    <footer
      class="modal-card-foot is-justify-content-left customized-modal-actions"
    >
      <b-button class="mr-3 action-btn" @click="onCancel">Cancel</b-button>
      <b-button
        type="is-primary action-btn"
        @click="addProduct"
        :disabled="!isAllRequiredFieldCompleted || isUploadingImage"
        >Confirm</b-button
      >
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </b-modal>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';
import ItemVariations from "@/components/Merchant/ProductManagement/ItemVariations.vue";
import ItemCollections from "@/components/Merchant/ProductManagement/ItemCollections.vue";
import ItemAddOns from "@/components/Merchant/ProductManagement/ItemAddOns.vue";
import ItemPointRedemption from "@/components/Merchant/ProductManagement/ItemPointRedemption.vue";

export default {
  name: "AddItem",
  components: {
    ItemVariations,
    ItemCollections,
    ItemAddOns,
    ItemPointRedemption,
  },
  props: {
    isAddItemModalActiveProp: Boolean,
    closeAddItemModal: { type: Function },
    category: Object,
  },
  data() {
    return {
      itemName: "",
      itemDescription: "",
      itemBasePrice: undefined,
      hasBasePriceDiscount: false,
      basePriceDiscountPercentage: 0,
      itemImage: [],
      itemCode: "",
      isDisplayOnMenu: true,

      hasVariations: false,
      variations: [],

      hasCollections: false,
      collections: [],

      hasAddOns: false,
      addOns: [],

      isPointRedeemable: false,
      pointValue: 1,

      isLoading: false,
      imagePreviewSrc: "",

      addOnDesignConfirmed: false,
    };
  },
  computed: {
    isAddItemModalActive: {
      get() {
        return this.isAddItemModalActiveProp;
      },
      set() {},
    },

    isUploadingImage() {
      return this.$store.getters["productImagesModule/getIsUploadingImage"];
    },
    uploadProgress() {
      return Math.round(
        this.$store.getters["productImagesModule/getUploadProgress"]
      );
    },
    imageURL() {
      return this.$store.getters["productImagesModule/getImageURL"];
    },
    imageFullPath() {
      return this.$store.getters["productImagesModule/getImageFullPath"];
    },

    isVariationsValid() {
      if (!this.hasVariations) {
        return true;
      } else {
        if (this.variations.length !== 0) {
          for (let i = 0; i < this.variations.length; i++) {
            if (this.variations[i].options.length < 2) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }
    },
    isCollectionsValid() {
      if (!this.hasCollections) {
        return true;
      } else {
        if (this.collections.length !== 0) {
          for (let i = 0; i < this.collections.length; i++) {
            if (
              this.collections[i].options.length < 2 ||
              this.collections[i].requiredSelectionQuantity === undefined ||
              this.collections[i].requiredSelectionQuantity === null ||
              this.collections[i].requiredSelectionQuantity === "" ||
              this.collections[i].requiredSelectionQuantity < 1 ||
              this.collections[i].requiredSelectionQuantity >=
                this.collections[i].options.length
            ) {
              return false;
            }
          }
          //
          return true;
        } else {
          return false;
        }
      }
    },
    isAllRequiredFieldCompleted() {
      let generalInputCompleted =
        this.itemName !== "" && this.itemBasePrice !== undefined;

      //&&
      //this.itemImage instanceof File

      if (generalInputCompleted) {
        if (!this.isVariationsValid || !this.isCollectionsValid) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
  },

  created() {},
  methods: {
    // Remove uploaded image, reset ui and store data
    deleteDropFile() {
      this.itemImage = [];
      this.imagePreviewSrc = "";

      if (this.imageFullPath !== "") {
        this.$store.dispatch(
          "productImagesModule/deleteImage",
          this.imageFullPath
        );
      }
      this.$store.dispatch("productImagesModule/clearAll");
    },
    // Reset ui and store data only
    resetDropFile() {
      this.itemImage = [];
      this.imagePreviewSrc = "";
      this.$store.dispatch("productImagesModule/clearAll");
    },

    clearVariations() {
      this.variations = [];
    },
    clearCollections() {
      this.collections = [];
    },
    clearAddOns() {
      this.addOns = [];
    },

    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onCancel() {
      this.reset();
      this.deleteDropFile();
      this.closeAddItemModal();
    },

    toUpperCase() {
      this.itemCode = this.itemCode.toUpperCase();
    },

    onFileChange() {
      if (!(this.itemImage instanceof Blob)) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const width = img.width;
          const height = img.height;
          const dimension = Math.min(width, height);
          const offsetX = (width - dimension) / 2;
          const offsetY = (height - dimension) / 2;

          canvas.width = 1080;
          canvas.height = 1080;

          const scale = 1080 / dimension;
          const destWidth = dimension * scale;
          const destHeight = dimension * scale;
          const destX = (1080 - destWidth) / 2;
          const destY = (1080 - destHeight) / 2;

          ctx.drawImage(
            img,
            offsetX,
            offsetY,
            dimension,
            dimension,
            destX,
            destY,
            destWidth,
            destHeight
          );

          const dataURL = canvas.toDataURL();

          this.imagePreviewSrc = dataURL;
          await this.uploadProductImage(canvas, this.itemImage.name);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(this.itemImage);
    },

    async uploadProductImage(canvas, fileName) {
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg", 0.8)
      );
      const file = new File([blob], fileName.replace(/\.[^.]+$/, ""), {
        type: "image/jpeg",
      });
      await this.$store.dispatch("productImagesModule/uploadImage", file);
    },

    async addProduct() {
      this.isLoading = true;
      // TODO: upload image and return image url, pass url to data

      await this.$store.dispatch("productsModule/addItem", {
        // TODO
        name: this.itemName,
        description: this.itemDescription,
        basePrice: Number(this.itemBasePrice).toFixed(2),
        hasBasePriceDiscount: this.hasBasePriceDiscount,
        basePriceDiscountPercentage: this.basePriceDiscountPercentage,
        //   image: this.itemImage,
        imageURL: this.imageURL ? this.imageURL : "",
        imageFullPath: this.imageFullPath,
        code: this.itemCode,
        isDisplayOnMenu: this.isDisplayOnMenu,
        isAvailable: true,
        hasVariations: this.hasVariations,
        variations: this.variations,
        hasCollections: this.hasCollections,
        collections: this.collections,
        categoryId: this.category.id,

        isPointRedeemable: this.isPointRedeemable,
        pointValue: this.pointValue,
      });

      this.isLoading = false;

      this.reset();
      this.resetDropFile();
      this.closeAddItemModal();
    },
  },
};
</script>

<style>
.product-image-preview img {
  height: 195px !important;
  border-radius: 0.5rem;
}

.img-loading img {
  filter: opacity(0.5);
}

button.delete.is-small {
  background: hsl(243.08deg 100% 69.41%);
}
</style>
