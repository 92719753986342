import { db } from "@/firebase";
//import { collection, addDoc } from "firebase/firestore";
import { setDoc, doc, getDocs } from "firebase/firestore";
import { collection, query, where } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

import { getAuth } from "firebase/auth";

import { ToastProgrammatic as Toast } from "buefy";

// import {
//   createUserWithEmailAndPassword,
//   //   signInWithEmailAndPassword,
//   //   signOut,
// } from "firebase/auth";

const state = {
  //currentUser: currentUser,
  merchantInfo: {},
};

const getters = {
  getMerchantInfo: (state) => state.merchantInfo,
  getMerchantId: (state) => state.merchantInfo.id,
  getMerchantTableNames: (state) => state.merchantInfo.tableNames,
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
    //Log out the user state
  },
  // addMerchantInfo(state, merchant) {
  //   state.merchantInfo = {
  //     id: merchant.id,
  //     name: merchant.name,
  //     contact: merchant.contact,
  //     email: merchant.email,
  //     timestamp: new Date(),
  //   };
  // },

  retrieveMerchantInfo(state, merchantInfo) {
    state.merchantInfo = merchantInfo;
  },

  createTable(state, tableName) {
    state.merchantInfo.tableNames.push(tableName);
  },
  removeTable(state, tableName) {
    // TODO

    var targetIndex = state.merchantInfo.tableNames.findIndex(
      (x) => x === tableName
    );
    if (targetIndex >= 0) {
      state.merchantInfo.tableNames.splice(targetIndex, 1);
    }
  },

  updateSettings(state, settings) {
    state.merchantInfo.hasServiceCharge = settings.hasServiceCharge;
    state.merchantInfo.hasServiceTax = settings.hasServiceTax;
    state.merchantInfo.isLoyaltyProgramEnabled =
      settings.isLoyaltyProgramEnabled;
  },

  updateProfile(state, data) {
    state.merchantInfo = { ...state.merchantInfo, ...data };
  },

  updateMerchantInfo(state, merchantInfo) {
    state.merchantInfo = merchantInfo;
  },
};

const actions = {
  // async signup(context, { email, password, name }) {
  //   const response = await createUserWithEmailAndPassword(
  //     auth,
  //     email,
  //     password
  //   );
  //   if (response) {
  //     context.commit("setUser", response.user);
  //     response.user.updateProfile({ displayName: name });
  //   } else {
  //     throw new Error("Failed to Sign Up");
  //   }
  // },

  // TODO: Add initRealtimeListeners
  // initRealtimeListeners(context) {
  //   const merchantUid = firebase.auth().currentUser.uid;

  //   db.collection("Merchants")
  //     .doc(merchantUid)
  //     .collection("orderGroups")
  //     .onSnapshot((snapshot) => {
  //       snapshot.docChanges().forEach((change) => {
  //         if (change.type === "added") {
  //           console.log("Added", change.doc.data());
  //           const source = change.doc.metadata.hasPendingWrites
  //             ? "Local"
  //             : "Server";

  //           if (source === "Server") {
  //             context.commit("addMerchantInfo", {
  //               id: change.doc.id,
  //               name: change.doc.data().name,
  //               contact: change.doc.data().contact,
  //               email: change.doc.data().email,
  //               timestamp: change.doc.data().timestamp,
  //             });
  //           }
  //         }
  //         if (change.type === "modified") {
  //           console.log("Updated", change.doc.data());
  //         }
  //         if (change.type === "removed") {
  //           console.log("Removed", change.doc.data());
  //         }
  //       });
  //     });
  // },

  async addMerchantInfo(context, merchant) {
    const todayDate = new Date();
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    const merchantId =
      "M" +
      todayDate.getFullYear().toString().slice(-2) +
      (todayDate.getMonth() + 1).toString().padStart(2, "0") +
      todayDate.getDate().toString().padStart(2, "0") +
      randomCode;

    try {
      const docRef = await setDoc(doc(db, "Merchants", merchantId.toString()), {
        id: merchantId,
        name: merchant.name,
        contact: merchant.contact,
        email: merchant.email,
        TableNames: [],
        hasServiceCharge: false,
        hasServiceTax: false,
        createdAt: new Date(),
        isLoyaltyProgramEnabled: false,
      });
      console.log("Document written with ID: ", docRef.id);

      // TODO: No need commit ba?
      // context.commit("addMerchantInfo", {
      //   id: merchantId,
      //   name: merchant.name,
      //   contact: merchant.contact,
      //   email: merchant.email,
      // });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  async retrieveMerchantInfo(context) {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    try {
      const merchantsRef = collection(db, "Merchants");

      const q = query(merchantsRef, where("email", "==", currentUser.email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        context.commit("retrieveMerchantInfo", doc.data());
      });
    } catch (e) {
      console.error("Error getting merchant info: ", e);
    }
  },

  async createTable({ commit, state }, tableName) {
    const merchantInfoRef = doc(db, "Merchants", state.merchantInfo.id);
    // TODO: if no exist yet, allow
    if (!state.merchantInfo?.tableNames?.includes(tableName)) {
      await updateDoc(
        merchantInfoRef,
        { tableNames: arrayUnion(tableName) },
        { merge: true }
      )
        .then(() => {
          Toast.open("Table has been created successfully ✅");
          commit("createTable", tableName);
        })
        .catch((e) => {
          console.error("Error updating document: ", e);
          Toast.open("Something went wrong, please try again ⚠️");
        });
    } else {
      Toast.open({
        duration: 3000,
        message: `A table named ${tableName} already exists, please create a table with a different name`,
      });
    }
  },

  async removeTable({ commit, state }, tableName) {
    const merchantInfoRef = doc(db, "Merchants", state.merchantInfo.id);
    // TODO: if no exist yet, allow

    await updateDoc(
      merchantInfoRef,
      { tableNames: arrayRemove(tableName) },
      { merge: true }
    )
      .then(() => {
        Toast.open("Table has been removed successfully ✅");
        commit("removeTable", tableName);
      })
      .catch((e) => {
        console.error("Error updating document: ", e);
        Toast.open("Something went wrong, please try again ⚠️");
      });
  },

  async updateSettings({ commit, state }, settings) {
    const merchantInfoRef = doc(db, "Merchants", state.merchantInfo.id);
    await setDoc(
      merchantInfoRef,
      {
        hasServiceCharge: settings.hasServiceCharge,
        hasServiceTax: settings.hasServiceTax,
        isLoyaltyProgramEnabled: settings.isLoyaltyProgramEnabled,
      },
      { merge: true }
    )
      .then(() => {
        Toast.open("Settings have been updated successfully ✅");
        commit("updateSettings", settings);
      })
      .catch((e) => {
        console.error("Error setting document: ", e);
        Toast.open("Something went wrong, please try again ⚠️");
      });
  },

  async updateProfile({ commit, state }, data) {
    const merchantInfoRef = doc(db, "Merchants", state.merchantInfo.id);
    await setDoc(merchantInfoRef, data, { merge: true })
      .then(() => {
        Toast.open("Profile have been updated successfully ✅");
        commit("updateProfile", data);
      })
      .catch((e) => {
        console.error("Error setting document: ", e);
        Toast.open("Something went wrong, please try again ⚠️");
      });
  },

  async initMerchantInfoRealtimeListeners({ commit, state }) {
    const merchantInfoRef = doc(db, "Merchants", state.merchantInfo.id);

    // const unsub =
    onSnapshot(merchantInfoRef, (doc) => {
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";

      if (source === "Server") {
        commit("updateMerchantInfo", doc.data());
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
