<template>
  <section class="has-background-light" style="height: 100vh">
    <div
      class="has-background-white py-2 px-3"
      style="
        position: fixed;
        top: 0;
        z-index: 1;
        width: 100%;
        border-bottom: 1px solid #dbdbdb;
      "
    >
      <!-- <div class="is-size-7 has-text-grey">Powered by</div> -->
      <div class="mx-auto has-text-centered" style="max-width: 400px">
        <img
          alt="WokyTalk"
          src="@/assets/text-logo.png"
          class="rd-logo mt-2"
          style="
            height: 40px !important;
            width: auto !important;
            image-rendering: -webkit-optimize-contrast;
          "
        />
      </div>
    </div>

    <div class="mx-auto p-5" style="max-width: 400px; margin-top: 70px">
      <b-notification
        v-if="isCodeSent === true && !isSendingCode"
        :closable="false"
        class="has-text-centered mb-4 has-fade-in-anim"
        type="is-dark"
        ><b-icon icon="check-circle" type="is-success" class="mr-2"> </b-icon>
        Verification code has been sent to +60 {{ this.userMobileNo.value }}
      </b-notification>
      <b-notification
        v-if="isCodeSent === false && !isSendingCode"
        :closable="false"
        class="has-text-centered mb-4 has-fade-in-anim"
        type="is-danger is-light"
        ><b-icon icon="alert-circle" type="is-danger" class="mr-2"> </b-icon>
        {{ this.errorMessage.failedtoSendCode }}
      </b-notification>

      <div
        v-if="this.$route.query.merchantId && this.$route.query.tableName"
        style="height: 100%"
      >
        <div
          v-if="!isLoading"
          class="has-border-grey has-background-white p-5 rounded"
        >
          <div v-if="Object.keys(this.merchantInfo).length !== 0">
            <div
              v-if="
                this.merchantInfo.tableNames.includes($route.query.tableName)
              "
            >
              <b-button
                v-if="isEnteringCode"
                type="is-text"
                icon-right="arrow-left"
                @click="backToMobileNoInput"
              />

              <div class="has-text-centered mb-5">
                <div class="image is-128x128 mx-auto mb-3 logo-image">
                  <div
                    v-if="
                      merchantInfo.logoImageURL === undefined ||
                      merchantInfo.logoImageURL === ''
                    "
                    class="rounded has-background-light no-image-icon-container"
                  >
                    <b-icon
                      icon="store"
                      type="is-grey-light"
                      size="is-large"
                    ></b-icon>
                  </div>
                  <img
                    v-else
                    alt="Merchant Logo"
                    :src="merchantInfo.logoImageURL"
                    class="rd-logo mt-2"
                    style="
                      height: 128px !important;
                      width: auto !important;
                      image-rendering: -webkit-optimize-contrast;
                    "
                  />
                </div>
                <h3 class="is-size-5 has-text-weight-semibold mb-2">
                  {{ merchantInfo.name }}
                </h3>
                <b-tag class="mb-3 is-size-6"
                  >Table {{ $route.query.tableName }}</b-tag
                >
                <!-- <p>u: {{ userLocation }}</p>
                <p>m: {{ merchantInfo.location }}</p> -->
                <!-- <div v-if="merchantInfo.location && userLocation">
                  <p
                    v-if="
                      isWithinRange(
                        userLocation.latitude,
                        userLocation.longitude,
                        merchantInfo.location.latitude,
                        merchantInfo.location.longitude
                      )
                    "
                    class="is-size-7 mb-3"
                  >
                    <b-icon
                      icon="map-marker"
                      size="is-small"
                      type="is-success"
                      class="mr-1"
                    ></b-icon>
                    You're within range
                  </p>
                  <p v-else>
                    <b-icon
                      icon="map-marker"
                      size="is-small"
                      type="is-danger"
                      class="mr-1"
                    ></b-icon>
                    <span class="is-size-7">You're out of range</span>
                  </p>
                </div>
                <div v-else class="is-size-6 pt-3">
                  <div class="mb-5 has-text-grey-dark">
                    We kindly request access to your current location for once,
                    to prevent any unexpected orders from a distance
                  </div>
                  <p v-if="userLocationError">
                    <b-notification
                      :closable="false"
                      class="has-text-centered mb-2"
                      type="is-danger is-light"
                      ><b-icon
                        icon="map-marker-alert"
                        class="mr-2"
                        size="is-small"
                      >
                      </b-icon>
                      Location access is denied
                    </b-notification>
                    <span class="is-size-7 has-text-grey">
                      Please enable location access in your browser settings to
                      proceed</span
                    >
                  </p>
                </div> -->
              </div>

              <!-- TODO -->
              <!-- removed: v-if="userLocation" -->
              <div v-if="hasMobileLogin">
                <div v-if="!isEnteringCode" class="mt-5">
                  <b-field label="Mobile No.">
                    <div class="is-flex">
                      <span
                        class="p-2 mr-2 has-border-grey"
                        style="border-radius: 0.5rem; height: 40px"
                        >+60</span
                      >
                      <b-input
                        maxlength="10"
                        class="is-flex-grow-1"
                        v-model="userMobileNo.value"
                        :has-counter="false"
                        validation-message="Please enter a valid Malaysian mobile number"
                        :pattern="userMobileNo.pattern"
                        required
                      >
                      </b-input>
                    </div>
                  </b-field>

                  <b-button
                    class="mt-5"
                    :disabled="!validateMobileNoInput"
                    type="is-primary"
                    expanded
                    @click="sendVerificationCode"
                    >Login</b-button
                  >
                </div>

                <div v-else>
                  <b-field label="Verification Code">
                    <b-input
                      maxlength="6"
                      v-model="verificationCode.value"
                      :has-counter="false"
                      validation-message="Please enter a valid 6 digits verification code"
                      :pattern="verificationCode.pattern"
                      required
                    >
                    </b-input>
                  </b-field>
                  <div
                    class="mt-3 mb-1 is-size-7 has-text-grey has-text-centered"
                  >
                    Didn't receive the code?
                  </div>
                  <b-button
                    expanded
                    @click="sendVerificationCode"
                    :disabled="isCountingDown || isSendingCode"
                  >
                    {{ isSendingCode ? "Sending Code" : "Resend" }}
                    <b-icon
                      v-if="isSendingCode"
                      icon="loading"
                      size="is-small"
                      custom-class="fa-spin"
                      class="ml-1"
                    ></b-icon>
                    <b-tag v-if="isCountingDown" class="ml-1"
                      >{{ timerCount }}s</b-tag
                    ></b-button
                  >

                  <b-button
                    class="mt-5"
                    :disabled="validateCodeInput === false || isVerifyingCode"
                    type="is-primary"
                    expanded
                    @click="verifyCode"
                  >
                    {{ isVerifyingCode ? "Verifying" : "Verify" }}
                    <b-icon
                      v-if="isVerifyingCode"
                      icon="loading"
                      size="is-small"
                      type="is-white"
                      custom-class="fa-spin"
                      class="ml-1"
                    ></b-icon>
                  </b-button>
                </div>
              </div>
              <div>
                <b-button
                  class="mt-5"
                  type="is-primary"
                  expanded
                  @click="loginAsGuest"
                >
                  Start Order</b-button
                >
              </div>
            </div>

            <div v-else class="has-text-centered">
              <b-image
                :src="require('@/assets/illustration/access_denied.svg')"
                alt=""
                class="mx-auto mb-5"
                style="width: 55%"
              ></b-image>
              <!-- TODO ：remove after display feature added -->
              <div class="mx-auto mb-2">Invalid Table</div>
              <p class="has-text-grey-light is-size-7 mb-5">
                Please ensure that you are scanning a valid QR code and try
                again
              </p>
              <!-- <b-button type="is-outlined is-primary" @click="openQRScanner"
                >Scan again</b-button
              > -->
            </div>
          </div>

          <div v-else class="has-text-centered">
            <!-- TODO: Invalid Merchant(ID) or Invalid Merchant's Table Name -->
            <b-image
              :src="require('@/assets/illustration/access_denied.svg')"
              alt=""
              class="mx-auto mb-5"
              style="width: 55%"
            ></b-image>
            <!-- TODO ：remove after display feature added -->
            <div class="mx-auto mb-2">Invalid Merchant</div>
            <p class="has-text-grey-light is-size-7 mb-5">
              Please ensure that you are scanning a valid QR code and try again
            </p>
            <!-- <b-button type="is-outlined is-primary" @click="openQRScanner"
              >Scan again</b-button
            > -->
          </div>
        </div>
        <div v-else>
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
        </div>
      </div>
      <div
        v-else
        class="has-text-centered has-border-grey has-background-white p-5 rounded"
      >
        <b-image
          :src="require('@/assets/illustration/access_denied.svg')"
          alt=""
          class="mx-auto mb-5"
          style="width: 55%"
        ></b-image>
        <!-- TODO ：remove after display feature added -->
        <div class="mx-auto mb-2">Invalid QR Code</div>
        <p class="has-text-grey-light is-size-7 mb-5">
          Please ensure that you are scanning a valid QR code and try again
        </p>
        <!-- <b-button type="is-outlined is-primary" @click="openQRScanner"
          >Scan again</b-button
        > -->
      </div>
    </div>
    <div id="recaptcha-container"></div>
  </section>
</template>

<script>
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { signInAnonymously } from "firebase/auth";

import { ERROR_MESSAGES } from "@/errorCodes.js";

export default {
  name: "UserLogin",
  data() {
    return {
      merchantInfo: {},
      isLoading: true,

      userMobileNo: {
        value: "",
        pattern: "^(1)[0-46-9][0-9]{7}|^(1)(1)[0-9]{8}$",
      },
      isEnteringCode: false,
      verificationCode: {
        value: "",
        pattern: "^[0-9]{6}$",
      },
      // TODO

      confirmationResult: {},
      timerCount: 60,
      isSendingCode: false,
      isVerifyingCode: false,
      isCodeSent: null,
      errorMessage: {
        failedtoSendCode: "",
        failedtoLogin: "",
      },
      isCountingDown: false,

      userLocation: null,
      userLocationError: null,

      hasMobileLogin: false,
    };
  },

  computed: {
    // merchantInfo() {
    //   return this.$store.getters["userModule/getMerchantInfo"];
    // },

    validateMobileNoInput() {
      const regexMobileNo = new RegExp(this.userMobileNo.pattern);

      if (regexMobileNo.test(this.userMobileNo.value)) {
        return true;
      }
      return false;
    },
    validateCodeInput() {
      const regexCode = new RegExp(this.verificationCode.pattern);

      if (regexCode.test(this.verificationCode.value)) {
        return true;
      }
      return false;
    },

    isNewUser() {
      return this.$store.getters["userModule/getIsNewUser"];
    },
  },
  async created() {
    await this.$store.dispatch(
      "userModule/retrieveMerchantInfo",
      this.$route.query.merchantId
    );

    this.merchantInfo = this.$store.getters["userModule/getMerchantInfo"];
    this.isLoading = false;
    // TODO: Merchant latitude & longitude
    //this.getUserLocation();

    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 300);
  },
  methods: {
    openQRScanner() {
      // Check if the user is accessing from iOS or Android
      const userAgent = navigator.userAgent.toLowerCase();
      const isIOS = /iphone|ipad|ipod/.test(userAgent);
      const isAndroid = /android/.test(userAgent);

      if (isIOS) {
        // Open QR scanner on iOS (using the Camera app)
        window.location.href =
          "itms://apps.apple.com/us/app/camera/id1584216193";
      } else if (isAndroid) {
        // Open QR scanner on Android (using the Google Lens app)
        window.location.href =
          "intent://scan/#Intent;scheme=https;package=com.google.android.googlequicksearchbox;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.android.googlequicksearchbox;end";
      } else {
        // Display an error message for unsupported devices
        alert("QR scanner is not found on your device.");
      }
    },

    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            this.userLocationError = null;
          },
          (error) => {
            this.userLocationError = error.message;
            this.userLocation = null;
          }
        );
      } else {
        this.userLocationError =
          "Geolocation is not supported by this browser.";
        this.userLocation = null;
      }
    },

    isWithinRange(lat1, lon1, lat2, lon2) {
      const latDiff = Math.abs(lat1 - lat2);
      const lonDiff = Math.abs(lon1 - lon2);
      const latMeter = latDiff * 111000; // 1 degree of latitude is approximately 111,000 meters
      const lonMeter = lonDiff * Math.abs(Math.cos((lat1 + lat2) / 2)) * 111000; // 1 degree of longitude is approximately 111,000 meters at the equator
      console.log("debug range", latDiff, ", ", lonDiff);
      console.log("debug range", latMeter, ", ", lonMeter);
      return latMeter <= 100 && lonMeter <= 100;
    },

    formatMobileNo(mobileNo) {
      const formattedMobileNo =
        "+60 " +
        mobileNo.substring(0, 4) +
        "-" +
        mobileNo.substring(4, 7) +
        "-" +
        mobileNo.substring(7, mobileNo.length);
      return formattedMobileNo;
      //"+60 1738-308-17";
    },

    async sendVerificationCode() {
      // TODO: retrieve user info check if exist
      await this.$store.dispatch(
        "userModule/retrieveUserInfo",
        "60" + this.userMobileNo.value
      );

      this.isEnteringCode = true;
      this.isSendingCode = true;

      this.timerCount = 60;
      const phoneNumber = await this.formatMobileNo(this.userMobileNo.value);
      //"+60 1738-308-17";
      const auth = getAuth();

      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: () => {
              // console.log("recaptcah res", response);
              // reCAPTCHA solved, allow signInWithPhoneNumber.
            },
          },
          auth
        );
      }
      const appVerifier = await window.recaptchaVerifier;

      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.confirmationResult = confirmationResult;

          this.isCodeSent = true;
          this.isSendingCode = false;

          this.startTimer();
          // ...
        })
        .catch((error) => {
          this.isCodeSent = false;
          this.isSendingCode = false;

          // Error; SMS not sent
          // ...
          // console.log("error: ", error);
          this.errorMessage.failedtoSendCode = error;
        });
    },

    startTimer() {
      this.isCountingDown = true;
      const interval = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount--;
        }

        if (this.timerCount === 0) {
          this.isCountingDown = false;
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    },

    backToMobileNoInput() {
      this.isEnteringCode = false;
      this.isCodeSent = null;
    },

    verifyCode() {
      this.isVerifyingCode = true;
      const code = this.verificationCode.value;
      this.confirmationResult
        .confirm(code)
        .then(async () => {
          // TODO:
          this.$buefy.toast.open("Login successfully 🎉");
          this.isVerifyingCode = false;
          // User signed in successfully.
          //const user = result.user;

          // user info
          if (this.isNewUser === true) {
            await this.addUserInfo();
          } else {
            await this.updateUserOnLogin();
          }

          // TODO: Redirect to Merchant Menu
          //merchantId=202301042KJM7L&tableName=1
          this.$router.push(
            `/user/menu/${this.$route.query.merchantId}?tableName=${this.$route.query.tableName}`
          );
          // ...
        })
        .catch((error) => {
          this.isVerifyingCode = false;
          // User couldn't sign in (bad verification code?)
          // ...
          const errorCode = error.code;
          this.$buefy.toast.open(
            `Error: ${ERROR_MESSAGES[errorCode] || errorCode}`
          );
        });
    },

    loginAsGuest() {
      const auth = getAuth();

      signInAnonymously(auth)
        .then(() => {
          // Signed in..
          this.$buefy.toast.open("Login successfully 🎉");

          // TODO: userInfo

          this.$router.push(
            `/user/menu/${this.$route.query.merchantId}?tableName=${this.$route.query.tableName}`
          );
        })
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          this.$buefy.toast.open(`Error: ${errorMessage}`);
          // ...
        });
    },

    // TODO: Verify on click
    addUserInfo() {
      this.$store.dispatch("userModule/addUserInfo", {
        mobileNo: "60" + this.userMobileNo.value,
        postcode: this.merchantInfo.postcode,
      });
    },

    updateUserOnLogin() {
      this.$store.dispatch("userModule/updateUserOnLogin", {
        mobileNo: "60" + this.userMobileNo.value,
        postcode: this.merchantInfo.postcode,
      });
    },

    addPostcodeToUser() {
      this.$store.dispatch("userModule/addPostcodeToUser", {
        mobileNo: "60" + this.userMobileNo.value,
      });
    },
    //   merchantLogin() {
    //     const auth = getAuth();
    //     signInWithEmailAndPassword(
    //       auth,
    //       this.merchantEmail.value,
    //       this.password.value
    //     )
    //       .then((userCredential) => {
    //         // Signed in
    //         const user = userCredential.user;
    //         console.log("user", user);
    //         // ...
    //         this.$buefy.toast.open("Login successfully!");
    //         this.$router.push("/merchant/home");
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         if (error.code !== undefined) {
    //           const errorCode = error.code;
    //           // const errorMessage = error.message;
    //           this.$buefy.toast.open(`Error: ${errorCode}`);
    //         }
    //       });
    //   },
  },
};
</script>

<style>
.logo-image img {
  border: 1px solid #f5f5f5;
  border-radius: 1.5rem;
}

.no-image-icon-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
