import { db } from "@/firebase";
import { doc, setDoc, increment } from "firebase/firestore";

import { ToastProgrammatic as Toast } from "buefy";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // TODO Add member points for online payment

  // Add member points once off-platform paid
  async updateUserMerchantMemberPoint({ rootState }, data) {
    try {
      const docRef = doc(
        db,
        "Users",
        data.userId,
        "Memberships",
        rootState.merchantModule.merchantInfo.id
      );

      let pointChangeInt = parseInt(data.pointChange);
      await setDoc(
        docRef,
        {
          memberPoints: increment(pointChangeInt),
          merchantId: rootState.merchantModule.merchantInfo.id,
        },
        { merge: true }
      );
    } catch (e) {
      console.error("Error adding document: ", e);
      Toast.open("Something went wrong. Please try again.");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
