<template>
  <section class="responsive-padding" style="padding-right: 1rem !important">
    <div class="mb-3">
      <div class="mb-3 is-flex">
        <div class="has-text-weight-bold my-auto is-size-5">To Serve</div>
        <!-- TODO: Yesterday and today orders? handle midnight orders-->
        <div class="ml-auto pr-4">
          <b-button
            class="is-hidden-tablet"
            icon-left="delete-outline"
            :disabled="!hasServedLog"
            @click="confirmClearCompletedLogs"
          >
          </b-button>
          <b-button
            class="is-hidden-mobile"
            label="Clear completed logs"
            icon-left="delete-outline"
            :disabled="!hasServedLog"
            @click="confirmClearCompletedLogs"
          >
          </b-button>
        </div>
      </div>
      <div class="py-3 to-serve-list-container">
        <ToServeList v-if="!isLoading" :ToServeLogs="ToServeLogs" />

        <div v-else>
          <div class="pb-3" v-for="i in 3" :key="i">
            <b-skeleton size="is-large" height="120" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ToServeList from "@/components/Merchant/ToServe/ToServeList.vue";

export default {
  name: "ToServe",
  components: { ToServeList },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;

    setTimeout(async () => {
      this.isLoading = false;
    }, 300);
  },
  computed: {
    ToServeLogs() {
      return this.$store.getters["toServeLogsModule/getToServeLogs"];
    },

    hasServedLog() {
      return this.ToServeLogs.some((obj) => obj.isServed === true);
    },
  },
  methods: {
    confirmClearCompletedLogs() {
      this.$buefy.dialog.confirm({
        title: "Clear completed logs",
        message: "Are you sure you want to clear all completed logs?",
        confirmText: "Yes",
        type: "is-primary",
        onConfirm: () =>
          this.clearCompletedLogs() &&
          this.$buefy.toast.open("Clearing completed logs ..."),
      });
    },
    async clearCompletedLogs() {
      let incompleteLogs = this.ToServeLogs.filter(
        (log) => log.isServed === false
      );

      await this.$store.dispatch(
        "toServeLogsModule/clearCompletedToServeLogs",
        incompleteLogs
      );
    },
  },
};
</script>

<style>
.to-serve-list-container {
  height: calc(100vh - 170px);
  overflow-y: auto;
  padding-right: 1rem;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
</style>
